const messages = {
  // Subscription menu
  MARK_AS_READ: 'пометить, как прочитанное',
  SYNC: 'Синх',
  RENAME: 'переименовать',
  REMOVE: 'удалять',
  SUMMARY: 'Резюме',
  WEB_VIEW: 'Веб-просмотр',
  READABLE: 'Reabable',
  UNCATEGORISED: 'Uncategorised',

  // Multiple
  SYNC_FINISHED: 'Синхронизация завершена',
  SYNC_SUBSCRIPTION: (subscriptionName) => `Синхронизации ${subscriptionName}`,
  SYNC_ACCOUNT: 'Синхронизация аккаунта...',
  SYNC_SUBSCRIPTIONS: 'Синхронизация подписки...',
  SEARCHING: (q) => `Поиск ${q}...`,
  SYNC_PROFILE: 'Синхронизация профиля...',
  ADD: 'Добавить',
  SETTINGS: 'настройки',
  SAVE: 'Сохранить',
  CANCEL: 'Отмена',
  RENAME_FOLDER: 'Переименовать папку',
  RENAME_SUBSCRIPTION: 'Переименовать подписку',
  ALL: 'все',
  LATER: 'Позже',
  FAVOURITE: 'фаворит',
  CONFIRM: 'Подтверждение',
  CONFIRM_DELETE_SUBSCRIPTION: 'Вы уверены, что хотите удалить подписку',

  MOMENTS_AGO: 'моменты назад',
  DIF_MINUTES: (t, isPlural) => `${t} минут`,
  DIF_HOURS: (t, isPlural) => `${t} ${isPlural ? 'часов' : 'часа'}`,
  DIF_DAYS: (t, isPlural) => `${t} ${isPlural ? 'дня' : 'день'}`,
  DIF_MONTHS: (t, isPlural) => `${t} месяца}`,
  DIF_YEARS: (t, isPlural) => `${t} ${isPlural ? 'лет' : 'года'}`,
  EMPTY_LIST_LATER_TITLE: 'Еще нет историй',
  EMPTY_LIST_LATER_SUBTEXT: 'Истории, которые вы хотите прочитать позже, будут здесь',
  EMPTY_LIST_FAVOURITE_TITLE: 'Еще нет историй',
  EMPTY_LIST_FAVOURITE_SUBTEXT: 'Истории, которые вы хотите сохранить как любимые, будут здесь',
  EMPTY_LIST_FOLDER_TITLE: 'Истории не найдены',
  EMPTY_LIST_FOLDER_SUBTEXT: 'В этой папке нет историй для показа',
  EMPTY_LIST_SUBSCRIPTION_TITLE: 'Истории не найдены',
  EMPTY_LIST_SUBSCRIPTION_SUBTEXT: 'В этой подписке нет историй',
  EMPTY_LIST_UNKNOWN_TITLE: 'Истории не найдены',
  EMPTY_LIST_UNKNOWN_SUBTEXT: 'Истории не найдены in this list',
  BY_AUTHOR: 'По',
  COPY_LINK: 'Копировать ссылку',
  MARK_AS_UNREAD: 'Отметить как непрочитанное',
  HIDE_WEB_VIEW: 'Спрятать Веб-просмотр',
  AUTH_CANCELLED_BY_USER: 'Авторизация отменена пользователем',
  CREATING_USER: 'Создание пользователя...',
  USER_CREATED: 'Пользователь создан',
  APP_HERO_TITLE: 'Оставаться на связи. Оставайтесь сосредоточенными.',
  APP_HERO_SUB_TITLE: `VEEN позволяет подключать и читать данные из нескольких источников`,
  REGISTERING_USER: 'Регистрация пользователя...',
  REGISTER: 'Регистр',
  LOGIN: 'Войти',
  FULL_NAME_LABEL: 'Полное имя',
  FULL_NAME_PLACEHOLDER: 'Введите полное имя',
  EMAIL_LABEL: 'Адрес электронной почты',
  CREATE_PASSWORD_LABEL: 'Придумайте пароль',
  VALIDATION_FULL_NAME_REQUIRED: 'Требуется полное имя',
  VALIDATION_PASSWORD_REQUIRED: 'Необходим пароль',
  VALIDATION_EMAIL_REQUIRED: 'Требуется электронная почта',
  VALIDATION_EMAIL_FORMAT: 'Должен быть действительный адрес электронной почты',
  VALIDATION_USERNAME_REQUIRED: 'Имя пользователя требуется',
  USERNAME_PLACEHOLDER: 'Электронная почта или имя пользователя',
  PASSWORD_PLACEHOLDER: 'Пароль',
  LOG_IN_PROGRESS_USER: 'Идет вход в систему....',
  SWITCH_ACCOUNT: 'Сменить аккаунт',
  INTEGRATION_NAMES: {
    feedly: 'Feedly', //keys are integration Codes
    foundry: 'Foundry',
  },
  CONNECTIONS: 'связи',
  ACCOUNT: 'СЧЕТ',
  SYNC_ON_START: 'Синхронизация при запуске',
  ENABLED: 'Включено',
  DISABLED: 'Отключено',
  LANGUAGE: 'язык',
  CHOOSE_LANGUAGE: 'Выберите язык',
  CHOOSE_THEME: 'Выберите тему',
  THEME: 'тема',
  THEME_NAMES: {
    1: 'световой',
    2: 'Темно',
  },
  PLEASE_UPGRADE_APP: 'Пожалуйста, обновите ваше приложение',
  DELETE_ACCOUNT: 'Вы уверены, что хотите удалить аккаунт?',
  DELETE: 'Удалить',
  MANAGE_ACCOUNTS: 'Управлять аккаунтами',
  ADD_ACCOUNT: 'Добавить новый аккаунт',
  CREATE_ACCOUNT: 'Завести аккаунт',
  PLAN_DETAILS: 'Детали плана',
  CHANGE: 'изменение',
  VALIDATION_OLD_PASSWORD_REQUIRED: 'Требуется старый пароль',
  VALIDATION_NEW_PASSWORD_REQUIRED: 'требуется новый пароль',
  CHANGE_PASSWORD_OLD_PLACEHOLDER: 'Введите старый пароль',
  CHANGE_PASSWORD_NEW_PLACEHOLDER: 'Введите новый пароль',
  CHANGE_PASSWORD_OLD_LABEL: 'Прежний пароль',
  CHANGE_PASSWORD_NEW_LABEL: 'Новый пароль',
  UPDATE: 'Обновить',
  CHANGE_PASSWORD_FORM_TITLE: 'Сменить пароль',
  CHANGE_PASSWORD_SUCCESS_MESSAGE: 'Пароль успешно изменен',
  LOGOUT_PLACEHOLDER: 'Выйти',
  LOGOUT_CONFIRM_MESSAGE: 'Вы действительно хотите выйти?',

  SEARCH_RESULT_FROM: (term) => `Результат поиска для ${term}`,
  SUBSCRIPTION_SYSTEM_SEARCHBOX_PLACEHOLDER: 'Введите название, тему, URL или ключевое слово',
  FIND_SUBSCRIPTIONS: 'Найти подписки',
  NO_RESULTS_FOUND: 'Результатов не найдено',
  TRY_ANOTHER_TERM: 'Попробуйте другой термин',
  SUBSCRIBERS: 'Подписчики',
  ITEMS_PER_WEEK: 'Предметов в неделю',
  SUBSCRIBE: 'Подписывайся',
  UNKNOWN_ERROR: 'Неизвестная ошибка',
  LOADING: 'загрузка...',
  SUCCESS: 'успех',
  FAIL: 'Не смогли',
  UPDATING: 'обновление...',
  REMOVING: 'Удаление...',
  ENTER_A_FOLDER_NAME: 'Введите имя папки',
  TAGS: 'Теги',
  OK: 'Ok',
  ALERT: 'Тревога',
  SESSION_TIMED_OUT: 'Время вашего сеанса истекло. Пожалуйста, войдите снова. Спасибо',

  ENTER_YOUR_EMAIl_ADDRESS: 'Адрес электронной почты',
  ENTER_OTP: 'Тип OTP',
  ENTER_NEW_PASSWORD: 'Введите новый пароль',
  SUBMIT: 'Разместить',
  FORGOT_PASSWORD: 'забыл пароль?',

  REQUESTING_OTP: 'Отправка OTP на вашу электронную почту',
  REQUESTING_OTP_ERROR: 'произошла ошибка...',
  REQUESTING_OTP_SUCCESS: 'Пожалуйста, введите OTP, полученный на вашу электронную почту',

  SUBMITTING_OTP: 'Отправка OTP',
  SUBMITTING_OTP_ERROR: 'Error in Submitting OTP',
  SUBMITTING_OTP_SUCCESS: 'Ошибка при отправке одноразового пароля',
  INVALID_OTP: 'Неверный OOTP',

  CHANGING_PASSWORD: 'Saving..',
  CHANGING_PASSWORD_ERROR: 'произошла ошибка...',
  CHANGING_PASSWORD_SUCCESS: 'Выполнено. Пожалуйста, войдите с новым паролем',
  ADD_NEW_TAG: 'новый',
  TAG_NAME_REQUIRED: 'Требуется название тега',
  ENTER_A_TAG_NAME: 'Название тэга',
  ADD_NEW_TAG_FORM_TITLE: 'Создать тег',
  USER_LEVEL: 'Уровень пользователя',
  SYNC_TAGS: 'Синхронизация тегов...',
  SYNCING_MARKERS: 'Синхронизация маркеров...',
  SYNCING_COUNTS: 'Счетчики синхронизации...',
  SYNCING_STORIES: 'Синхронизация историй...',
  SYNCING_STORIES_WITH_BATCH_NUMBER: (n) => {
    return `(${n}) Синхронизация историй...`
  },
  SYNCING_ACCOUNT_STORIES_WITH_BATCH_NUMBER: (n) => {
    return `(${n}) Синхронизация истории аккаунта...`
  },
  SYNCING_TAG_STORIES_WITH_BATCH_NUMBER: (n, label) => {
    return `(${label}) Синхронизация историй тегов...`
  },
  ONBOARDING_RECOMMENDATION_TITLE: 'Пожалуйста, выберите несколько тем, чтобы начать',
  ONBOARDING_RECOMMENDATION_COMPLETE_BTN: 'Полный',

  //**-----------BELOW ARE OLD KEYS----------- */
  WAIT: 'Подождите...',
  APP_NAME: 'VeenReader',

  //Add Strings Here
  WELCOME_MSG_1: 'welcome to',
  WELCOME_MSG_2: ' veen reader',
  ERROR: 'ошибка',

  OR: 'либо',
  FEEDLY: 'feedly',
  FACEBOOK: 'facebook',
  FULLNAME: 'Имя',
  FULLNAME_REQUIRED: 'Full name is required Ru',
  START_WITH_LOCAL_ACCOUNT: 'начать с локальной учетной записи',
  TWITTER: 'щебет',
  LOCAL: 'местный',
  CONINTUE_WITH_FEEDLY: 'RU-Continue with feedly',

  //Accounts
  ADD_NEW: "Dobavit' novoye",
  REMOVE_ACCOUNT: 'Удалить',
  REMOVE_ACCOUNT_SCREEN: 'Удалить Счет',
  UNDO_REMOVE: 'расстегивать',
  CHOOSE_A_SERVICE: 'Выберите услугу',
  CREATE_LOCAL_ACCOUNT: 'Создание локальной учетной записи',
  CREATE: 'Создайте',

  BACK: 'назад',

  UNREAD: 'непрочитанный',

  SYNCING: 'Синхронизации',
  ON: 'на',
  OFF: 'от',
  GENERAl: 'Генеральная',
  TEMPLATE: 'шаблон',
  READING_OPTIONS: 'Параметры чтения',
  FONT_SIZE: 'Размер шрифта',
  REMOVE_ITEMS_OLDER_THAN: 'Удалить элементы старше, чем',
  NEVER: 'Никогда',
  DAYS: 'дней',
  HINDI: 'Hindi',
  ENGLISH: 'English',
  CARD: 'карты',
  TILE: 'Кафельная плитка',
  CLASSIC: 'классический',

  NEWEST_FIRST: 'Новый первый',
  OLDEST_FIRST: 'Старый',
  POPULAR_FIRST: 'Популярные первые',
  SEARCH: 'Поиск',
  SEARCH_BAR_TEXT: 'История поиска',
  NEW_FOLDER_NAME: 'Необязательный: Новое имя папки',
  REFRESH_AFTER: 'Обновить после',
  EVERY_X_MINUTES: (n) => {
    return `каждые ${n} минуты`
  },
  EVERY_X_HOUR: (n) => {
    return `каждые ${n} часа`
  },
  STATUS_COPIED_TO_CLIPBOARD: 'Скопировано в буфер обмена!!',

  SYNC_TOKEN: 'Синхронизирующий токен',
  SYNC_STORIES: 'Синхронизировать истории',

  STARTING_AUTH: 'Запуск авторизации',
  WAITING_FOR_AUTHRIZATION: 'Ожидание авторизации',
  COLLECTING_TOKEN: 'Собирающий токен',
  HELP: 'Помогите',
  MARK_LIST_AS_READ: 'Отметить список как прочитанный',
  SUPPORT_EMAIL: 'Поддержка электронной почты',
  PRIVACY_POLICY: 'политика конфиденциальности',
  MONTHS: ['Jan', 'Feb', 'Mar', 'апр', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
}
export default messages
