import React from 'react'
import styled from 'styled-components'
import { SearchBox as SearchBoxBase } from 'APP/ui-kit/Vendor'

const SearchBox = (props) => (
  <Container>
    <SearchBoxBase autoComplete="off" {...props} />
  </Container>
)

const Container = styled.div`
  width: 95%;
  max-width: 650px;
`

export default SearchBox
