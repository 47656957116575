import styled from 'styled-components'

const Image = styled.div`
  background-image: url('${(p) => p.src}');
  background-color: #d6d6d6;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 25%;
`

export default Image
