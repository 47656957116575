import React from 'react'
import { Row, Col } from 'APP/ui-kit/Grid/'
import Avatar from 'APP/ui-kit/Avatar'
import { getShortFullName } from 'APP/utils/common'
import styled from 'styled-components'

const BaseUserRow = ({ user, handleRowClick, className, INTEGRATION_NAMES, command }) => {
  return (
    <Row rows onClick={() => handleRowClick && handleRowClick(user)} className={className}>
      <Row length={28} centerh left cols style={{ marginRight: 16 }}>
        <Avatar size={28} imageUrl={user.picture}>
          {(user.fullName || '')[0]}{' '}
        </Avatar>
      </Row>
      <Row cols>
        <UserName>{getShortFullName(user.fullName, 22)}</UserName>
        <IntegrationName>{INTEGRATION_NAMES[user.integrationCode]}</IntegrationName>
      </Row>
      {command && (
        <Row length={96} centerv right rows>
          {command}
        </Row>
      )}
    </Row>
  )
}

const UserRow = styled(BaseUserRow)`
  text-transform: capitalize;
  padding: 8px 8px;
  border-radius: ${(p) => p.theme.borderRadius2};
  font-size: 14px;
  font-weight: 400;
  ${(p) =>
    !p.noborder &&
    `
    border-bottom: 1px solid ${p.theme.rowBorder};
  `}
  ${(p) => p.selected && `background:${p.theme.integrationUserCardSelectedBgColor};`}
  ${(p) =>
    !p.disablehover &&
    `
    cursor: pointer;
    &:hover {
      background: ${p.theme.integrationUserCardHover};
    }
  `}
`

const UserName = styled(Col)`
  font-weight: 600;
`

const IntegrationName = styled(Col)`
  font-weight: 600;
  color: ${(p) => p.theme.subduedText};
  font-size: 12px;
`

/**
 * noborder: hides border from the bottom
 * selected: Is item selected
 * disablehover: Hides all hover effect
 * user: integrationUser
 * handleRowClick?
 * INTEGRATION_NAMES: List of integration
 */
export default UserRow
