import React from 'react'
import { LANGUAGES, useIntl } from 'APP/language'

const Languagebar = () => {
  const [locale, changeLocale] = useIntl()
  return (
    <div>
      {LANGUAGES.asArray().map((l, key) => (
        <span
          key={key}
          style={{
            margin: '0px 8px',
            cursor: 'pointer',
            fontWeight: l.languageCode === locale ? '600' : '200',
          }}
          onClick={() => changeLocale(l.languageCode)}>
          {l.displayName}
        </span>
      ))}
    </div>
  )
}

export default Languagebar
