/**
 * This is a simple grid. It needs to be optimized
 */

// length:number, //intuitive value for height/wdith
// size:number, //division of flex
// row:bool, //internal
// col:bool, //internal
// cacheId => Used to cache style
// extendedStyle => Overwrite cached style

import React from 'react'

const cachedStyles = {}

const Flexbox = ({ cacheId, rows, cols, length, size = 1, top, bottom, left, right, centerh, centerv, middle, noflex, children, row, col, extendedProps, style, extendedStyle, ...rest }) => {
  let calculatedStyle = {}

  if (cachedStyles[cacheId]) {
    calculatedStyle = cachedStyles[cacheId]
  } else {
    if (rows) {
      calculatedStyle['flexDirection'] = 'row'
    } else {
      calculatedStyle['flexDirection'] = 'column'
    }

    if (length) {
      let prop = row ? 'width' : 'height'
      calculatedStyle[prop] = length
    } else {
      calculatedStyle['flex'] = size
    }

    if (!noflex) {
      calculatedStyle['display'] = 'flex'
      calculatedStyle['alignSelf'] = 'stretch'
    }

    let mainAxis = row ? 'justifyContent' : 'alignItems'
    let crossAxis = row ? 'alignItems' : 'justifyContent'

    if (top) {
      calculatedStyle[crossAxis] = 'flex-start'
    }
    if (bottom) {
      calculatedStyle[crossAxis] = 'flex-end'
    }
    if (left) {
      calculatedStyle[mainAxis] = 'flex-start'
    }
    if (right) {
      calculatedStyle[mainAxis] = 'flex-end'
    }

    if (centerh) {
      calculatedStyle[mainAxis] = 'center'
    }

    if (centerv) {
      calculatedStyle[crossAxis] = 'center'
    }

    if (middle) {
      calculatedStyle[crossAxis] = 'center'
      calculatedStyle[mainAxis] = 'center'
    }

    calculatedStyle = { ...style, ...calculatedStyle }

    cacheId && (cachedStyles[cacheId] = calculatedStyle)
  }

  let finalStyle = { ...calculatedStyle, ...extendedStyle }
  return (
    <div {...rest} style={finalStyle}>
      {children}
    </div>
  )
}

const Row = (props) => {
  return <Flexbox {...props} row={true} />
}

const Col = (props) => {
  return <Flexbox {...props} col={true} />
}

const Grid = (props) => {
  return <Flexbox {...props} />
}

export { Col, Row, Grid }
