import React from 'react'
import { Row, Col } from 'APP/ui-kit/Grid'
import styled from 'styled-components'
import { Text } from 'APP/ui-kit/Vendor'

const InformationRow = ({ title, value, selector }) => (
  <InformationRowContainer rows>
    <Row cols>
      <Col>
        <Label variant="xSmall">{title}</Label>
      </Col>
      <Col style={{ paddingTop: 4 }}>
        <Text variant="mediumPlus">{value}</Text>
      </Col>
    </Row>
    <Row length={100} centerv right rows>
      {selector}
    </Row>
  </InformationRowContainer>
)

const InformationRowContainer = styled(Row)`
  border-bottom: 1px solid ${(p) => p.theme.rowBorder};
  padding: 8px 0px;
`

const Label = styled(Text)`
  text-transform: uppercase;
  font-weight: 600;
  color: ${(p) => p.theme.subduedText};
`

export default InformationRow
