import { get } from 'APP/utils/lodash'
import { createAction } from 'APP/utils/reduxUtils'
import produce from 'immer'
import { handleActions } from 'redux-actions'

const MODULE_NAME = 'UI_BLOCKER'

/* ------------- Actions ------------- */
export const actions = {
  blockUI: createAction(MODULE_NAME, 'blockUI'),
  unBlockUI: createAction(MODULE_NAME, 'unBlockUI'),
}

/* ------------- Initial state ------------- */
const initialState = {
  visbile: false,
  zIndex: 1,
}

/* ------------- reducer ------------- */
export const reducer = handleActions(
  {
    [actions.blockUI]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.visbile = true
        draft.zIndex = get(payload, 'zIndex', 1)
      }),
    [actions.unBlockUI]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.visbile = false
        draft.zIndex = get(payload, 'zIndex', 1)
      }),
  },
  initialState
)
