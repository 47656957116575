import { connect } from 'react-redux'
import Pure from './Pure'
import * as AppModel from 'APP/components/Models/app'
import { getShortName } from 'APP/utils/common'
import { getIntegrationUser } from 'APP/utils/reduxSelectors'

const mapStateToProps = (state) => {
  const integrationUser = getIntegrationUser(state) || {}
  return {
    syncing: state.appState.syncInProgress,
    name: getShortName(integrationUser.fullName, 10),
    picture: integrationUser.picture,
  }
}

export default connect(mapStateToProps, {
  sync: AppModel.actions.sync.request,
})(Pure)
