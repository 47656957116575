import SyncManager from './base'
import * as MarkersModel from 'APP/components/Models/markers'
import { runSaga } from 'APP/utils/reduxUtils'

class FoundrySyncManager extends SyncManager {
  *syncCounts() {
    yield console.log('Sync  counts FOUNDRY ...')
    yield runSaga(MarkersModel, MarkersModel.actions.calculateCounts.request)()
  }
}

export default FoundrySyncManager
