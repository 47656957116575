import { css } from 'styled-components'

// small	320px - 479px
// medium	480px - 639px
// large	640px - 1023px
// extra large	1024px - 1365px
// extra extra large	1366px - 1919px
// extra extra extra large	1920px and up

const sizes = {
  xs: 0, // phones 0px
  sm: 768, // tablets 768px
  md: 1024, // low-res display up to 1024px
  lg: 1280, // hi-res display 1280px
}

export const media = Object.keys(sizes).reduce((accumulator, label) => {
  accumulator[label] = (...args) => css`
    @media only screen and (max-width: ${sizes[label]}px) {
      ${css(...args)};
    }
  `
  return accumulator
}, {})
