import { handleActions } from 'redux-actions'
import produce from 'immer'
import { put, takeLatest, select, call, fork, takeEvery } from 'redux-saga/effects'
import dbZervice from 'APP/services/dbZervice'
import { handleSaga, create3Actions, createAction } from 'APP/utils/reduxUtils'
import { find, remove, findAndReplace, reject, findAndPatch, findAndReplaceOrAdd } from 'APP/utils/lodash'
import { serviceEffext, API_OPERATIONS } from 'APP/services/serviceEffext'
import { getIntegrationUser, getIntegrationUsers, getSubscriptions } from 'APP/utils/reduxSelectors'

const MODULE_NAME = 'SUBSCRIPTIONS'
const createActions = create3Actions(MODULE_NAME)

/* ------------- Actions ------------- */
export const actions = {
  load: createActions('load'),
  update: createActions('update'),
  sync: createActions('sync'),
  remove: createActions('remove'),
  rename: createActions('rename'),
  updateLastSuccessSyncTime: createAction('updateLastSuccessSyncTime of 1 subscription'),
}

/* ------------- Initial state ------------- */
const initialState = {
  subscriptions: [],
  isSubscriptionsLoaded: false,
}

/* ------------- reducer ------------- */

// Reduer has to fixed for STATE format change
export const reducer = handleActions(
  {
    [actions.load.request]: (state, { payload }) =>
      produce(state, (draft) => {
        return { ...state, subscriptions: [] }
      }),
    [actions.load.success]: (state, { payload }) =>
      produce(state, (draft) => {
        return { ...state, subscriptions: payload, isSubscriptionsLoaded: true }
      }),
    [actions.update.request]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.subscriptions = findAndReplaceOrAdd(draft.subscriptions, { _id: payload._id }, payload)
      }),
    [actions.sync.success]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.subscriptions = payload.map((s) => {
          // find subscription in list of existing subscriptions if found overwrite it's properties with new
          return { ...find(draft.subscriptions, { _id: s._id }), ...s }
        })
      }),
    [actions.remove.request]: (state, { payload }) =>
      produce(state, (draft) => {
        const { subscription, folder } = payload
        if (subscription.categories.length < 2) {
          draft.subscriptions = reject(draft.subscriptions, (s) => s.id === subscription.id)
        } else {
          const existingSubscription = find(draft.subscriptions, { id: subscription.id })
          remove(existingSubscription.categories, (c) => c.id === folder.id)
          draft.subscriptions = findAndReplace(draft.subscriptions, { id: subscription._id }, existingSubscription)
        }
      }),
    [actions.rename.request]: (state, { payload }) =>
      produce(state, (draft) => {
        const { subscription, folder, isFolder, newName } = payload
        if (!isFolder) {
          findAndPatch(draft.subscriptions, { _id: subscription._id }, { title: newName })
        } else {
          draft.subscriptions = draft.subscriptions.map((s) => {
            s.categories = findAndPatch(s.categories, { id: folder.id }, { label: newName })
            return s
          })
        }
      }),
    [actions.updateLastSuccessSyncTime]: (state, { payload }) =>
      produce(state, (draft) => {
        const { startTime, subscription } = payload
        findAndPatch(draft.subscriptions, { _id: subscription._id }, { lastSuccessSyncTime: startTime })
      }),
  },
  initialState
)

/* ------------- root saga ------------- */
export const rootSaga = {
  [actions.load.request]: handleSaga(takeLatest, function* () {
    const integrationUser = yield select(getIntegrationUser)
    const subscriptions = yield call(dbZervice.getSubscriptions, { integrationUser })
    yield put(actions.load.success(subscriptions))
  }),
  [actions.update.request]: handleSaga(takeEvery, function* ({ payload: subscription }) {
    const result = yield serviceEffext(API_OPERATIONS.UPSERT_SUBSCRIPTION, { subscription: subscription })
    yield fork(dbZervice.upsertSubscriptions, { subscriptions: [result.data] })
  }),
  [actions.sync.request]: handleSaga(
    takeLatest,
    function* () {
      const integrationUser = yield select(getIntegrationUser)
      const response = yield serviceEffext(API_OPERATIONS.GET_SUBSCRIPTIONS)
      const subscriptions = response.data
      yield put(actions.sync.success(subscriptions))
      yield call(dbZervice.clearIntUserSubscriptions, { integrationUser })
      yield call(dbZervice.upsertSubscriptions, { subscriptions })
    },
    { progressMessageProducer: 'SYNC_SUBSCRIPTIONS' }
  ),
  [actions.rename.request]: handleSaga(
    takeLatest,
    function* ({ payload }) {
      const subscriptions = yield select(getSubscriptions)
      yield fork(dbZervice.upsertSubscriptions, { subscriptions })
      const { isFolder, folder, newName } = payload
      if (isFolder) {
        yield serviceEffext(API_OPERATIONS.RENAME_FOLDER, {
          folder: {
            id: folder.id,
            label: newName,
          },
        })
      } else {
        const subscription = find(subscriptions, { _id: payload.subscription._id })
        yield serviceEffext(API_OPERATIONS.UPSERT_SUBSCRIPTION, { subscription })
      }
    },
    'UPDATING'
  ),
  [actions.remove.request]: handleSaga(
    takeEvery,
    function* ({ payload }) {
      const subscriptions = yield select(getSubscriptions)
      const integrationUser = yield select(getIntegrationUser)
      const { subscription } = payload
      const existingSub = find(subscriptions, { _id: subscription._id })

      // if subscription still exist in state, means, user has this subscription in some other folder
      if (existingSub) {
        yield fork(dbZervice.upsertSubscriptions, { subscriptions: [subscription] })
        yield serviceEffext(API_OPERATIONS.UPSERT_SUBSCRIPTION, { subscription })
      } else {
        yield fork(dbZervice.deleteSubscriptions, { integrationUser, subscriptions: [subscription] })
        yield serviceEffext(API_OPERATIONS.REMOVE_ONE_SUBSCRIPTION, { subscription })
      }
    },
    'REMOVING'
  ),
  [actions.updateLastSuccessSyncTime]: handleSaga(takeLatest, function* ({ payload }) {
    const { startTime, subscription } = payload
    yield call(dbZervice.updateSubscription, { subscription, props: { lastSuccessSyncTime: startTime } })
  }),
}
