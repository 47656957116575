import { combineReducers, createStore, applyMiddleware } from 'redux'
import createSagaMiddleWare from 'redux-saga'
import { createLogger } from 'redux-logger'
import config from 'APP/utils/config'
import { mergeSagas } from 'APP/utils/reduxUtils'

import * as AppState from 'APP/components/Models/app'
import * as User from 'APP/components/Models/user.js'
import * as Settings from 'APP/components/Settings/model'
import * as StoriesList from 'APP/components/StoriesList/model'
import * as SubscriptionsManager from 'APP/components/SubscriptionsManager/model'
import * as IntegrationUsers from 'APP/components/Models/integrationUsers'
import * as SubscriptionList from 'APP/components/SubscriptionList/model'
import * as ParsedArticles from 'APP/components/StoriesList/parsedArticlesModel'
import * as AccountsManager from 'APP/components/AccountsManager/model'
import * as WelcomeScreen from 'APP/components/WelcomeScreen/model'
import * as MessageBar from 'APP/components/MessageBar/model'
import * as Language from 'APP/components/Models/language'
import * as ApiStatus from 'APP/components/Models/apiStatus'
import * as UiBlocker from 'APP/components/UiBlocker/model'
import * as Markers from 'APP/components/Models/markers'
import * as Tags from 'APP/components/Models/tags'
import * as GlobalDialog from 'APP/components/Dialog/model'
import * as OnboardingRecommendations from 'APP/components/OnboardingRecommendations/model'

// Root reducer
const rootReducer = combineReducers({
  user: User.reducer,
  settings: Settings.reducer,
  stories: StoriesList.storiesReducer,
  storiesMeta: StoriesList.storiesMetaReducer,
  appState: AppState.reducer,
  subscriptionsManager: SubscriptionsManager.reducer,
  integrationUsers: IntegrationUsers.reducer,
  subscriptions: SubscriptionList.reducer,
  parsedArticles: ParsedArticles.reducer,
  accountsManager: AccountsManager.reducer,
  messageBar: MessageBar.reducer,
  language: Language.reducer,
  apiStatus: ApiStatus.reducer,
  uiBlocked: UiBlocker.reducer,
  markers: Markers.reducer,
  tags: Tags.reducer,
  globalDialog: GlobalDialog.reducer,
  onboardingRecommendations: OnboardingRecommendations.reducer,
})

// Root saga
const rootSagas = [
  User,
  Settings,
  StoriesList,
  AppState,
  SubscriptionsManager,
  IntegrationUsers,
  SubscriptionList,
  ParsedArticles,
  AccountsManager,
  WelcomeScreen,
  MessageBar,
  Markers,
  Tags,
  GlobalDialog,
  OnboardingRecommendations,
].reduce((prevVal, curVal) => prevVal.concat(mergeSagas(curVal.rootSaga)), [])

//Middlewares
const sagaMiddleWare = createSagaMiddleWare()
const middlewares = []
config.reduxLoggerEnabled && middlewares.push(createLogger())
middlewares.push(sagaMiddleWare)

//Create store
const store = createStore(rootReducer, applyMiddleware(...middlewares))

//run sagas
rootSagas.forEach(sagaMiddleWare.run)

export { store }
