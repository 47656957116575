import { oAuthFlow } from './oAuth.web'
import Base from './base'

class Platform extends Base {
  static oAuthFlow = oAuthFlow
  static getName() {
    return 'web'
  }
}

export default Platform
