import { getTheme } from 'APP/ui-kit/Vendor'
import { MESSAGE_BAR_MESSAGE_TYPE } from 'APP/utils/enums'

const fabricTheme = getTheme()

// These colors can be used to overwrite default Fabricn pallete
const colors = {
  // Fabric Library colors
  ...fabricTheme.palette,
  ...fabricTheme.semanticColors,

  // palette color
  transparent: 'transparent',
  white: '#fff',

  // Semantic colors
  messageBarBgColors: {
    [MESSAGE_BAR_MESSAGE_TYPE.PRIMARY]: '#005a9e',
    [MESSAGE_BAR_MESSAGE_TYPE.SUCCESS]: '#217346',
    [MESSAGE_BAR_MESSAGE_TYPE.WARNING]: '#ab830c',
    [MESSAGE_BAR_MESSAGE_TYPE.DANGER]: '#b10f0f',
  },
  messageBarFontColors: {
    [MESSAGE_BAR_MESSAGE_TYPE.PRIMARY]: '#fff',
    [MESSAGE_BAR_MESSAGE_TYPE.SUCCESS]: '#fff',
    [MESSAGE_BAR_MESSAGE_TYPE.WARNING]: '#fff',
    [MESSAGE_BAR_MESSAGE_TYPE.DANGER]: '#fff',
  },
}

const theme = {
  ...colors,
  fonts: fabricTheme.fonts,

  /**
   * Generic
   */
  borderRadius2: '2px',
  borderRadius4: '4px',
  bodyBackground: '#fff',
  subduedBackground: '#e1dfdd',
  elevation1: '0 1.6px 3.6px 0 rgba(0, 0, 0, 0.132), 0 0.3px 0.9px 0 rgba(0, 0, 0, 0.108)',
  rowBorder: '#edebe9',
  subduedText: '#a19f9d',
  frameBorderColor: '#edebe9',
  toolbarIconHoverColor: '#0a98d8',
  toolbarIconActiveColor: '#0a98d8',
  toolbarIconInActiveColor: '#a19f9d',
  linkTextColor: '#1773bb',

  /**
   * UI KIT
   */

  dangerButtonBorder: '#ffa6a7',
  dangerButtonText: '#f9696b',
  dangerButtonHoverBackground: '#ffeeee',
  formValidationErrorText: '#a4262c', //same for dark theme too

  cardBackground: '#fff',
  cardBorder: '#e5e5e5',
  cardDisabledBackground: '#f3f2f1',
  cardTitleColor: '#0078d4',

  avatarBgColor: '#03a9f4',
  avatarText: '#fff',
  uiBlockerBgColor: 'rgba(5,5,5,0.6)',

  /**
   * Component Colors
   */

  /**
   * naming conventions
   * item-state-property
   * eg: folderMenuActiveBgColor
   *  item:folderMenu
   *  state: Active
   *  property: BgColor
   */
  connectionCardIconBackgroundColor: '#eee',
  integrationUserCardSelectedBgColor: '#eff6fc',
  integrationUserCardHoverBgColor: '#dfebf5',
  foldersListBGColor: '#f6f9fc',
  folderTextColor: '#a19f9d',
  folderHoverTextColor: '#605e5c',
  folderSelectedBgColor: '#eee',
  folderMarkerSelectedBgColor: '#0078d4',
  folderMenuActiveBgColor: '#0078d4',
  standardFolderTextColor: '#3b3a39',
  standardFolderSelectedTextColor: '#0078d4',
  subscriptionTextColor: '#3c3c3c',
  subscriptionSelectedBgColor: '#deecf9',
  subscriptionHoverBgColor: '#d2d0ce',
  subscriptionMarkerSelectedBgColor: '#0078d4',
  subscriptionMenuActiveBgColor: '#a19f9d',
  sidebarBgColor: '#0e2d44',
  sidebarItemTextColor: '#eee',
  sidebarItemHoverTextColor: '#fff',
  sidebarItemHoverBgColor: '#345265',
  sidebarLogoContainerTextColor: '#b0c2d0',
  sidebarDateTextColor: '#718390',
  sidebarSelectedItemBgColor: '#1d3f56',
  skeletonCardBottomBorderColor: '#f1f2f3',
  storyCardBottomBorderColor: '#edebe9',
  storyCardSelectedBackgroundColor: '#edebe9',
  storyCardImageBgColor: '#d2d0ce',
  stortyCardTitleTextColor: '#323130',
  stortyCardTitleReadTextColor: '#888',
  stortyCardTitleSelectedTextColor: '#323130',
  emptyStoryListIconColor: '#009688',
  emptyStoryListTitleColor: '#424748',
  emptyStoryListSubTitleColor: '#545658',
  emptyStoryDetailsBgColor: '#ddd',
  storyDetailsTextColor: '', // Default color is fine
  storyDetailsTitleTextColor: '', // Default color is fine
  subscriptionManagerIconBorderColor: '#fff',
  subscriptionManagerSubtextColor: '#a19f9d',
  subscriptionManagerDescriptionColor: '#545658',
  subscriptionManagerSeparotorColor: '#edebe9',
  linkButtonBorderColor: '#aec6d8',
  pillBorderColor: ' #a19f9d',
  pillBackgroundColor: '#a19f9d',
  pillTextColor: '#fff',
  pillButtonBorderColor: '#1b7fcc',
  pillButtonIconBGColor: '#1b7fcc',
}

export { colors, theme }
