import React from 'react'
import { useSelector } from 'react-redux'
import { useTheme } from 'APP/themes'
import { useIntl, LANGUAGES } from 'APP/language'
import Router from './Router'
import { get } from 'APP/utils/lodash'
import { useGetActions } from 'APP/utils/reduxHooks'

const App = () => {
  const [, changeTheme] = useTheme()
  const [, changeLocale] = useIntl()
  const { initializeApp, changeLanguage, integrationUserChanged } = useGetActions()
  const theme = useSelector((s) => get(s, 'settings.theme'))
  const language = useSelector((s) => get(s, 'settings.language'))
  const integrationUserIndex = useSelector((s) => get(s, 'appState.uiState.integrationUserIndex'))
  const isAppReady = useSelector((s) => get(s, 'appState.isDashboardReady'))

  // Initialize APP
  React.useEffect(() => {
    initializeApp()
  }, [])

  React.useEffect(() => {
    if (integrationUserIndex > -1 && isAppReady) {
      integrationUserChanged()
    }
  }, [integrationUserIndex, isAppReady])

  React.useEffect(() => {
    theme && changeTheme(theme)
  }, [theme, changeTheme])

  React.useEffect(() => {
    language && changeLocale(language)
    changeLanguage(LANGUAGES[language]['messages'])
  }, [language])

  return (
    <React.Fragment>
      <Router />
    </React.Fragment>
  )
}

export default App
