import React from 'react'
import styled from 'styled-components'
import Pill from 'APP/ui-kit/Pills'
import { FontIcon } from 'APP/ui-kit/Vendor'
import { withTheme } from 'styled-components'
import { useMessage } from 'APP/language'
import Form from './CreateNewTag'

const Component = ({ tags, tagsWithStoryIds, story, handleTagClick }) => {
  const [ADD_NEW_TAG] = useMessage('ADD_NEW_TAG')
  const [popupVisible, setPopupVisible] = React.useState(false)

  return (
    <React.Fragment>
      <div style={{ paddingLeft: 32, marginTop: 16 }}>
        <Tag isButton={true} ghost={true} text={<AddButton text={ADD_NEW_TAG} />} onClick={() => setPopupVisible(true)} />
        {tags.map((tag) => {
          const isSelected = (tagsWithStoryIds[tag.id] || {})[story.id]
          return <Tag key={tag.id} text={tag.label} ghost={!isSelected} onClick={() => handleTagClick({ isSelected, tag })} />
        })}
      </div>
      {popupVisible && <Form hideForm={() => setPopupVisible(false)} />}
    </React.Fragment>
  )
}

const Tag = styled(Pill)`
  margin-bottom: 8px;
  margin-right: 8px;
`

const AddButton = withTheme(({ theme, text }) => {
  return (
    <div style={{ display: 'flex' }}>
      <div
        style={{
          width: 32,
          backgroundColor: theme.pillButtonIconBGColor,
          borderRadius: '24px 0px 0px 24px',
          margin: 0,
          padding: 0,
          height: 22,
          marginLeft: -8,
          textAlign: 'center',
          lineHeight: '22px',
          color: '#fff',
        }}>
        <FontIcon iconName={'Add'} />
      </div>
      <div style={{ flex: 1, paddingLeft: 8, lineHeight: '22px' }}>{text}</div>
    </div>
  )
})

export default Component
