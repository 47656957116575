const messages = {
  // Subscription menu
  MARK_AS_READ: 'Mark as read',
  SYNC: 'Sync',
  RENAME: 'Rename',
  REMOVE: 'Remove',
  SUMMARY: 'Summary',
  WEB_VIEW: 'Webview',
  READABLE: 'Reabable',

  // Multiple
  SYNC_FINISHED: 'Sync finished',
  SYNC_SUBSCRIPTION: (subscriptionName) => `Syncing ${subscriptionName}`,
  SYNC_ACCOUNT: 'Syncing account...',
  SYNC_SUBSCRIPTIONS: 'Syncing subscriptions...',
  SEARCHING: (q) => `Searching ${q}...`,
  SYNC_PROFILE: 'Syncing Profile...',
  ADD: 'Add',
  SETTINGS: 'Settings',
  SAVE: 'Save',
  CANCEL: 'Cancel',
  RENAME_FOLDER: 'Rename folder',
  RENAME_SUBSCRIPTION: 'Rename subscription',
  UNCATEGORISED: 'Uncategorised',
  ALL: 'All',
  LATER: 'Later',
  FAVOURITE: 'Favourites',
  CONFIRM: 'Confirm',
  CONFIRM_DELETE_SUBSCRIPTION: 'Are you sure you want to delete subscription?',
  MOMENTS_AGO: 'moments ago',
  DIF_MINUTES: (t, isPlural) => `${t} minutes`,
  DIF_HOURS: (t, isPlural) => `${t} hour${isPlural ? 's' : ''}`,
  DIF_DAYS: (t, isPlural) => `${t} day${isPlural ? 's' : ''}`,
  DIF_MONTHS: (t, isPlural) => `${t} month${isPlural ? 's' : ''}`,
  DIF_YEARS: (t, isPlural) => `${t} year${isPlural ? 's' : ''}`,
  EMPTY_LIST_LATER_TITLE: 'No stories yet',
  EMPTY_LIST_LATER_SUBTEXT: 'The stories you want to read later will be here',
  EMPTY_LIST_FAVOURITE_TITLE: 'No stories yet',
  EMPTY_LIST_FAVOURITE_SUBTEXT: 'The stories you want to save as favourite will be here',
  EMPTY_LIST_FOLDER_TITLE: 'No stories found',
  EMPTY_LIST_FOLDER_SUBTEXT: 'This folder has no stories to show',
  EMPTY_LIST_SUBSCRIPTION_TITLE: 'No stories found',
  EMPTY_LIST_SUBSCRIPTION_SUBTEXT: 'This subscription has no stories to show',
  EMPTY_LIST_UNKNOWN_TITLE: 'No stories found',
  EMPTY_LIST_UNKNOWN_SUBTEXT: 'No stories found in this list',
  BY_AUTHOR: 'By',
  COPY_LINK: 'Copy link',
  MARK_AS_UNREAD: 'Mark as unread',
  HIDE_WEB_VIEW: 'Hide webview',
  AUTH_CANCELLED_BY_USER: 'Authorization cancelled by user',
  CREATING_USER: 'Creating user...',
  USER_CREATED: 'User created!!',
  APP_HERO_TITLE: 'Read. Learn. Grow.',
  APP_HERO_SUB_TITLE: `VEEN lets you connect and read things from multiple sources`,
  REGISTERING_USER: 'Registering user.....',
  REGISTER: 'Register',
  LOGIN: 'Login',
  FULL_NAME_LABEL: 'Full Name',
  FULL_NAME_PLACEHOLDER: 'Enter full name',
  EMAIL_LABEL: 'Email Address',
  CREATE_PASSWORD_LABEL: 'Create Password',
  VALIDATION_FULL_NAME_REQUIRED: 'Full name is required',
  VALIDATION_PASSWORD_REQUIRED: 'Password is required',
  VALIDATION_EMAIL_REQUIRED: 'Email is required',
  VALIDATION_EMAIL_FORMAT: 'Must be a valid email',
  VALIDATION_USERNAME_REQUIRED: 'Username is required',
  USERNAME_PLACEHOLDER: 'Email or username',
  PASSWORD_PLACEHOLDER: 'Password',
  LOG_IN_PROGRESS_USER: 'Login in progress....',
  SWITCH_ACCOUNT: 'Switch Account',
  INTEGRATION_NAMES: {
    feedly: 'Feedly', //keys are integration Codes
    foundry: 'Foundry',
  },
  CONNECTIONS: 'Connections',
  ACCOUNT: 'Account',
  SYNC_ON_START: 'Sync on start',
  ENABLED: 'Enabled',
  DISABLED: 'Disabled',
  LANGUAGE: 'Language',
  CHOOSE_LANGUAGE: 'Choose language',
  CHOOSE_THEME: 'Choose Theme',
  THEME: 'Theme',
  THEME_NAMES: {
    1: 'Light',
    2: 'Dark',
  },
  PLEASE_UPGRADE_APP: 'Please upgrade your app',
  DELETE_ACCOUNT: 'Are your sure you want to delete the account?',
  DELETE: 'Delete',
  MANAGE_ACCOUNTS: 'Manage accounts',
  ADD_ACCOUNT: 'Add new account',
  CREATE_ACCOUNT: 'Create an account',
  PLAN_DETAILS: 'Plan details',
  CHANGE: 'Change',

  VALIDATION_OLD_PASSWORD_REQUIRED: 'Old password is required',
  VALIDATION_NEW_PASSWORD_REQUIRED: 'New password is required',
  CHANGE_PASSWORD_OLD_PLACEHOLDER: 'Enter old password',
  CHANGE_PASSWORD_NEW_PLACEHOLDER: 'Enter new password',
  CHANGE_PASSWORD_OLD_LABEL: 'Old password',
  CHANGE_PASSWORD_NEW_LABEL: 'New Password',
  UPDATE: 'Update',
  CHANGE_PASSWORD_FORM_TITLE: 'Change password',
  CHANGE_PASSWORD_SUCCESS_MESSAGE: 'Password changed successfully',
  LOGOUT_PLACEHOLDER: 'Logout',
  LOGOUT_CONFIRM_MESSAGE: 'Are you sure you want to logout',

  SEARCH_RESULT_FROM: (term) => `Search result for ${term}`,
  SUBSCRIPTION_SYSTEM_SEARCHBOX_PLACEHOLDER: 'Type title, topic,  url or keyword',
  TYPE_TITLE_URL: 'Type title, topic,  url or keyword',
  FIND_SUBSCRIPTIONS: 'Find subscriptions',
  NO_RESULTS_FOUND: 'No Results found',
  TRY_ANOTHER_TERM: 'Try another term',
  SUBSCRIBERS: 'Subscribers',
  ITEMS_PER_WEEK: 'Items per week',
  SUBSCRIBE: 'Subscribe',
  UNKNOWN_ERROR: 'Unknown error',
  LOADING: 'Loading...',
  SUCCESS: 'Success',
  FAIL: 'Failed',
  UPDATING: 'Updating...',
  REMOVING: 'Removing...',
  ENTER_A_FOLDER_NAME: 'Enter a folder name',
  TAGS: 'Tags',
  OK: 'OK',
  ALERT: 'Alert',
  SESSION_TIMED_OUT: 'Your session timed out. Please login again. Thanks',

  ENTER_YOUR_EMAIl_ADDRESS: 'Email Address',
  ENTER_OTP: 'Enter Otp',
  ENTER_NEW_PASSWORD: 'Enter a new password',
  SUBMIT: 'Submit',
  FORGOT_PASSWORD: 'Forgot Password?',

  REQUESTING_OTP: 'Sending OTP to your email',
  REQUESTING_OTP_ERROR: 'Some error occured...',
  REQUESTING_OTP_SUCCESS: 'Please enter OTP received on your email',

  SUBMITTING_OTP: 'Submitting OTP',
  SUBMITTING_OTP_ERROR: 'Error in Submitting OTP',
  SUBMITTING_OTP_SUCCESS: 'Please enter a new password',
  INVALID_OTP: 'Invalid Otp',

  CHANGING_PASSWORD: 'Saving..',
  CHANGING_PASSWORD_ERROR: 'Some error occured...',
  CHANGING_PASSWORD_SUCCESS: 'Done. Please login with new password',
  ADD_NEW_TAG: 'Add new',
  TAG_NAME_REQUIRED: 'Tag name is required',
  ENTER_A_TAG_NAME: 'Tag name',
  ADD_NEW_TAG_FORM_TITLE: 'Create a tag',
  USER_LEVEL: 'User level',
  SYNC_TAGS: 'Syncing tags...',
  SYNCING_MARKERS: 'Syncing markers...',
  SYNCING_COUNTS: 'Syncing counts...',
  SYNCING_STORIES: 'Syncing stories...',
  SYNCING_STORIES_WITH_BATCH_NUMBER: (n) => {
    return `(${n}) Syncing stories...`
  },
  SYNCING_ACCOUNT_STORIES_WITH_BATCH_NUMBER: (n) => {
    return `(${n}) Syncing account stories...`
  },
  SYNCING_TAG_STORIES_WITH_BATCH_NUMBER: (n, label) => {
    return `(${label}) Syncing tag stories...`
  },
  ONBOARDING_RECOMMENDATION_TITLE: 'Please choose some topics to start',
  ONBOARDING_RECOMMENDATION_COMPLETE_BTN: 'Complete',
  //**-----------BELOW ARE OLD KEYS----------- */

  WAIT: 'Wait...',
  APP_NAME: 'VEEN READER',

  //Add Strings Here
  WELCOME_MSG_1: 'welcome to',
  WELCOME_MSG_2: ' veen reader',
  ERROR: 'Error',

  OR: 'or',
  FEEDLY: 'feedly',
  FACEBOOK: 'facebook',
  FULLNAME: 'full name',
  FULLNAME_REQUIRED: 'Full name is required',
  START_WITH_LOCAL_ACCOUNT: 'start with local account',
  TWITTER: 'twitter',
  LOCAL: 'local',
  CONINTUE_WITH_FEEDLY: 'Continue with feedly',

  //Accounts
  ADD_NEW: 'Add New',
  REMOVE_ACCOUNT: 'Remove',
  REMOVE_ACCOUNT_SCREEN: 'Remove Account',
  UNDO_REMOVE: 'Undo',
  CHOOSE_A_SERVICE: 'Choose a service',
  CREATE: 'Create',

  BACK: 'Back',

  UNREAD: 'Unread',

  SYNCING: 'Syncing',
  ON: 'On',
  OFF: 'Off',
  GENERAl: 'General',
  TEMPLATE: 'Template',
  READING_OPTIONS: 'Reading Options',
  FONT_SIZE: 'Font Size',
  REMOVE_ITEMS_OLDER_THAN: 'Remove items older than',
  NEVER: 'Never',
  DAYS: 'Days',
  HINDI: 'Hindi',
  ENGLISH: 'English',
  CARD: 'Card',
  TILE: 'Tile',
  CLASSIC: 'Classic',

  NEWEST_FIRST: 'Newest first',
  OLDEST_FIRST: 'Oldest First',
  POPULAR_FIRST: 'Popular First',
  SEARCH: 'Search',
  SEARCH_BAR_TEXT: 'Search stories',
  NEW_FOLDER_NAME: 'Optional: New folder name',
  REFRESH_AFTER: 'Refresh after',
  EVERY_X_MINUTES: (n) => {
    return `Every ${n} minutes`
  },
  EVERY_X_HOUR: (n) => {
    return `Every ${n} hour${n > 0 ? 's' : ''}`
  },
  STATUS_COPIED_TO_CLIPBOARD: 'Copied to clipboard!!',

  SYNC_TOKEN: 'Sync token',
  SYNC_STORIES: 'Sync stories',

  STARTING_AUTH: 'Starting authorization',
  WAITING_FOR_AUTHRIZATION: 'Waiting for authorization',
  COLLECTING_TOKEN: 'Collecting Token',
  HELP: 'Help',
  MARK_LIST_AS_READ: 'Mark list as read',
  SUPPORT_EMAIL: 'Support email',
  PRIVACY_POLICY: 'Privacy Policy',
  USER_ALREADY_EXISTS: 'user already exists',
  MONTHS: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
}
export default messages
