import React from 'react'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { TextField } from 'APP/ui-kit/Vendor'
import { useMessage, FormattedMessage } from 'APP/language'
import FormModal from 'APP/ui-kit/Modals/Form'
import { useGetActions } from 'APP/utils/reduxHooks'

const initialValues = {
  label: '',
}

const schema = yup.object().shape({
  label: yup.string().required('TAG_NAME_REQUIRED'),
})

const config = {
  initialValues,
  validationSchema: schema,
}

const Form = ({ hideForm = () => {} }) => {
  const { createNewTag } = useGetActions()
  const [ENTER_A_TAG_NAME, SUBMIT, CANCEL, ADD_NEW_TAG_FORM_TITLE] = useMessage('ENTER_A_TAG_NAME', 'SUBMIT', 'CANCEL', 'ADD_NEW_TAG_FORM_TITLE')
  const [formErrorProp, setFormErrorProp] = React.useState(null)

  const { handleSubmit, handleChange, values, errors, isSubmitting, setSubmitting, setErrors } = useFormik({
    ...config,
    onSubmit: (values) => {
      setSubmitting(true)

      createNewTag({
        data: values,
        callback: (success, message, errs) => {
          if (!success) {
            setSubmitting(false)
            errs && setErrors(errs)
            setFormErrorProp(message)
          } else {
            hideForm()
          }
        },
      })
    },
  })

  const customHandleSubmit = (e) => {
    e.preventDefault()
    handleSubmit()
  }

  return (
    <FormModal
      formErrorProp={formErrorProp}
      primaryBtnTxt={SUBMIT}
      secondryBtnTxt={CANCEL}
      disabled={isSubmitting}
      modalTitle={ADD_NEW_TAG_FORM_TITLE}
      hideModal={hideForm}
      onSubmit={customHandleSubmit}>
      <form onSubmit={customHandleSubmit}>
        <TextField
          disabled={isSubmitting}
          errorMessage={errors['label'] && <FormattedMessage id={errors['label']} />}
          placeholder={ENTER_A_TAG_NAME}
          label={ENTER_A_TAG_NAME}
          value={values.label}
          name="label"
          onChange={handleChange}
          autoComplete="off"
          type="text"
        />
      </form>
    </FormModal>
  )
}

export default Form
