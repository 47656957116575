import Entity from './Entity'

// class Category {
// }

// Category.schema = {
//     name: 'category',
//     properties: {
//         id: 'mumber',
//         created: 'string',
//         label: 'string'
//     }
// };

class Subscription extends Entity {
  static entityName = 'integration_user_subscriptions'

  integrationUserId
  title
  visualUrl
  website
  categories

  static getSchema() {
    return {
      integrationUserId: 'string',
      title: 'string',
      visualUrl: 'string',
      website: 'string',
      // categories: [Category],
    }
  }
}

export default Subscription
