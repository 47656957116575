import React from 'react'
import * as S from './styled'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { InputField } from './Component'
import { REGISTRATION_PROVIDER } from 'APP/utils/enums'
import { useMessage } from 'APP/language'

const initialValues = {
  fullName: '',
  password: '',
  email: '',
}

const schema = yup.object().shape({
  fullName: yup.string().required('VALIDATION_FULL_NAME_REQUIRED'),
  email: yup.string().required('VALIDATION_EMAIL_REQUIRED').email('VALIDATION_EMAIL_FORMAT'),
  password: yup.string().required('VALIDATION_PASSWORD_REQUIRED'),
})

const config = {
  initialValues,
  validationSchema: schema,
}

const Form = ({ register, login, updateFormMessage }) => {
  const [REGISTERING_USER, REGISTER, FULL_NAME_LABEL, EMAIL_LABEL, CREATE_PASSWORD_LABEL, LOG_IN_PROGRESS_USER] = useMessage(
    'REGISTERING_USER',
    'REGISTER',
    'FULL_NAME_LABEL',
    'EMAIL_LABEL',
    'CREATE_PASSWORD_LABEL',
    'LOG_IN_PROGRESS_USER'
  )

  const { handleSubmit, handleChange, values, errors, isSubmitting, setSubmitting, setErrors, touched, handleBlur } = useFormik({
    ...config,
    onSubmit: (values) => {
      updateFormMessage(REGISTERING_USER)
      setSubmitting(true)
      register({
        data: { ...values, provider: REGISTRATION_PROVIDER.LEGATO },
        callback: (success, message, errs) => {
          if (!success) {
            setSubmitting(false)
            updateFormMessage(message)
            errs && setErrors(errs)
          } else {
            updateFormMessage(LOG_IN_PROGRESS_USER)
            login({ credentials: { username: values.email, password: values.password } })
          }
        },
      })
    },
  })
  return (
    <S.HtmlFormContainer
      onSubmit={(e) => {
        e.preventDefault()
        handleSubmit()
      }}>
      <InputField
        autoComplete="off"
        type="text"
        tabIndex={1}
        disabled={isSubmitting}
        error={touched['fullName'] && errors['fullName']}
        placeholder={FULL_NAME_LABEL}
        name="fullName"
        value={values.fullName}
        onChange={handleChange}
        onBlur={handleBlur}
      />
      <InputField
        autoComplete="off"
        type="text"
        tabIndex={2}
        disabled={isSubmitting}
        error={touched['email'] && errors['email']}
        placeholder={EMAIL_LABEL}
        name="email"
        value={values.email}
        onChange={handleChange}
        onBlur={handleBlur}
      />

      <InputField
        autoComplete="off"
        type="password"
        tabIndex={3}
        disabled={isSubmitting}
        error={touched['password'] && errors['password']}
        placeholder={CREATE_PASSWORD_LABEL}
        name="password"
        value={values.password}
        onChange={handleChange}
        onBlur={handleBlur}
      />
      <S.Button tabIndex={4} disabled={isSubmitting} type="submit">
        {REGISTER}
      </S.Button>
    </S.HtmlFormContainer>
  )
}

export default Form
