import React from 'react'
import { withTheme } from 'styled-components'
import { useMessage } from 'APP/language/'
import { Tabs } from 'APP/ui-kit/Vendor'
import { Row, Col } from 'APP/ui-kit/Grid'
import Settings from 'APP/components/Settings'
import IntegrationUserList from 'APP/components/AccountsManager/IntegrationUserList'
import ConnectionsList from 'APP/components/AccountsManager/ConnectionsList'
import AccountTab from 'APP/components/AccountsManager/AccountTab'
import AppVersion from 'APP/components/Multiple/AppVersion'

const TabItemsMap = {
  GENERAl: Settings,
  CONNECTIONS: () => (
    <React.Fragment>
      <ConnectionsList />
      <IntegrationUserList />
    </React.Fragment>
  ),
  ACCOUNT: () => <AccountTab />,
}

const Component = withTheme(({ theme }) => {
  const [CONNECTIONS, ACCOUNT, GENERAl] = useMessage('CONNECTIONS', 'ACCOUNT', 'GENERAl')
  const [selectedKey, setSelectedKey] = React.useState('GENERAl')
  const ComponentToRender = TabItemsMap[selectedKey]

  return (
    <React.Fragment>
      <Row cols>
        <Col
          length={52}
          style={{
            background: theme.bodyBackground,
            paddingTop: 8,
            textAlign: 'center',
          }}>
          <Tabs onLinkClick={(item) => setSelectedKey(item.props.itemKey)} headersOnly={true} selectedKey={selectedKey} linkSize={Tabs.Size.large} aria-label="General">
            <Tabs.Item itemKey="GENERAl" headerText={GENERAl} headerButtonProps={{ 'data-order': 1, 'data-title': GENERAl }} />
            <Tabs.Item itemKey="CONNECTIONS" headerText={CONNECTIONS} headerButtonProps={{ 'data-order': 2, 'data-title': CONNECTIONS }} />
            <Tabs.Item itemKey="ACCOUNT" headerText={ACCOUNT} headerButtonProps={{ 'data-order': 3, 'data-title': ACCOUNT }} />
          </Tabs>
        </Col>
        <Col
          style={{
            background: theme.subduedBackground,
            overflowY: 'auto',
            paddingBottom: 32,
          }}>
          <ComponentToRender />
          <AppVersion />
        </Col>
      </Row>
    </React.Fragment>
  )
})

export default Component
