import React from 'react'
import styled from 'styled-components'
import { ProgressIndicator as ProgressIndicatorBase } from 'APP/ui-kit/Vendor'

const ProgressIndicatorStyled = styled(ProgressIndicatorBase)`
  .ms-ProgressIndicator-itemProgress {
    padding: 0px !important;
  }
`

const intervalDelay = 100
const intervalIncrement = 0.01
const ProgressIndicatorCustom = () => {
  const [percentComplete, setPercentComplete] = React.useState(0)
  React.useEffect(() => {
    const id = setInterval(() => {
      setPercentComplete((intervalIncrement + percentComplete) % 1)
    }, intervalDelay)
    return () => {
      clearInterval(id)
    }
  })

  return <ProgressIndicatorStyled barHeight={2} percentComplete={percentComplete} />
}

export default ProgressIndicatorCustom
