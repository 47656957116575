import Entity from './Entity'
import { FOLDER_IDS } from 'APP/utils/enums'

class IntegrationUser extends Entity {
  static entityName = 'integration_users'
  userId
  integrationCode
  email
  fullName
  selectedFolderId

  static getSchema() {
    return {
      userId: 'string',
      integrationCode: 'string',
      email: 'string',
      fullName: 'string',
      selectedFolderId: { type: 'string', default: FOLDER_IDS.ALL },
    }
  }

  static async findByIndex({ integrationUserIndex }) {
    let users = await this.getDoc().find({}, (query) => {
      query = query.sort({ created: -1 })
      return query
    })
    return users[integrationUserIndex] || null
  }

  static async findAll() {
    return await this.getDoc().find({}, (query) => {
      query = query.sort({ created: -1 })
      return query
    })
  }
}
export default IntegrationUser
