import styled from 'styled-components'
import { PrimaryButton, DefaultButton, Button } from 'APP/ui-kit/Vendor'

const DangerButton = styled(DefaultButton)`
  border: 1px solid ${(p) => p.theme.dangerButtonBorder};
  color: ${(p) => p.theme.dangerButtonText} !important;
  &:hover {
    background: ${(p) => p.theme.dangerButtonHoverBackground};
  }
`

const LinkButton = styled(DefaultButton)`
  border: 1px solid ${(p) => p.theme.linkButtonBorderColor};
  border-radius: ${(p) => p.theme.borderRadius2};
  height: 24px;
`

export { PrimaryButton, DefaultButton, Button, DangerButton, LinkButton }
