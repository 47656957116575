import React from 'react'
import { useRouteMatch } from 'react-router-dom'

import Register from './RegisterForm'
import Login from './Login'
import ForgotPassword from './ForgotPassword'
import * as S from './styled'
import { useMessage } from 'APP/language'
import { ROUTES } from 'APP/utils/enums'

const FormsDict = {
  [ROUTES.ONBOARDING_LOGIN]: Login,
  [ROUTES.ONBOARDING_REGISTER]: Register,
  [ROUTES.ONBOARDING_FORGOT_PASSWORD]: ForgotPassword,
}

const Forms = ({ login, register, forgotPassword }) => {
  let match = useRouteMatch()
  const [formMessage, setFormMessage] = React.useState('')
  const [REGISTER, LOGIN, FORGOT_PASSWORD] = useMessage('REGISTER', 'LOGIN', 'FORGOT_PASSWORD')
  const activeForm = match && match.path && FormsDict[match.path] ? match.path : ROUTES.ONBOARDING_LOGIN

  React.useEffect(() => {
    // When form changes, remove form error
    setFormMessage('')
  }, [activeForm])

  const FormToRender = FormsDict[activeForm]
  return (
    <div>
      <S.FormLabelsContainer>
        <S.FormLabel to={ROUTES.ONBOARDING_LOGIN} $active={activeForm === ROUTES.ONBOARDING_LOGIN}>
          {LOGIN}
        </S.FormLabel>
        <S.FormLabel to={ROUTES.ONBOARDING_REGISTER} $active={activeForm === ROUTES.ONBOARDING_REGISTER}>
          {REGISTER}
        </S.FormLabel>
      </S.FormLabelsContainer>
      {formMessage && <S.FormMessage>{formMessage}</S.FormMessage>}
      <FormToRender updateFormMessage={(msg) => setFormMessage(msg)} login={login} register={register} forgotPassword={forgotPassword} />
      {activeForm !== ROUTES.ONBOARDING_FORGOT_PASSWORD && (
        <S.ForgotPasswordContainer>
          <S.ForgotPassword to={ROUTES.ONBOARDING_FORGOT_PASSWORD}>{FORGOT_PASSWORD}</S.ForgotPassword>
        </S.ForgotPasswordContainer>
      )}
    </div>
  )
}

export default Forms
