import React from 'react'
import { useSelector } from 'react-redux'
import { Route, useHistory, useLocation } from 'react-router-dom'
import { SCREEN_NAMES, ROUTES } from 'APP/utils/enums'
import Splash from 'APP/sections/Splash'
import Layout, { setDefaultSections } from 'APP/ui-kit/Layouts/Dashboard'
import { LogUIState, useUIState } from 'APP/utils/uiHooks'
import { getUser } from 'APP/utils/reduxSelectors'

//Layout sections
import Sidebar from 'APP/sections/Sidebar'
import SubscriptionList from 'APP/sections/SubscriptionList'
import StoriesList from 'APP/sections/StoriesList'
import StoryDetails from 'APP/sections/StoryDetails'

// setup default sections for Dashboard
setDefaultSections({ leftSection: SubscriptionList, rightSection: StoryDetails, middleSection: StoriesList, sidebar: Sidebar })

const BaseRouteInner = ({ component: Component, onboarding, path, layoutProps, secured }) => {
  const history = useHistory()
  const screen = useUIState((state) => state.screen)
  let location = useLocation()

  React.useEffect(() => {
    //Anonymous user trying to access Secured/non-onboarding
    if (screen === SCREEN_NAMES.WELCOME && (secured || !onboarding)) {
      history.replace(ROUTES.ONBOARDING_LOGIN)
    }

    //Logged in user is trying access onboarding/404 => Redriect him to dashboard
    if (screen === SCREEN_NAMES.DASHBOARD && (onboarding || path === ROUTES.CATCH_ALL)) {
      let { from } = location.state || { from: null }
      history.replace(from || ROUTES.generateDashboardHomePath(0))
    }
    // if user is already logged then he will be redirected to Dashboard if he tries onBoarding or 404
    // if (screen === SCREEN_NAMES.SPLASH && user && (onboarding || path === ROUTES.CATCH_ALL)) {
    //   history.replace(ROUTES.DASHBOARD_ROOT)
    // }
    // // if user is NOT logged then he will be redirected to Welcome if he tries 404 items
    // if (screen !== SCREEN_NAMES.SPLASH && !user && path === ROUTES.CATCH_ALL) {
    //   history.push(ROUTES.ONBOARDING_LOGIN)
    // }
  }, [screen])

  console.log('------>----', screen, secured, onboarding)

  // If eligible screen=splash, render Splash
  if (screen === SCREEN_NAMES.SPLASH) {
    return <Splash />
  }
  // If eligible screen=Welcome, and user has requested for secured page...
  // Let's return null....Use Effect hook will redirect to login
  if (screen === SCREEN_NAMES.WELCOME && (secured || !onboarding)) {
    return null
  }
  return <Component {...layoutProps} />
}

export const BaseRoute = ({ path, component, onboarding, layoutProps, secured, ...rest }) => {
  return (
    <Route path={path} {...rest}>
      <LogUIState />
      <BaseRouteInner {...{ component, onboarding, layoutProps, path }} secured={secured || false} />
    </Route>
  )
}

export const SecuredRoute = (props) => {
  let location = useLocation()
  const history = useHistory()
  const user = useSelector(getUser)
  const screen = useUIState((state) => state.screen)
  console.log('----Render-->Secured---', screen)
  //@TODO: Review this hook
  React.useEffect(() => {
    console.log('React.useEffect---->', screen)
    if (screen !== SCREEN_NAMES.SPLASH && !user) {
      history.replace(ROUTES.ONBOARDING_LOGIN, { from: { pathname: location.pathname } })
    }
  }, [screen, user])
  return <BaseRoute {...props} secured />
}

export const DashboardLayout = ({ left, right, middle, ...rest }) => {
  return <SecuredRoute {...rest} component={Layout} layoutProps={{ left, right, middle }} />
}

export const PublicRoute = Route
