import { colors as defaultColors, theme as defaultTheme } from './defaultTheme'

const colors = {
  ...defaultColors,

  // Below colors are coming from fabric Codes
  // (Do not use these names in your codes)
  themeDarker: '#82c7ff',
  themeDark: '#6cb8f6',
  themeDarkAlt: '#3aa0f3',
  themePrimary: '#2899f5',
  themeSecondary: '#0078d4',
  themeTertiary: '#235a85',
  themeLight: '#004c87',
  themeLighter: '#043862',
  themeLighterAlt: '#092c47',
  black: '#ffffff',
  neutralDark: '#faf9f8',
  neutralPrimary: '#f3f2f1',
  neutralPrimaryAlt: '#c8c6c4',
  neutralSecondary: '#a19f9d',
  neutralSecondaryAlt: '#979693',
  neutralTertiary: '#797775',
  neutralTertiaryAlt: '#484644',
  neutralQuaternary: '#3b3a39',
  neutralQuaternaryAlt: '#323130',
  neutralLight: '#292827',
  neutralLighter: '#252423',
  neutralLighterAlt: '#201f1e',
  white: '#1b1a19',
  redDark: '#F1707B',

  /**
   * Generic
   */
  bodyBackground: '#1d1b1a',
  subduedBackground: '#323130',
  elevation1: '0 1.6px 3.6px 0 rgba(0, 0, 0, 0.532), 0 0.3px 0.9px 0 rgba(0, 0, 0, 0.508)',
  rowBorder: '#3b3a39',
  subduedText: '#a19f9d',
  frameBorderColor: '#3b3a39',

  /**
   * UI KIT
   */
  dangerButtonBorder: '#F1707B',
  dangerButtonText: '#f9696b',
  dangerButtonHoverBackground: '#31191b',

  cardBackground: '#222',
  cardBorder: '#2f2e2e',
  cardDisabledBackground: '#383838',
  uiBlockerBgColor: 'rgba(51,51,51,0.52)',

  /**
   * Component Colors
   */
  connectionCardIconBackgroundColor: '#444',
  integrationUserCardSelectedBgColor: '#004578',
  integrationUserCardHoverBgColor: '#092c47',
  foldersListBGColor: '#222',
  folderTextColor: '#797775',
  folderHoverTextColor: '#a19f9d',
  folderSelectedBgColor: '#2f2e2e',
  folderMarkerSelectedBgColor: '#0078d4',
  folderMenuActiveBgColor: '#0078d4',
  standardFolderTextColor: '#c8c6c4',
  standardFolderSelectedTextColor: '#0078d4',
  subscriptionTextColor: '#cccccc',
  subscriptionSelectedBgColor: '#092c47',
  subscriptionHoverBgColor: '#3b3a39',
  subscriptionMarkerSelectedBgColor: '#0078d4',
  subscriptionMenuActiveBgColor: '#a19f9d',
  sidebarBgColor: '#2b2b2b',
  sidebarItemTextColor: '#eee',
  sidebarItemHoverTextColor: '#fff',
  sidebarItemHoverBgColor: '#212121',
  sidebarSelectedItemBgColor: '#404040',
  skeletonCardBottomBorderColor: '#252423',
  storyCardBottomBorderColor: '#3b3a39',
  storyCardSelectedBackgroundColor: '#3b3a39',
  storyCardImageBgColor: '#292827',
  stortyCardTitleTextColor: '#c8c6c4',
  stortyCardTitleReadTextColor: '#666',
  stortyCardTitleSelectedTextColor: '#c8c6c4',
  emptyStoryListIconColor: '#009688',
  emptyStoryListTitleColor: '#979693',
  emptyStoryListSubTitleColor: '#797775',
  emptyStoryDetailsBgColor: '#333',
  storyDetailsTextColor: '#aaa',
  storyDetailsTitleTextColor: '#ddd',
  subscriptionManagerIconBorderColor: '#fff',
  subscriptionManagerSubtextColor: '#797775',
  subscriptionManagerDescriptionColor: '#bbb',
  subscriptionManagerSeparotorColor: '#3b3a39',
  pillBorderColor: ' #a19f9d',
  pillBackgroundColor: '#1b7fcc',
  pillTextColor: '#fff',
}

const theme = {
  ...defaultTheme,
  ...colors,
}

export { colors, theme }
