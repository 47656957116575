import { put, call, select } from 'redux-saga/effects'
import { updateSuccessMessage } from 'APP/components/MessageBar/model'
import { actions as AppActions } from 'APP/components/Models/app'
import { actions as integrationUsersActions } from 'APP/components/Models/integrationUsers'
import * as SubscriptionListModel from 'APP/components/SubscriptionList/model'
import * as StoriesListModel from 'APP/components/StoriesList/model'
import * as MarkersModel from 'APP/components/Models/markers'
import * as TagsModel from 'APP/components/Models/tags'
import { runSaga } from 'APP/utils/reduxUtils'
import { getTags, getMany, getMessagesDict } from 'APP/utils/reduxSelectors'
import { SYSTEM_TAGS } from 'APP/utils/enums'
import db from 'APP/services/dbZervice'
import { CACHE_KEYS } from 'APP/utils/enums'

const messageBarStatusId = 'full-sync'

class SyncManager {
  user
  integrationUser
  constructor(user, integrationUser) {
    this.user = user
    this.integrationUser = integrationUser
  }

  *syncInteUserSubscriptions() {
    yield console.log('Sync subscriptions')
    yield runSaga(SubscriptionListModel, SubscriptionListModel.actions.sync.request)({ payload: { messageBarStatusId } })
  }

  *syncAccountStories({ startTime }) {
    yield console.log('Sync account stories')
    // yield runSaga(StoriesListModel, StoriesListModel.actions.syncAccount.request)({ payload: { messageBarStatusId, startTime } })
    const statusBar = {
      progress: true,
      messageKeys: {
        progress: {
          key: 'SYNCING_ACCOUNT_STORIES_WITH_BATCH_NUMBER',
        },
      },
    }
    yield runSaga(StoriesListModel, StoriesListModel.actions.fetchStoriesOfAStream.request)({ payload: { direction: 'forward', streamId: SYSTEM_TAGS.ALL.id, statusBar, messageBarStatusId } })
  }

  *syncAllSubscriptionStories({ startTime }) {
    yield console.log('Sync all subscription stories...')
    yield runSaga(StoriesListModel, StoriesListModel.actions.syncAll.request)({ payload: { messageBarStatusId, startTime } })
  }

  *syncMarkers({ startTime }) {
    yield console.log('Sync  markers ...')
    // yield runSaga(MarkersModel, MarkersModel.actions.fetchMarkers.request)({ payload: { messageBarStatusId, startTime } })
    yield put(MarkersModel.actions.fetchMarkers.request({ messageBarStatusId, startTime }))
  }

  *syncCounts() {
    yield console.log('Sync  counts ...')
    // yield runSaga(MarkersModel, MarkersModel.actions.fetchCounts.request)()
    yield put(MarkersModel.actions.fetchCounts.request({ messageBarStatusId }))
  }

  *syncTags({ integrationUser }) {
    // Below codes fetch only stories..decide a way to fetch tags
    console.log('Sync  Tags ...')
    yield runSaga(TagsModel, TagsModel.actions.fetchTags.request)({ payload: { messageBarStatusId } })
  }

  *syncTagStories({ integrationUser, startTime }) {
    // Below codes fetch only stories..
    console.log('Sync  Tag stories ...')
    // all tags
    const [tags] = yield select((state) => getMany(state, getTags))

    // Tags ids to sync
    const messages = yield select(getMessagesDict)
    const tagsToSync = tags.filter((t) => t.canSync).map((t) => ({ id: t.id, label: t.label || messages[t.labelKey] || 'Tag' }))
    const tagIds = tagsToSync.map((t) => t.id)

    for (let tagToSync of tagsToSync) {
      const statusBar = {
        progress: true,
        messageKeys: {
          progress: {
            key: 'SYNCING_TAG_STORIES_WITH_BATCH_NUMBER',
            data: tagToSync.label,
          },
        },
      }
      yield runSaga(
        StoriesListModel,
        StoriesListModel.actions.fetchStoriesOfAStream.request
      )({ payload: { direction: 'forward', streamId: tagToSync.id, tillStreamEnd: true, newestStory: {}, statusBar, messageBarStatusId } })
    }
    yield put(TagsModel.actions.cleanTaggedStories({ tagIds }))
    // clean all those tagStoryIds whose Tags not found
  }

  *run() {
    const { integrationUser } = this
    yield put(AppActions.updateSyncInProgress(true))

    const startTime = Date.now()

    // // sync subscription list
    yield call(this.syncInteUserSubscriptions)

    // // sync Tags
    yield call(this.syncTags, { integrationUser })

    // // // sync account marker
    yield call(this.syncMarkers, { startTime })

    // sync counts
    yield call(this.syncCounts)

    // // // // sync account stories
    yield call(this.syncAccountStories, { startTime })

    // // // sync tag stories
    yield call(this.syncTagStories, { integrationUser, startTime })

    // // update last successful sync time of the account
    yield put(integrationUsersActions.updateLastSuccessSyncTime({ startTime, integrationUser, isFullSync: true }))

    yield call(db.setCache, { key: CACHE_KEYS.HAS_SYNCED_ONCE, value: { data: true } })
    yield updateSuccessMessage('SYNC_FINISHED', messageBarStatusId, 5)
    yield put(AppActions.updateSyncInProgress(false))
  }
}

export default SyncManager
