import React from 'react'
import styled from 'styled-components'
import { FontIcon } from 'APP/ui-kit/Vendor'

const Bar = () => (
  <Container>
    <Icon iconName={'CheckMark'} />
    <Icon iconName={'SortLines'} /> {/** SortLines SortLinesAscending */}
    <Icon iconName={'Filter'} text="unread" />
    <Icon iconName={'Search'} style={{ marginLeft: 'auto' }} />
  </Container>
)

const Icon = ({ style = {}, iconName, onClick = () => {}, text }) => (
  <Box onClick={onClick} style={style}>
    <FontIcon iconName={iconName} />
    <span>{text}</span>
  </Box>
)

const Container = styled.div`
  display: flex;
  justify-content: flex-end;
  height: 40px;
  border-top: 1px solid ${(p) => p.theme.frameBorderColor};
`

const Box = styled.div`
  margin-left: 8px;
  min-width: 45px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  poistion: absolute;
  top: 0px;
  right: 0px;
  &:hover {
    color: ${(p) => p.theme.toolbarIconHoverColor};
  }
  span {
    padding-left: 6px;
  }
`

export default Bar
