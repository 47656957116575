import { handleActions, combineActions } from 'redux-actions'
import produce from 'immer'
import { put, takeLatest, call, select, takeEvery } from 'redux-saga/effects'
import { handleSaga, create3Actions } from 'APP/utils/reduxUtils'
import dbService from 'APP/services/dbZervice'
import { API_OPERATIONS, serviceEffext } from 'APP/services/serviceEffext'
import { getIntegrationUser, getMarkersInfo, getMarkerSubscriptionPostions, getCounts, getMany } from 'APP/utils/reduxSelectors'
import { getTimeXDaysAgo } from 'APP/utils/common'
import { get } from 'APP/utils/lodash'
import { CACHE_KEYS } from 'APP/utils/enums'

const MODULE_NAME = 'MARKERS'
const createActions = create3Actions(MODULE_NAME)

/* ------------- Actions ------------- */
export const actions = {
  fetchMarkers: createActions('fetch:markers/read/unread/positions'),
  loadStoryMarkers: createActions('load'),
  fetchCounts: createActions('fetch:counts'),
  loadCounts: createActions('loadCounts'),
  calculateCounts: createActions('calculateCounts'),
  saveCounts: createActions('saveCounts'),
  markAsRead: createActions('mark as read'),
  keepAsUnRead: createActions('keep as unread'),
  markSubscriptionAsRead: createActions('mark subscription as read'),
}

/* ------------- Initial state ------------- */
const initialState = {
  readIds: {},
  unReadIds: {},
  counts: {},
  subscriptionPostions: {}, // This stores last read position. Any story before this will marked as read
}

/* ------------- Reducer ------------- */
export const reducer = handleActions(
  {
    [combineActions(actions.loadStoryMarkers.success, actions.fetchMarkers.success)]: (state, { payload }) =>
      produce(state, (draft) => {
        const { readIds, unReadIds, subscriptionPostions } = payload
        const format = (items = []) => items.reduce((a, c) => (a[c] = 1) && a, {})
        draft.readIds = format(readIds)
        draft.unReadIds = format(unReadIds)
        draft.subscriptionPostions = subscriptionPostions.reduce((a, c) => {
          a[c.id] = c.asOf
          return a
        }, {})
      }),
    [combineActions(actions.saveCounts.request)]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.counts = payload.counts
      }),

    [actions.markAsRead.request]: (state, { payload }) =>
      produce(state, (draft) => {
        const { story } = payload
        const position = get(draft, 'subscriptionPostions', {})[story.subscriptionId]
        const deleteFromUnread = position && position >= story.published

        const shouldUpdateCount = deleteFromUnread ? !!draft.unReadIds[story.id] : !draft.readIds[story.id]
        if (shouldUpdateCount) {
          deleteFromUnread ? delete draft.unReadIds[story.id] : (draft.readIds[story.id] = 1)
          draft.counts[story.subscriptionId] = Number(draft.counts[story.subscriptionId] || 0) - 1
        }
      }),
    [actions.keepAsUnRead.request]: (state, { payload }) =>
      produce(state, (draft) => {
        const { story } = payload
        const position = get(draft, 'subscriptionPostions', {})[story.subscriptionId]
        const deleteFromUnread = position && position >= story.published

        const shouldUpdateCount = deleteFromUnread ? !!draft.readIds[story.id] : !draft.unReadIds[story.id]
        if (shouldUpdateCount) {
          deleteFromUnread ? (draft.unReadIds[story.id] = 1) : delete draft.readIds[story.id]
          draft.counts[story.subscriptionId] = Number(draft.counts[story.subscriptionId] || 0) + 1
        }
      }),
    [actions.markSubscriptionAsRead.success]: (state, { payload }) =>
      produce(state, (draft) => {
        const { subscription, lastStory } = payload
        draft.subscriptionPostions = draft.subscriptionPostions || {}
        draft.subscriptionPostions[subscription.id] = lastStory.published
      }),
  },
  initialState
)

/* ------------- Root Saga ------------- */
export const rootSaga = {
  [actions.fetchMarkers.request]: handleSaga(
    takeLatest,
    function* () {
      const integrationUser = yield select(getIntegrationUser)
      const newerThan = 1111 //This will be always 11111.
      const response = yield serviceEffext(API_OPERATIONS.GET_MARKERS, { newerThan })
      const { readIds, feeds, unreadIds } = response.data
      yield call(dbService.resetIntUserMarkers, { integrationUser })
      yield call(dbService.saveMarker, { ids: readIds, integrationUser, type: 'read' })
      yield call(dbService.saveMarker, { ids: unreadIds, integrationUser, type: 'unread' })
      yield call(dbService.saveMarker, { ids: feeds, integrationUser, type: 'subscriptionPostions' })
      yield put(actions.fetchMarkers.success({ readIds, subscriptionPostions: feeds, unReadIds: unreadIds }))
    },
    { progressMessageProducer: 'SYNCING_MARKERS' }
  ),

  [actions.loadStoryMarkers.request]: handleSaga(takeLatest, function* () {
    const integrationUser = yield select(getIntegrationUser)
    const { readIds, unReadIds, subscriptionPostions } = yield call(dbService.getMarkers, { integrationUser, query: { type: 'read' } })
    yield put(actions.loadStoryMarkers.success({ readIds, unReadIds, subscriptionPostions }))
  }),
  [actions.fetchCounts.request]: handleSaga(
    takeLatest,
    function* () {
      const response = yield serviceEffext(API_OPERATIONS.GET_MARKERS_COUNTS)
      const counts = response.data
      yield put(actions.saveCounts.request({ counts }))
    },
    { progressMessageProducer: 'SYNCING_COUNTS' }
  ),
  [actions.loadCounts.request]: handleSaga(takeLatest, function* () {
    const integrationUser = yield select(getIntegrationUser)
    const key = CACHE_KEYS.PRODUCE_COUNTS_KEY_FN(integrationUser)
    const response = yield call(dbService.getCacheValue, { key })
    const data = get(response, 'data.counts', null)
    if (data) {
      yield put(actions.saveCounts.request({ counts: JSON.parse(data) }))
    }
  }),
  [actions.calculateCounts.request]: handleSaga(takeLatest, function* () {
    const integrationUser = yield select(getIntegrationUser)
    const { autoMarkStoryAsReadOlderThan } = yield select(getMarkersInfo)
    const ignoreOlderThan = getTimeXDaysAgo(autoMarkStoryAsReadOlderThan)
    const counts = yield call(dbService.getCounts, { integrationUser, ignoreOlderThan })
    yield put(actions.saveCounts.request({ counts }))
  }),
  [actions.saveCounts.request]: handleSaga(takeLatest, function* ({ payload }) {
    const integrationUser = yield select(getIntegrationUser)
    const { counts, saveToDB = true } = payload
    if (saveToDB && counts) {
      const key = CACHE_KEYS.PRODUCE_COUNTS_KEY_FN(integrationUser)
      yield call(dbService.setCache, { key: key, value: { counts: JSON.stringify(counts) } })
    }
  }),

  [actions.markAsRead.request]: handleSaga(takeEvery, function* ({ payload }) {
    const integrationUser = yield select(getIntegrationUser)
    const newCounts = yield select(getCounts)
    const subscriptionPostions = yield select(getMarkerSubscriptionPostions)
    const { story } = payload
    const { subscriptionId } = story
    const position = subscriptionPostions[subscriptionId]

    // let options;
    // if (position) {
    //   if (position < story.published) {
    //     options = { type: 'read', operator: 'addMarker' }
    //   } else {
    //     options = { type: 'unread', operator: 'deleteMarker' }
    //   }
    // } else {
    //   options = { type: 'read', operator: 'addMarker' }
    // }
    // let options = { type: 'read', operator: 'addMarker' }
    // if (position && position > story.published) {
    //   options = { type: 'unread', operator: 'deleteMarker' }
    // }

    const deleteFromUnread = position && position > story.published
    const options = deleteFromUnread ? { type: 'unread', operator: 'deleteMarker' } : { type: 'read', operator: 'addMarker' }
    const marker = {
      storyId: story.id,
      integrationUserId: integrationUser._id,
      type: options.type,
    }

    // Update local DB
    yield call(dbService[options.operator], marker)

    // Update counts cache
    const key = CACHE_KEYS.PRODUCE_COUNTS_KEY_FN(integrationUser)
    yield call(dbService.setCache, { key: key, value: { counts: JSON.stringify(newCounts) } })

    // push to server
    yield serviceEffext(API_OPERATIONS.ADD_MARKER, {
      action: 'markAsRead',
      type: 'entries',
      entries: [
        {
          storyId: story.id,
          subscriptionId: subscriptionId,
          published: story.published,
        },
      ],
    })
  }),

  [actions.markSubscriptionAsRead.request]: handleSaga(takeEvery, function* ({ payload: { subscription } }) {
    const [integrationUser, subscriptionPostions, counts] = yield select((state) => getMany(state, getIntegrationUser, getMarkerSubscriptionPostions, getCounts))

    // this will update count in redux and db
    yield put(actions.saveCounts.request({ counts: { ...counts, [subscription.id]: 0 } }))

    // find newest story
    const lastStory = yield call(dbService.findOneStoryOfASubscriptions, { subscriptionIds: [subscription.id] })

    if (lastStory) {
      // update to redux
      yield put(actions.markSubscriptionAsRead.success({ subscription, lastStory }))

      // Add to DB (no need to wait, so not using yield call)
      const subscriptionPostions2 = { ...subscriptionPostions, [subscription.id]: lastStory.published }
      const positions = Object.keys(subscriptionPostions2).map((p) => ({ id: p, asOf: subscriptionPostions2[p] })) //convert to array
      dbService.saveMarker({ ids: positions, integrationUser, type: 'subscriptionPostions' })

      // push to server
      yield serviceEffext(API_OPERATIONS.ADD_MARKER, {
        action: 'markAsRead',
        type: 'feeds',
        feedIds: [subscription.id],
        lastReadEntryId: lastStory.id,
        lastReadEntryTime: lastStory.published,
      })
    }
  }),

  [actions.keepAsUnRead.request]: handleSaga(takeEvery, function* ({ payload }) {
    const integrationUser = yield select(getIntegrationUser)
    const newCounts = yield select(getCounts)
    const subscriptionPostions = yield select(getMarkerSubscriptionPostions)
    const { story } = payload
    const { subscriptionId } = story
    const position = subscriptionPostions[subscriptionId]

    const addToUnread = position && position > story.published
    const options = addToUnread ? { type: 'unread', operator: 'addMarker' } : { type: 'read', operator: 'deleteMarker' }
    const marker = {
      storyId: story.id,
      integrationUserId: integrationUser._id,
      type: options.type,
    }

    // Update local DB
    yield call(dbService[options.operator], marker)

    // Update counts cache
    const key = CACHE_KEYS.PRODUCE_COUNTS_KEY_FN(integrationUser)
    yield call(dbService.setCache, { key: key, value: { counts: JSON.stringify(newCounts) } })

    // push to server
    yield serviceEffext(API_OPERATIONS.ADD_MARKER, {
      action: 'keepUnread',
      type: 'entries',
      entries: [
        {
          storyId: story.id,
          subscriptionId: subscriptionId,
          published: story.published,
        },
      ],
    })
  }),
}
