import styled from 'styled-components'

const Fullscreen = styled.div`
  padding: 0px;
  margin: 0px;
  width: 100vw;
  max-height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  display: flex;
  background: ${(p) => p.theme.bodyBackground};
`

export default Fullscreen
