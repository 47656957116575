import { connect } from 'react-redux'
import { actions } from './model'
import Pure from './Pure'

const mapStateToProps = (state) => {
  return {}
}

export default connect(mapStateToProps, {
  register: actions.register.request,
  login: actions.login.request,
  forgotPassword: actions.forgotPassword.request,
})(Pure)
