import React from 'react'
import MessageBar from 'APP/ui-kit/MessageBar'
import { useFormattedMessage } from 'APP/language'
import { withTheme } from 'styled-components'

const Colors = withTheme(({ theme }) => {
  return <div>{theme.dangerButtonBorder}</div>
})

const Component = ({ state }) => {
  return (
    <div
      style={{
        position: 'absolute',
        right: 0,
        bottom: 0,
        background: '#f4f4f4',
        width: 250,
        height: '100vh',
      }}>
      <Colors />
    </div>
  )
}

export default Component
