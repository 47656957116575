import { handleActions } from 'redux-actions'
import { takeLatest, call, select, put } from 'redux-saga/effects'
import produce from 'immer'
import { handleSaga, runSaga, createAction, create3Actions } from 'APP/utils/reduxUtils'
import db from 'APP/services/dbZervice'
import { Platform } from 'APP/app/resources'
import * as User from 'APP/components/Models/user.js'
import * as Settings from 'APP/components/Settings/model'
import * as IntegrationUsers from 'APP/components/Models/integrationUsers'
import * as MessageBar from 'APP/components/MessageBar/model'
import { actions as UiBlockerActions } from 'APP/components/UiBlocker/model'
import config from 'APP/utils/config'
import { getSyncManager } from 'APP/components/SyncManagers'
import { getIntegrationUser, getUser, getMessages } from 'APP/utils/reduxSelectors'
import { actions as TagActions } from 'APP/components/Models/tags'
import { get } from 'APP/utils/lodash'
import { actions as dialogActions } from 'APP/components/Dialog/model'
import { CACHE_KEYS } from 'APP/utils/enums'
import { produceVersionKey, getUTCMillisecondsNow } from 'APP/utils/common'

const MODULE_NAME = 'APP'
const createActions = create3Actions(MODULE_NAME)

export const actions = {
  initialize: createActions('initialize'),
  sync: createActions('sync'),
  loadDashboard: createAction(MODULE_NAME, 'loadDashboard'),
  updateSyncInProgress: createAction(MODULE_NAME, 'updateSyncInProgress'),
  saveUIState: createAction(MODULE_NAME, 'saveUIState'),
  patchUIState: createAction(MODULE_NAME, 'patchUIState'),
  setDashboardReadyStatus: createAction(MODULE_NAME, 'setDashboardReadyStatus'),
  integrationUserChanged: createAction(MODULE_NAME, 'integrationUserChanged'),
  saveAppBlocker: createAction(MODULE_NAME, 'saveAppBlocker'),
  verifyAppBlocker: createActions(MODULE_NAME, 'verifyAppBlocker'),
}

const initialState = {
  syncInProgress: false,
  uiState: {},
  isDashboardReady: false,
  appBlocker: {},
}

export const reducer = handleActions(
  {
    [actions.updateSyncInProgress]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.syncInProgress = payload
      }),
    [actions.saveUIState]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.uiState = payload
      }),
    [actions.patchUIState]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.uiState = { ...draft.uiState, ...payload }
      }),
    [actions.setDashboardReadyStatus]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.isDashboardReady = payload
      }),
    [actions.verifyAppBlocker.complete]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.appBlocker = payload
      }),
  },
  initialState
)

export const rootSaga = {
  [actions.initialize.request]: handleSaga(takeLatest, function* () {
    try {
      yield put(MessageBar.actions.updateSplashMessage('Configuring device...'))

      console.log('configure Platform')
      Platform.configure({ RELAY_SERVER_URL: config.RELAY_SERVER_URL })

      console.log('Initialize database')
      yield call(db.initialize)

      yield put(actions.verifyAppBlocker.request())

      yield put(actions.loadDashboard())
    } catch (error) {
      yield put(MessageBar.actions.updateSplashMessage('Some error occured'))
      console.log('err', error)
    }
  }),
  [actions.loadDashboard]: handleSaga(takeLatest, function* () {
    try {
      yield put(actions.setDashboardReadyStatus(false))
      console.log('Load user') //Loading user  is part of initialization
      yield runSaga(User, User.actions.load.request)()

      yield put(MessageBar.actions.updateSplashMessage('Loading user....'))
      const user = yield select(getUser)
      const userFound = !!user
      if (userFound) {
        console.log('Load settings')
        yield runSaga(Settings, Settings.actions.load.request)()

        console.log('Load IntUsers')
        yield runSaga(IntegrationUsers, IntegrationUsers.actions.load.request)()

        // All critical things loaded. Lets just trigger actions
        yield put(MessageBar.actions.updateSplashMessage('Starting application'))

        console.log('Get settings from server')
        yield put(Settings.actions.get.request())
      }
      yield put(actions.setDashboardReadyStatus(true))
    } catch (error) {
      yield put(MessageBar.actions.updateSplashMessage('Some error occured'))
      console.log(error)
    }
    console.log('Unblock UI')
    yield put(UiBlockerActions.unBlockUI()) // Unblock UI in-case it's blocked
  }),
  [actions.sync.request]: handleSaga(takeLatest, function* () {
    const user = yield select(getUser)
    const integrationUser = yield select(getIntegrationUser)
    const SyncManager = getSyncManager(integrationUser.integrationCode)
    const iSy = new SyncManager(user, integrationUser)
    // call saga in iSy context
    yield call([iSy, iSy.run])
  }),
  [actions.integrationUserChanged]: handleSaga(takeLatest, function* () {
    // This is called whenever user index is changed
    yield put(TagActions.loadTags.request())
    yield put(TagActions.loadTaggedStories.request())

    // Start sync If needed
    const hasSynnedData = yield call(db.getCacheValue, { key: CACHE_KEYS.HAS_SYNCED_ONCE })
    if (!get(hasSynnedData, 'data.data', false)) {
      yield put(actions.sync.request())
    }
  }),
  [actions.saveAppBlocker]: handleSaga(takeLatest, function* ({ payload }) {
    const { error } = payload
    const status = get(error, 'response.status', '') + ''
    const customStatus = get(error, 'response.data.statusCode') + ''
    const responseData = get(error, 'response.data.data')
    if (status === '500' && customStatus === '601') {
      //save response data
      yield call(db.setCache, { key: CACHE_KEYS.APP_BLOCKER, value: responseData })
    }
    yield responseData
  }),
  [actions.verifyAppBlocker.request]: handleSaga(takeLatest, function* () {
    let blockerData = yield call(db.getCacheValue, { key: CACHE_KEYS.APP_BLOCKER })
    blockerData = get(blockerData, 'data')

    // If blocer data found
    if (blockerData) {
      const [ALERT] = yield select((s) => getMessages(s, ['ALERT']))

      // blocker  for app version
      if (blockerData.appVersion && blockerData.appVersion === produceVersionKey(config)) {
        yield put(
          dialogActions.showPopup({
            message: blockerData.message,
            title: ALERT,
            dialogContentProps: { showCloseButton: false },
          })
        )
        yield put(actions.verifyAppBlocker.complete(blockerData))
      }

      // blocker  for time
      if (blockerData.time && blockerData.time > getUTCMillisecondsNow()) {
        yield put(
          dialogActions.showPopup({
            message: blockerData.message,
            title: ALERT,
            dialogContentProps: { showCloseButton: false },
          })
        )
        yield put(actions.verifyAppBlocker.complete(blockerData))
      }
    }
  }),
}
