import React from 'react'
import FullScreen from 'APP/ui-kit/Fullscreen'
import { useMessage } from 'APP/language'
import * as S from './styled'
import Forms from './Forms'
import Languagebar from './LanguagesBar'
import Logo from 'APP/static/logo-310x310.png'
import Flower from 'APP/static/empty-storylist-flower.png'

const WelcomeScreen = ({ login, register, forgotPassword }) => {
  const [APP_HERO_TITLE, APP_HERO_SUB_TITLE] = useMessage('APP_HERO_TITLE', 'APP_HERO_SUB_TITLE')
  return (
    <FullScreen>
      <S.Container>
        <S.Top>
          <S.Logo src={Logo} alt="Logo" />
          <S.LogoTitle>VEEN READER</S.LogoTitle>
        </S.Top>
        <S.Middle>
          <S.TitleContainer>
            <S.Title>{APP_HERO_TITLE}</S.Title>
            <S.SubTitle>{APP_HERO_SUB_TITLE}</S.SubTitle>
          </S.TitleContainer>
          <S.FormsContainer>
            <Forms login={login} register={register} forgotPassword={forgotPassword} />
          </S.FormsContainer>
        </S.Middle>
        <S.Bottom>
          <Languagebar />
          <div style={{ marginLeft: 'auto' }}>{/*Help*/}</div>
        </S.Bottom>
      </S.Container>
      <S.Flower src={Flower} />
    </FullScreen>
  )
}

export default WelcomeScreen
