import React from 'react'
import * as S from './styled'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { InputField } from './Component'
import { useMessage } from 'APP/language'

const initialValues = {
  otp: '',
  email: '',
  newPassword: '',
}
const schema = yup.object().shape({
  email: yup.string().required('VALIDATION_EMAIL_REQUIRED').email('VALIDATION_EMAIL_FORMAT'),
})

const config = {
  initialValues,
  validationSchema: schema,
}

const Form = ({ forgotPassword, updateFormMessage }) => {
  const [
    ENTER_YOUR_EMAIl_ADDRESS,
    SUBMIT,
    REQUESTING_OTP,
    ENTER_OTP,
    ENTER_NEW_PASSWORD,
    SUBMITTING_OTP,
    CHANGING_PASSWORD,
    REQUESTING_OTP_ERROR,
    REQUESTING_OTP_SUCCESS,
    SUBMITTING_OTP_ERROR,
    SUBMITTING_OTP_SUCCESS,
    INVALID_OTP,
    CHANGING_PASSWORD_ERROR,
    CHANGING_PASSWORD_SUCCESS,
  ] = useMessage(
    'ENTER_YOUR_EMAIl_ADDRESS',
    'SUBMIT',
    'REQUESTING_OTP',
    'ENTER_OTP',
    'ENTER_NEW_PASSWORD',
    'SUBMITTING_OTP',
    'CHANGING_PASSWORD',
    'REQUESTING_OTP_ERROR',
    'REQUESTING_OTP_SUCCESS',
    'SUBMITTING_OTP_ERROR',
    'SUBMITTING_OTP_SUCCESS',
    'INVALID_OTP',
    'CHANGING_PASSWORD_ERROR',
    'CHANGING_PASSWORD_SUCCESS'
  )
  const [step, setStep] = React.useState(1)
  const [token, setToken] = React.useState(null)

  const { handleSubmit, handleChange, values, errors, isSubmitting, setSubmitting, setErrors } = useFormik({
    ...config,
    onSubmit: (values) => {
      const RequestMessages = {
        1: REQUESTING_OTP,
        2: SUBMITTING_OTP,
        3: CHANGING_PASSWORD,
      }
      const ErrorMessages = {
        1: REQUESTING_OTP_ERROR,
        2: SUBMITTING_OTP_ERROR,
        3: CHANGING_PASSWORD_ERROR,
      }
      const SuccessMessages = {
        1: REQUESTING_OTP_SUCCESS,
        2: SUBMITTING_OTP_SUCCESS,
        3: CHANGING_PASSWORD_SUCCESS,
      }
      updateFormMessage(RequestMessages[step])
      setSubmitting(true)
      forgotPassword({
        data: { ...values, step: 'step' + step },
        token,
        callback: (success, message, token) => {
          setSubmitting(false)
          if (success && token) {
            setToken(token)
            setStep(step + 1)
            updateFormMessage(SuccessMessages[step])
          } else {
            if (message === 'INVALID_OTP') {
              updateFormMessage(INVALID_OTP)
            } else {
              updateFormMessage(ErrorMessages[step])
            }
          }
        },
      })
    },
  })
  return (
    <S.HtmlFormContainer
      onSubmit={(e) => {
        e.preventDefault()
        handleSubmit()
      }}>
      {step === 1 && (
        <InputField
          autoComplete="off"
          type="text"
          tabIndex={1}
          disabled={isSubmitting || step > 1}
          error={errors['email']}
          placeholder={ENTER_YOUR_EMAIl_ADDRESS}
          name="email"
          value={values.email}
          onChange={handleChange}
        />
      )}
      {step === 2 && (
        <InputField
          autoComplete="off"
          type="text"
          tabIndex={2}
          disabled={isSubmitting || step > 2}
          error={errors['otp']}
          placeholder={ENTER_OTP}
          name="otp"
          value={values.otp}
          onChange={handleChange}
        />
      )}
      {step === 3 && (
        <InputField
          autoComplete="off"
          type="text"
          tabIndex={3}
          disabled={isSubmitting || step > 3}
          error={errors['newPassword']}
          placeholder={ENTER_NEW_PASSWORD}
          name="newPassword"
          value={values.newPassword}
          onChange={handleChange}
        />
      )}
      {step < 4 && (
        <S.Button tabIndex={4} disabled={isSubmitting} type="submit">
          {SUBMIT}
        </S.Button>
      )}
    </S.HtmlFormContainer>
  )
}

export default Form
