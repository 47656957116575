import React from 'react'
import { useDimensions } from 'APP/utils/uiHooks'
import ProgressIndicator from 'APP/ui-kit/ProgressIndicator'
import styled from 'styled-components'
import { get } from 'APP/utils/lodash'
import { useSelector } from 'react-redux'

const Component = () => {
  const { SIDEBAR_WIDTH } = useDimensions()
  const visible = useSelector((s) => get(s, 'messageBar.progressIndicatorVisible'))
  return (
    <React.Fragment>
      {visible && (
        <ProgressIndicatorWrapper left={SIDEBAR_WIDTH}>
          <ProgressIndicator />
        </ProgressIndicatorWrapper>
      )}
    </React.Fragment>
  )
}
const ProgressIndicatorWrapper = styled.div`
  position: absolute;
  top: 0;
  width: 100vw;
  left: ${(p) => p.left}px;
`

export default Component
