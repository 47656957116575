import { connect } from 'react-redux'
import Pure from './Pure'
import { getMany, getOnboardingRecommendations, getApiStatus, getOnboardingRecommendationsActive, getIntegrationUser, getSubscriptions } from 'APP/utils/reduxSelectors'
import { actions } from './model'
import { API_OPERATIONS } from 'APP/services/serviceEffext'
import { actions as SubscriptionListActions } from 'APP/components/SubscriptionList/model'
import { actions as UserActions } from 'APP/components/Models/user'
import { actions as AppActions } from 'APP/components/Models/app'

const mapStateToProps = (state) => {
  const [results, active, integrationUser, subscriptions] = getMany(state, getOnboardingRecommendations, getOnboardingRecommendationsActive, getIntegrationUser, getSubscriptions)
  const apiStatus = getApiStatus(state, API_OPERATIONS.GET_ONBOARDING_RECOMMENDATIONS)
  return {
    results: results,
    apiStatus: apiStatus,
    integrationUser,
    active,
    subscriptions,
  }
}

export default connect(mapStateToProps, {
  fetchRecommendations: actions.fetchRecommendations.request,
  markAsOnBoarded: UserActions.markAsOnBoarded.request,
  addSubscription: SubscriptionListActions.update.request,
  removeSubscription: SubscriptionListActions.remove.request,
  startSync: AppActions.sync.request,
})(Pure)
