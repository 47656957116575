import React from 'react'
import MessageBar from 'APP/ui-kit/MessageBar'
import { useFormattedMessage } from 'APP/language'
import { useDimensions } from 'APP/utils/uiHooks'

const Component = ({ message = {} }) => {
  let messageToRender = useFormattedMessage(message.message, message.langData, message.message)
  const { SIDEBAR_WIDTH } = useDimensions()
  if (!messageToRender) return null
  return (
    <MessageBar type={message.type} left={SIDEBAR_WIDTH}>
      {messageToRender}
    </MessageBar>
  )
}

export default Component
