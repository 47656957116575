import { handleActions } from 'redux-actions'
import produce from 'immer'
import { put, takeLatest, call, select, fork } from 'redux-saga/effects'
import { handleSaga, create3Actions, createAction } from 'APP/utils/reduxUtils'
import dbService from 'APP/services/dbZervice'
import { getUser } from 'APP/utils/reduxSelectors'
import { serviceEffext, API_OPERATIONS } from 'APP/services/serviceEffext'

const MODULE_NAME = 'USER'
const createActions = create3Actions(MODULE_NAME)

/* ------------- Actions ------------- */
export const actions = {
  load: createActions('load'),
  saveRefreshedToken: createAction('saveRefreshedToken'),
  markAsOnBoarded: createActions('markAsOnBoarded'),
}

/* ------------- Initial state ------------- */
const initialState = {
  user: null,
}

/* ------------- Reducer ------------- */
export const reducer = handleActions(
  {
    [actions.load.success]: (state, { payload }) => produce(state, (draft) => ({ user: payload })),
    [actions.saveRefreshedToken]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.user.accessToken = payload
      }),
    [actions.markAsOnBoarded.request]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.user = { ...draft.user, onBoarded: true }
        return draft
      }),
  },
  initialState
)

/* ------------- Root Saga ------------- */
export const rootSaga = {
  [actions.load.request]: handleSaga(takeLatest, function* () {
    let user = yield call(dbService.getUser)
    yield put(actions.load.success(user))
  }),

  [actions.saveRefreshedToken]: handleSaga(takeLatest, function* ({ payload }) {
    const user = yield select(getUser)
    yield call(dbService.updateUser, { user, props: { accessToken: payload } })
  }),

  [actions.markAsOnBoarded.request]: handleSaga(takeLatest, function* ({ payload }) {
    const user = yield select(getUser)
    yield fork(dbService.updateUser, { user, props: { onBoarded: true } })
    yield serviceEffext(API_OPERATIONS.MARK_ONBOARDING_COMPLETE)
  }),
}
