import React from 'react'
import { find, pick, reject, isValidString } from 'APP/utils/lodash'
import { ContextualMenu as ContextualMenuBase } from 'APP/ui-kit/Vendor'
import styled from 'styled-components'
import { TextField, PrimaryButton } from 'APP/ui-kit/Vendor'
import { useMessage } from 'APP/language'

const getMenus = ({ onMenuItemClick, allCategories = [], subscriptions, subscription, categories }) => {
  if (allCategories.length < 1) {
    return [{}]
  }
  return allCategories.map((category, i) => {
    const inCategory = !!find(categories, { id: category.id })
    return {
      key: category.id,
      text: category.label,
      onClick: (e) => onMenuItemClick(e, category),
      iconProps: {
        iconName: inCategory ? 'CheckboxComposite' : 'Checkbox',
      },
    }
  })
}

const ContextualMenu = ({ hidden, subscription, target, onDismiss, folders = [], subscriptions, add, integrationUser }) => {
  const [newCategoryTitle, setNewCategoryTitle] = React.useState('')
  const [subscriptionTitle, setSubscriptionTitle] = React.useState(subscription.title)
  const [SUBSCRIBE, ENTER_A_FOLDER_NAME] = useMessage('SUBSCRIBE', 'ENTER_A_FOLDER_NAME')

  // Categories of a subscription
  const [categories, setCategories] = React.useState((find(subscriptions, { id: subscription.id }) || { categories: [] })['categories'])

  // all available categories
  const allCategories = folders.map((folder) => pick(folder, ['id', 'label']))
  const toggleCategory = (category) => {
    let cats = []
    if (find(categories, { id: category.id })) {
      cats = reject(categories, { id: category.id })
    } else {
      cats = categories.concat(category)
    }
    setCategories(cats)
  }
  return (
    <ContextualMenuBase
      onRenderMenuList={(menuListProps, defaultRender) => (
        <Container>
          <Row>
            <TextField value={subscriptionTitle} onChange={(e) => setSubscriptionTitle(e.target.value)} />
          </Row>
          <Row>
            <TextField value={newCategoryTitle} onChange={(e) => setNewCategoryTitle(e.target.value)} placeholder={ENTER_A_FOLDER_NAME} />
          </Row>
          <div>{allCategories.length > 0 && defaultRender(menuListProps)}</div>
          <Row>
            <PrimaryButton
              style={{ width: '100%', marginTop: 16 }}
              onClick={() => {
                const sub = { ...subscription }
                sub.title = isValidString(subscriptionTitle) ? subscriptionTitle : sub.title
                const catsToAdd = [...categories]
                if (isValidString(newCategoryTitle)) {
                  catsToAdd.push({
                    id: `user/${integrationUser.id}/category/${newCategoryTitle}`,
                    label: newCategoryTitle,
                  })
                }
                sub.categories = catsToAdd
                add(sub)
                onDismiss()
              }}>
              {SUBSCRIBE}
            </PrimaryButton>
          </Row>
        </Container>
      )}
      items={getMenus({
        onMenuItemClick: (e, category) => {
          e.preventDefault()
          toggleCategory(category)
        },
        allCategories,
        subscriptions,
        subscription: subscription,
        categories,
        toggleCategory,
      })}
      hidden={hidden}
      target={target}
      onItemClick={onDismiss}
      onDismiss={onDismiss}
    />
  )
}

const Container = styled.div`
  width: 280px;
  padding: 12px;
`

const Row = styled.div`
  width: 100%;
  margin-bottom: 8px;
`

export default ContextualMenu
