import styled from 'styled-components'
import BoxBase from 'APP/ui-kit/Box'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  user-select: none;
  background: ${(p) => p.theme.sidebarBgColor};
`

export const Box = styled(BoxBase)`
  cursor: pointer;
  text-transform: capitalize;
  &:hover {
    background: ${(p) => p.theme.sidebarItemHoverBgColor};
    color: ${(p) => p.theme.sidebarItemHoverTextColor};
  }
`

export const LogoContainer = styled.div`
  font-size: 14px;
  text-transform: uppercase;
  font-size: 14px;
  font-size: 16px;
  margin-bottom: -8px;
  font-weight: 800;
  color: ${(p) => p.theme.sidebarLogoContainerTextColor};
`

export const DateComp = styled.div`
  font-size: 12px;
  color: ${(p) => p.theme.sidebarDateTextColor};
`
