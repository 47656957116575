import { ContextualMenuItemType } from 'APP/ui-kit/Vendor'

export const MENU_KEYS = {
  markAsRead: 'MARK_AS_READ',
  sync: 'SYNC',
  rename: 'RENAME',
  remove: 'REMOVE',
  summary: 'SUMMARY',
  web: 'WEB',
  readable: 'READABLE',
}

export const getSubscriptionMenus = (onMenuItemClick, messages) => {
  const menuItems = [
    {
      key: MENU_KEYS.markAsRead,
      text: messages['MARK_AS_READ'],
      onClick: onMenuItemClick,
      iconProps: {
        iconName: 'CheckMark',
      },
    },
    {
      key: MENU_KEYS.sync,
      text: messages['SYNC'],
      onClick: onMenuItemClick,
      iconProps: {
        iconName: 'Sync',
      },
    },
    {
      key: MENU_KEYS.rename,
      text: messages['RENAME'],
      onClick: onMenuItemClick,
      iconProps: {
        iconName: 'Edit',
      },
    },
    {
      key: MENU_KEYS.remove,
      text: messages['REMOVE'],
      onClick: onMenuItemClick,
      iconProps: {
        iconName: 'Delete',
      },
    },
    {
      key: 'divider_1',
      itemType: ContextualMenuItemType.Divider,
    },
    {
      key: 'section2',
      itemType: ContextualMenuItemType.Section,
      sectionProps: {
        title: 'Default view',
        items: [
          {
            key: MENU_KEYS.summary,
            text: messages['SUMMARY'],
            onClick: onMenuItemClick,
            iconProps: {
              iconName: 'Completed',
            },
          },
          {
            key: MENU_KEYS.web,
            text: messages['WEB_VIEW'],
            onClick: onMenuItemClick,
            iconProps: {
              iconName: 'CircleRing',
            },
          },
          {
            key: MENU_KEYS.readable,
            text: messages['READABLE'],
            onClick: onMenuItemClick,
            iconProps: {
              iconName: 'CircleRing',
            },
          },
        ],
      },
    },
  ]
  return menuItems
}

export const getFolderMenus = (onMenuItemClick, messages) => {
  const menuItems = [
    {
      key: MENU_KEYS.rename,
      text: messages['RENAME'],
      onClick: onMenuItemClick,
      iconProps: {
        iconName: 'Edit',
      },
    },
  ]
  return menuItems
}
