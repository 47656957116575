import { default as find } from 'lodash/find'
import { default as findIndex } from 'lodash/findIndex'
import { default as assign } from 'lodash/assign'
import { default as isString } from 'lodash/isString'
import { default as trim } from 'lodash/trim'
import { default as orderBy } from 'lodash/orderBy'
export { find, assign, findIndex, orderBy }

export { default as last } from 'lodash/last'
export { default as first } from 'lodash/first'
export { default as update } from 'lodash/update'
export { default as remove } from 'lodash/remove'
export { default as sortBy } from 'lodash/sortBy'
export { default as reject } from 'lodash/reject'
export { default as uniqBy } from 'lodash/uniqBy'
export { default as intersectionBy } from 'lodash/intersectionBy'
export { default as get } from 'lodash/get'
export { default as pick } from 'lodash/pick'
export { default as range } from 'lodash/range'
export { default as differenceBy } from 'lodash/differenceBy'
export { default as dropRight } from 'lodash/dropRight'
export { default as filter } from 'lodash/filter'
export { default as slice } from 'lodash/slice'
export { default as pickBy } from 'lodash/pickBy'
export { default as isEmpty } from 'lodash/isEmpty'

export const findAndPatch = (collection, predicate, newProps) => {
  const index = findIndex(collection, predicate)
  const newObj = { ...collection[index], ...newProps }
  index > -1 && collection.splice(index, 1, newObj)
  return collection
}

export const findAndReplace = (collection, predicate, newObj) => {
  collection = collection.concat([])
  const index = findIndex(collection, predicate)
  index > -1 && collection.splice(index, 1, newObj)
  return collection
}

// find an object in an array. if found replace it else just add to array
export const findAndReplaceOrAdd = (collection, predicate, newObj) => {
  const index = findIndex(collection, predicate)
  index < 0 ? collection.push(newObj) : collection.splice(index, 1, newObj)
  return collection
}

export const isValidString = (str) => !!(str && isString(str) && trim(str) !== '')
