import Entity from './Entity'

class ParsedArticle extends Entity {
  static entityName = 'parsed_articles'
  url
  data

  static getSchema() {
    return {
      url: 'string',
      data: 'object',
    }
  }
}

export default ParsedArticle
