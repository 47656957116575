import React from 'react'
import { Provider } from 'react-redux'
import { Fabric } from 'APP/ui-kit/Vendor'
import { store } from './store'
import { IntlProvider } from 'APP/language/index'
import { ThemeProvider } from '../themes'
import { UIStateProvider } from 'APP/utils/uiHooks'
import App from './App'
import DevUtil from 'APP/components/DevUtils'
import * as Sentry from '@sentry/react'

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true }
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <h1>Something went wrong.</h1>
    }

    return this.props.children
  }
}

function WrappedApp() {
  return (
    <Provider store={store}>
      <IntlProvider>
        <Fabric>
          <ThemeProvider>
            {/**<ErrorBoundary> */}
            <Sentry.ErrorBoundary fallback={'An error has occurred'}>
              <UIStateProvider>
                <App />
                {/**<DevUtil /> */}
              </UIStateProvider>
              {/**</ErrorBoundary> */}
            </Sentry.ErrorBoundary>
          </ThemeProvider>
        </Fabric>
      </IntlProvider>
    </Provider>
  )
}

export default WrappedApp
