import { generatePath } from 'react-router'
import { formatStringForUrl } from 'APP/utils/common'

export const ENVS = {
  production: 'production',
  development: 'development',
}

export const APP_ENVS = {
  production: 'production',
  development: 'development',
  uat: 'uat',
}

export const PLATFORMS = {
  mac: 'mac',
  win: 'win', //used with electron
  web: 'web',
  winjs: 'winjs', //used for windows app store
}

export const STORY_ACTIVITY_NAME = {
  READ: 'READ',
  LATER: 'LATER',
  FAVOURITE: 'FAVOURITE',
}

export const MESSAGE_BAR_MESSAGE_TYPE = {
  PRIMARY: 'primary',
  WARNING: 'warning',
  SUCCESS: 'success',
  DANGER: 'danger',
}

// These  are system folders
export const FOLDER_IDS = {
  UNCATEGORISED: { name: 'Uncategorised', id: 'UNCATEGORISED' },
  TAGS: { name: 'Tags', id: 'TAGS' },
}

// UNKNOW USE, MAYBE REMOVED
// These are folder types...Later you can add more type like Tags, groups etc...
export const FOLDER_TYPES = {
  FOLDER: 'FOLDER',
  TAG: 'TAG',
}

export const SYSTEM_TAGS = {
  ALL: { id: 'ALL' },
  LATER: { id: 'LATER' },
  FAVOURITE: { id: 'FAVOURITE' },
}

export const REGISTRATION_PROVIDER = {
  LEGATO: 'legato',
}

export const INTEGRATION_CODES = {
  FEEDLY: 'feedly',
  FOUNDRY: 'foundry',
}

export const AUTH_TYPES = {
  OAUTH: 'OAUTH',
  FORM: 'FORM',
}

export const SCREEN_NAMES = {
  SPLASH: 'splash',
  WELCOME: 'welcome',
  DASHBOARD: 'dashboard',
}

export const WEB_BROWSERS = {
  browser: 'browser',
  mobBrowser: 'mobBrowser',
}

export const API_STATUS = {
  PROGRESS: 'PROGRESS',
  SUCCESS: 'SUCCESS',
  FAIL: 'FAIL',
}

export const CUSTOM_ERRORS = {
  API_ERROR: 'API_ERROR',
}

export const STORY_LIST_STATUS = {
  NOT_READY: 'NOT_READY',
  LAZY_LOAD_FROM_DB: 'LAZY_LOAD_FROM_DB',
  LAZY_LOAD_FROM_SERVER: 'LAZY_LOAD_FROM_SERVER',
  IDLE: 'IDLE',
}

// These are the types of items a user can select in subscription list
export const SUBSCRIPTION_LIST_SELECTED_ITEM_TYPE = {
  TAG: 'TAG',
  FOLDER: 'FOLDER',
  SUBSCRIPTION: 'SUBSCRIPTION',
  UNKNOWN: 'UNKNOWN',
}

export const CACHE_KEYS = {
  SYNC_TIMES: 'SYNC_TIMES',
  APP_BLOCKER: 'APP_BLOCKER',
  PRODUCE_COUNTS_KEY_FN: (integrationUser) => `count-${integrationUser._id}`,
  HAS_SYNCED_ONCE: 'HAS_SYNCED_ONCE',
}

const CATCH_ALL = '*'

const ONBOARDING_LOGIN = '/welcome/login'
const ONBOARDING_REGISTER = '/welcome/register'
const ONBOARDING_FORGOT_PASSWORD = '/welcome/forgot-password'

const STORY_PATH = '/story/:story_id'
const DASHBOARD = '/dashboard'
const DASHBOARD_USER = '/u/:integration_user_index/dashboard'
const DASHBOARD_USER_FOLDER = DASHBOARD_USER + '/folders/:folder_label'
const DASHBOARD_USER_FOLDER_SUBSCRIPTIONS = DASHBOARD_USER_FOLDER + '/subscriptions/:subscription_title'
const DASHBOARD_USER_TAGS = DASHBOARD_USER + '/tags/:tag_name'
const DASHBOARD_USER_FOLDER_STORY = DASHBOARD_USER_FOLDER + STORY_PATH
const DASHBOARD_USER_SUBSCRIPTIONS_STORY = DASHBOARD_USER_FOLDER_SUBSCRIPTIONS + STORY_PATH
const DASHBOARD_STORY = DASHBOARD + STORY_PATH
const DASHBOARD_USER_STORY = DASHBOARD_USER + STORY_PATH
const DASHBOARD_SUBSCRIPTION_SYSTEM = DASHBOARD_USER + '/subscriptions'
const DASHBOARD_SETTINGS = DASHBOARD_USER + '/settings'
const DASHBOARD_SYSTEM_TAG = DASHBOARD_USER + '/s/:tag_name'
const DASHBOARD_USER_TAG = DASHBOARD_USER + '/tags/:tag_name'
const DASHBOARD_SYSTEM_TAG_STORY = DASHBOARD_SYSTEM_TAG + STORY_PATH
const DASHBOARD_USER_TAG_STORY = DASHBOARD_USER_TAG + STORY_PATH

// Public URLS
const VERIFY_EMAIL = '/public/verify-email'

export const ROUTES = {
  CATCH_ALL,
  ONBOARDING_LOGIN,
  ONBOARDING_REGISTER,
  DASHBOARD_SETTINGS,
  DASHBOARD_SUBSCRIPTION_SYSTEM,
  DASHBOARD,
  DASHBOARD_USER,
  DASHBOARD_USER_FOLDER,
  DASHBOARD_USER_FOLDER_SUBSCRIPTIONS,
  DASHBOARD_STORY,
  DASHBOARD_USER_STORY,
  DASHBOARD_USER_FOLDER_STORY,
  DASHBOARD_USER_SUBSCRIPTIONS_STORY,
  DASHBOARD_USER_TAGS,
  DASHBOARD_SYSTEM_TAG,
  DASHBOARD_USER_TAG,
  DASHBOARD_SYSTEM_TAG_STORY,
  DASHBOARD_USER_TAG_STORY,
  ONBOARDING_FORGOT_PASSWORD,
  VERIFY_EMAIL,
  generateDashboardHomePath: (index) => generatePath(ROUTES.DASHBOARD_USER, { integration_user_index: index }),
  generateDashboardSubscriptionSystemPath: (index) => generatePath(ROUTES.DASHBOARD_SUBSCRIPTION_SYSTEM, { integration_user_index: index }),
  generateDashboardSettingsPath: (index) => generatePath(ROUTES.DASHBOARD_SETTINGS, { integration_user_index: index }),
  generatePathForLeftMenuClick: ({ index, folder, subscription }) => {
    let data = { integration_user_index: index }
    let path = null

    // Folder is clicked
    if (!subscription && folder && folder.id !== FOLDER_IDS.TAGS.id) {
      // Clicked folder is Tag. As of now it will be system folder like All, Bookmarks
      if (folder.isTag) {
        path = DASHBOARD_SYSTEM_TAG
        data = { ...data, tag_name: formatStringForUrl(folder.labelKey) }
      } else {
        path = DASHBOARD_USER_FOLDER
        data = { ...data, folder_label: formatStringForUrl(folder.label) }
      }
    }

    if (subscription) {
      if (subscription.isTag) {
        path = DASHBOARD_USER_TAG
        data = { ...data, tag_name: formatStringForUrl(subscription.title) }
      } else {
        path = DASHBOARD_USER_FOLDER_SUBSCRIPTIONS
        data = { ...data, folder_label: formatStringForUrl(folder.label), subscription_title: formatStringForUrl(subscription.title) }
      }
    }
    if (path) {
      return generatePath(path, data)
    }

    return undefined
  },
}
