import config from './base'

// production config
const productionConfig = {
  RELAY_SERVER_URL: 'https://api.veenreader.com/api/v1/utils/relay',
  LEGATO_SERVER_URL: 'https://api.veenreader.com',
}

let finalConfig = { ...config, ...productionConfig }

export default finalConfig
