const querystring = require('querystring')

// type oAuthOptions = {
//   clientId: string,
//   clientSecret: string,
//   accessTokenUri: string,
//   authorizationUri: string,
//   redirectUri: string,
//   scopes: string,
//   state: string,
// }

class oAuthClient {
  clientId
  clientSecret
  accessTokenUri
  authorizationUri
  redirectUri
  scopes
  state

  constructor(options) {
    this.clientId = options.clientId
    this.accessTokenUri = options.accessTokenUri
    this.authorizationUri = options.authorizationUri
    this.redirectUri = options.redirectUri
    this.scopes = options.scopes
    this.state = options.state
  }

  _createUri(tokenType) {
    function sanitizeScope(scopes) {
      return Array.isArray(scopes) ? scopes.join(' ') : toString(scopes)
    }
    let options = this
    const query = querystring.stringify({ client_id: options.clientId, redirect_uri: options.redirectUri, scope: sanitizeScope(options.scopes), response_type: tokenType, state: options.state })
    return `${options.authorizationUri}?${query}`
  }

  getAuthorizationUri = () => {
    return this._createUri('code')
  }
}

export default oAuthClient
