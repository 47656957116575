import React from 'react'
import { wrapSubscriptionTitleName } from 'APP/utils/common'
import { useMessages, useMessage } from 'APP/language'
import { TextField, ContextualMenu, Dialog, DialogType, DialogFooter, PrimaryButton, DefaultButton } from 'APP/ui-kit/Vendor'
import { FontIcon } from 'APP/ui-kit/Vendor'
import { getSubscriptionMenus, getFolderMenus, MENU_KEYS } from './menus'
import * as S from './styled'
import { useUIState } from 'APP/utils/uiHooks'
import { ROUTES } from 'APP/utils/enums'
import { useHistory } from 'react-router-dom'

function RenameModal({ hidden, hideModal, text, onSave, title }) {
  const [value, setValue] = React.useState(text)
  const [saveLabel, cancelLabel] = useMessage('SAVE', 'CANCEL')
  return (
    <Dialog
      hidden={hidden}
      onDismiss={hideModal}
      dialogContentProps={{
        type: DialogType.normal,
        title: title,
      }}
      modalProps={{
        isBlocking: true,
        styles: { main: { maxWidth: 450 } },
      }}>
      <form
        onSubmit={(e) => {
          e.preventDefault()
          onSave(value)
        }}>
        <TextField label={text} value={value} onChange={(e) => setValue(e.target.value)} />
      </form>
      <DialogFooter>
        <PrimaryButton
          onClick={() => {
            onSave(value)
          }}
          text={saveLabel}
        />
        <DefaultButton onClick={hideModal} text={cancelLabel} />
      </DialogFooter>
    </Dialog>
  )
}

function ConfirmModal({ hidden, onCancel, onConfirm, data }) {
  const [areYouSureLabel, confirmLabel, cancelLabel] = useMessage('CONFIRM_DELETE_SUBSCRIPTION', 'CONFIRM', 'CANCEL')
  const { subscription } = data
  return (
    <Dialog
      hidden={hidden}
      onDismiss={onCancel}
      dialogContentProps={{
        type: DialogType.normal,
        title: confirmLabel,
        subText: areYouSureLabel,
      }}
      modalProps={{
        isBlocking: true,
        styles: { main: { maxWidth: 450 } },
      }}>
      <div>{subscription.title}</div>
      <DialogFooter>
        <PrimaryButton onClick={onConfirm} text={confirmLabel} />
        <DefaultButton onClick={onCancel} text={cancelLabel} />
      </DialogFooter>
    </Dialog>
  )
}

const Subscription = ({ contextMenuEventData, subscription, selectSubscription, folder, showContextualMenu }) => {
  let isContextMenuActive = false
  if (contextMenuEventData && !contextMenuEventData.isFolder) {
    const { subscription: eventSubscription, folder: eventFolder } = contextMenuEventData
    isContextMenuActive = eventSubscription.id === subscription.id && eventFolder.id === folder.id
  }
  return (
    <React.Fragment>
      <S.SubscriptionContainer
        selected={subscription.selected}
        isContextMenuActive={isContextMenuActive}
        onClick={() => selectSubscription({ folder, subscription })}
        onContextMenu={(e) => {
          e.preventDefault()
          subscription.customizable && showContextualMenu({ target: e.target, subscription, folder })
        }}>
        <S.SubscriptionIcon icon={subscription.iconUrl}>{subscription.hasFontIcon && <FontIcon iconName={subscription.iconUrl} />}</S.SubscriptionIcon>
        <S.SubscriptionLabel>{wrapSubscriptionTitleName(subscription.title)}</S.SubscriptionLabel>
        <S.SubscriptionUnreadNumber>{subscription.unreadCount}</S.SubscriptionUnreadNumber>
      </S.SubscriptionContainer>
    </React.Fragment>
  )
}

const FolderLabelWithIcon = ({ iconName, children, ...rest }) => {
  return (
    <S.FolderLabelWithIconWrapper {...rest}>
      <div>
        <FontIcon iconName={iconName} />
      </div>
      <div>{children}</div>
    </S.FolderLabelWithIconWrapper>
  )
}

const Folder = ({ folder, selectSubscription, selectFolder, showContextualMenu, contextMenuEventData, messages }) => {
  let isContextMenuActive = false
  if (contextMenuEventData && contextMenuEventData.isFolder) {
    const { folder: eventFolder } = contextMenuEventData
    isContextMenuActive = eventFolder.id === folder.id
  }
  const FolderLabelToRender = !!folder.iconName ? FolderLabelWithIcon : S.FolderLabel
  return (
    <S.FolderContainer bottomMargin={folder.subscriptions.length > 0 || folder.bottomMargin}>
      <FolderLabelToRender
        iconName={folder.iconName}
        selected={folder.selected}
        isContextMenuActive={isContextMenuActive}
        onClick={() => {
          selectFolder(folder)
        }}
        onContextMenu={(e) => {
          e.preventDefault()
          folder.customizable && showContextualMenu({ target: e.target, folder, isFolder: true })
        }}>
        {folder.labelKey ? messages[folder.labelKey] : folder.label}
      </FolderLabelToRender>
      {folder.subscriptions.map((s) => (
        <Subscription contextMenuEventData={contextMenuEventData} showContextualMenu={showContextualMenu} subscription={s} key={s.id} selectSubscription={selectSubscription} folder={folder} />
      ))}
    </S.FolderContainer>
  )
}

const FolderList = ({ folders, load, syncSubscription, removeSubscription, rename, loadCounts, markSubscriptionAsRead }) => {
  const messages = useMessages()
  const [contextMenuEventData, setContextEventClickData] = React.useState(null)
  const [modalData, setModalData] = React.useState(null)
  const [confirmModalData, setConfirmModalData] = React.useState(null)
  const integrationUserIndex = useUIState((st) => st.integrationUserIndex)
  const history = useHistory()

  React.useEffect(() => {
    load()
    loadCounts()
  }, [integrationUserIndex, load])

  const handleConextMenuClick = (e, menuItem) => {
    const { subscription, folder, isFolder } = contextMenuEventData
    menuItem.key === MENU_KEYS.sync && syncSubscription({ streamId: subscription.id, direction: 'forward', statusBar: { progress: true, success: true, failure: true } })
    menuItem.key === MENU_KEYS.remove && setConfirmModalData({ subscription, folder })
    menuItem.key === MENU_KEYS.rename && setModalData({ subscription, folder, isFolder })
    menuItem.key === MENU_KEYS.markAsRead && markSubscriptionAsRead({ subscription, folder, isFolder })
  }

  const getMenus = (...props) => {
    return (contextMenuEventData.isFolder ? getFolderMenus : getSubscriptionMenus)(...props)
  }

  const showContextualMenu = (data) => {
    setContextEventClickData(data)
  }

  const selectSubscription = ({ folder, subscription }) => {
    const path = ROUTES.generatePathForLeftMenuClick({ folder, subscription, index: integrationUserIndex })
    if (path) {
      history.push(path)
    }
  }
  const selectFolder = (folder) => {
    const path = ROUTES.generatePathForLeftMenuClick({ folder, index: integrationUserIndex })
    if (path) {
      history.push(path)
    }
  }

  const hideModal = () => setModalData(null)

  return (
    <S.FolderListContainer>
      {folders.map((f) => (
        <Folder
          contextMenuEventData={contextMenuEventData}
          showContextualMenu={showContextualMenu}
          selectFolder={selectFolder}
          folder={f}
          key={f.id}
          selectSubscription={selectSubscription}
          messages={messages}
        />
      ))}
      {contextMenuEventData && (
        <ContextualMenu
          items={getMenus(handleConextMenuClick, messages)}
          hidden={!contextMenuEventData}
          target={contextMenuEventData && contextMenuEventData.target}
          onItemClick={() => showContextualMenu(null)}
          onDismiss={() => showContextualMenu(null)}
          alignTargetEdge
        />
      )}
      {modalData && (
        <RenameModal
          hidden={!modalData}
          hideModal={hideModal}
          text={modalData.isFolder ? modalData.folder.label : modalData.subscription.title}
          onSave={(newName) => {
            rename({ ...modalData, newName })
            hideModal()
          }}
          title={messages[modalData.isFolder ? 'RENAME_FOLDER' : 'RENAME_SUBSCRIPTION']}
        />
      )}
      {!!confirmModalData && (
        <ConfirmModal
          hidden={!confirmModalData}
          onCancel={() => {
            setConfirmModalData(null)
          }}
          onConfirm={() => {
            removeSubscription(confirmModalData)
            setConfirmModalData(null)
          }}
          data={confirmModalData}
        />
      )}
    </S.FolderListContainer>
  )
}

export default FolderList
