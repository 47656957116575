import React from 'react'
import * as S from './styled'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { InputField } from './Component'
import { useMessage } from 'APP/language'

const initialValues = {
  username: '',
  password: '',
}

const schema = yup.object().shape({
  username: yup.string().required('VALIDATION_USERNAME_REQUIRED'),
  password: yup.string().required('VALIDATION_PASSWORD_REQUIRED'),
})

const config = {
  initialValues,
  validationSchema: schema,
}

const Form = ({ login, updateFormMessage }) => {
  const [USERNAME_PLACEHOLDER, PASSWORD_PLACEHOLDER, LOGIN, LOG_IN_PROGRESS_USER] = useMessage('USERNAME_PLACEHOLDER', 'PASSWORD_PLACEHOLDER', 'LOGIN', 'LOG_IN_PROGRESS_USER')

  const { handleSubmit, handleChange, values, errors, isSubmitting, setSubmitting, setErrors, touched, handleBlur } = useFormik({
    ...config,
    onSubmit: (values) => {
      setSubmitting(true)
      updateFormMessage(LOG_IN_PROGRESS_USER)
      login({
        credentials: values,
        callback: (success, message, errs) => {
          updateFormMessage(message)
          setSubmitting(false)
          if (success) {
            //do something
          } else {
            errs && setErrors(errs)
          }
        },
      })
    },
  })
  return (
    <S.HtmlFormContainer
      onSubmit={(e) => {
        e.preventDefault()
        handleSubmit()
      }}>
      <InputField
        autoComplete="off"
        type="text"
        tabIndex={1}
        disabled={isSubmitting}
        error={touched['username'] && errors['username']}
        placeholder={USERNAME_PLACEHOLDER}
        name="username"
        value={values.username}
        onChange={handleChange}
        onBlur={handleBlur}
      />

      <InputField
        autoComplete="off"
        type="password"
        tabIndex={1}
        disabled={isSubmitting}
        error={touched['password'] && errors['password']}
        placeholder={PASSWORD_PLACEHOLDER}
        name="password"
        value={values.password}
        onChange={handleChange}
        onBlur={handleBlur}
      />
      <S.Button tabIndex={4} disabled={isSubmitting} type="submit">
        {LOGIN}
      </S.Button>
    </S.HtmlFormContainer>
  )
}

export default Form
