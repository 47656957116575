import React from 'react'
import config from 'APP/utils/config'

const Component = () => {
  return (
    <div
      style={{
        color: '#848484',
        fontSize: '0.8rem',
        textTransform: 'lowercase',
        backgroundColor: 'transparent',
        textAlign: 'center',
        marginTop: 'auto',
      }}>
      v{config.appVersion} ({config.buildNumber})
    </div>
  )
}

export default Component
