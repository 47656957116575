import { createAction as createActionBase } from 'redux-actions'
import { put } from 'redux-saga/effects'
import { updateProgressMessage, updateSuccessMessage, updateErrorMessage } from 'APP/components/MessageBar/model'
import { CUSTOM_ERRORS } from 'APP/utils/enums'
import { actions as MessageBarActions } from 'APP/components/MessageBar/model'
import * as Sentry from '@sentry/react'

/**
 *
 * @param {*} messageBarAdapter (boolean || string, {})
 * True || String: indicates show generic message. If string it's used as message Id for generic message
 * Object: {
 *  messageBarStatusId: this will have lower priority then picking from payload
 *  generic:true || string,
 *  progressMessageProducer: ()=>{} || string,
 *  successMessageProducer: ()=>{} || string,
 *  failMessageProducer: ()=>{} || string
 * }
 */

// Note: whenever you pass string for generic message. It will only be used only in progress

const eventNames = {
  REQUEST: 'REQUEST',
  SUCCESS: 'SUCCESS',
  FAIL: 'FAIL',
}
let produceMessage = (messageBarAdapter, messageProducer, request, generic, genericMessage, eventName) => {
  if (!messageBarAdapter) {
    return null
  }
  if (typeof messageBarAdapter === 'string') {
    if (eventName === eventNames.REQUEST) {
      return { message: messageBarAdapter }
    }
    return { message: genericMessage }
  }
  if (typeof messageBarAdapter === 'boolean') {
    return { message: genericMessage }
  }

  let message
  if (messageProducer) {
    message = typeof messageProducer === 'function' ? messageProducer(request) : messageProducer
  }

  if (!messageProducer && generic) {
    message = genericMessage
  }

  if (messageBarAdapter[eventName]) {
    message = messageBarAdapter[eventName]
  }

  if (message) {
    if (typeof message === 'string') {
      return { message }
    } else {
      return message
    }
  } else {
    return null
  }
}

function commonSagaHandler(handler, messageBarAdapter) {
  return function* (request) {
    const { type, payload = {} } = request || { type: 'DIRECT_SAGA_REQUEST' }
    const { messageBarStatusId: messageBarStatusIdFromPayload, callback } = payload
    const statusId = messageBarStatusIdFromPayload || (messageBarAdapter && messageBarAdapter.messageBarStatusId) || `${Date.now()}- ${type}`
    const { generic, progressMessageProducer, successMessageProducer, failMessageProducer } = messageBarAdapter || {}

    if (messageBarAdapter) {
      const progressMessage = produceMessage(messageBarAdapter, progressMessageProducer, request, generic, 'LOADING', eventNames.REQUEST)
      if (progressMessage) {
        yield updateProgressMessage(progressMessage.message, statusId, undefined, progressMessage.langData)
      }
      if (messageBarAdapter.showProgressIndicator) {
        yield put(MessageBarActions.showProgressIndicator())
      }
    }
    const expiresIn = messageBarStatusIdFromPayload ? undefined : 3
    try {
      yield handler(request)
      if (messageBarAdapter) {
        const successMessage = produceMessage(messageBarAdapter, successMessageProducer, request, generic, 'SUCCESS', eventNames.SUCCESS)
        if (successMessage) {
          yield updateSuccessMessage(successMessage.message, statusId, expiresIn, successMessage.langData)
        }
      }
    } catch (error) {
      console.log('Failed at saga', error, request)
      Sentry.captureException(error)
      // Update status
      if (messageBarAdapter) {
        const failMessage = error.message ? { message: error.message } : produceMessage(messageBarAdapter, failMessageProducer, request, generic, 'FAIL', eventNames.FAIL)
        if (failMessage) {
          yield updateErrorMessage(failMessage.message, statusId, expiresIn, failMessage.langData)
        }
      }
      // Call the callback if it's defined.Mostly used in forms
      let errors = {}
      // For API errors we know that .date will be there
      if (error.name === CUSTOM_ERRORS.API_ERROR) {
        errors = error.data.errors
      }
      callback && callback(false, error.message, errors)
    } finally {
      if (messageBarAdapter && messageBarAdapter.showProgressIndicator) {
        yield put(MessageBarActions.hideProgressIndicator())
      }
    }
  }
}

/**
 * @param {*} effect: [effectName] like takeLatest etc from redux-saga
 * @param {} handler: Saga to handle request
 */
export const handleSaga = (effect, handler, messageBarAdapter) => {
  return [effect, commonSagaHandler(handler, messageBarAdapter)]
}

export const mergeSagas = (sagas) => {
  let s = []
  for (let key in sagas) {
    let [effect, handler] = sagas[key]
    s.push(function* () {
      yield effect(key, handler)
    })
  }
  return s
}

// runs saga
export const runSaga = (model, action) => {
  // returns a saga, call it like below
  // yield runSaga(Feeds,Feeds.actions.fetchFeedsRequest)({payload:1500});
  return model.rootSaga[action][1]
}

export const createAction = (...props) => createActionBase((props || []).join(':'))

export const create3Actions = (moduleName) => (actionName) => ({
  request: createAction(moduleName, actionName, 'request'),
  failure: createAction(moduleName, actionName, 'failure'),
  success: createAction(moduleName, actionName, 'success'),
  complete: createAction(moduleName, actionName, 'complete'),
})

/**
adapter

bool => it will display generic message
string: it will use this key progress, for other events it will use generic

progressMessageProducer, successMessageProducer, failMessageProducer
they can be functions or just plain objects

funtion syntax
progressMessageProducer: ({ payload: { subscription } }) => ({ message: 'SYNC_SUBSCRIPTION', langData: [subscription.title] }),


you can also return plain object like
{ message: 'SYNC_SUBSCRIPTION', langData: [subscription.title] }



{
  REQUEST: 'REQUEST_message_key',
  SUCCESS: 'SUCCESS_message_key',
  FAIL: 'FAIL_key',
}

{
  REQUEST: 'REQUEST_message_key',
  SUCCESS: 'SUCCESS_message_key',
  FAIL: {message:'FAIL_key', langData:data},
}

 */
