import * as Db from '../db'
import { v4 as uuidv4 } from 'uuid'

const DEVICE_ID_KEY = '___d1'
class Base {
  static config
  static Db = Db

  static configure(config) {
    Base.config = config
  }
  static getConfig() {
    return Base.config
  }
  static windowMoveToPath(path) {
    window.location.replace(path)
  }

  static getDeviceId = () => {
    let id = localStorage.getItem(DEVICE_ID_KEY)
    if (!id) {
      id = uuidv4()
      localStorage.setItem(DEVICE_ID_KEY, id)
    }
    return id
  }
  static copyToClipBoard(text) {
    var dummy = document.createElement('textarea')
    document.body.appendChild(dummy)
    dummy.value = text
    dummy.select()
    document.execCommand('copy')
    document.body.removeChild(dummy)
  }

  static getDimensions() {
    return {
      width: window.innerWidth,
      height: window.innerHeight,
    }
  }

  // Below must be implemented in Child
  static getName() {
    throw Error('Implement in child')
  }
  static oAuthFlow
}

export default Base
