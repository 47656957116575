import Pure from './pure'
import { connect } from 'react-redux'

const mapStateToProps = (state) => {
  return {
    enabled: state.uiBlocked.visbile,
    zIndex: state.uiBlocked.zIndex,
  }
}

export default connect(mapStateToProps)(Pure)
