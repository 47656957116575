export class CustomError extends Error {
  constructor(...params) {
    super(...params)
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, CustomError)
    }
    const [name, message, response] = params
    this.data = response
    this.name = name
    this.message = message
  }
}
