import { connect } from 'react-redux'
import Pure from './Pure'

const mapStateToProps = (state) => {
  return {
    message: state.messageBar.statusMessages[0],
  }
}

export default connect(mapStateToProps, {})(Pure)
