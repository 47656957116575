import { last, slice } from 'APP/utils/lodash'
var LinvoDB = require('linvodb3')

// Singleton for all docs
const docs = {}

export const configure = async ({ dbPath, entities }) => {
  // try {
  //   // try to get linvoDB if not found then it will use indeedDB
  //   const electron = window.require('electron')
  //   const { getDB } = electron.remote.require('../electron/start.js')
  //   LinvoDB = getDB({ dbPath })
  // } catch (error) {
  //   LinvoDB.defaults.store = { db: require('level-js') }
  // }
  LinvoDB.defaults.store = { db: require('level-js') }
  const supportsExec = ['find', 'findOne']
  const dbMethodsToBind = ['find', 'findOne', 'insert', 'remove', 'save', 'update', 'count']
  for (let entity of entities) {
    const entityName = entity.getEntityName()
    const doc = new LinvoDB(entityName, entity.getSchema(), {})
    for (let method of dbMethodsToBind) {
      //Bind is done to run the method in doc's context
      const isMethodSupportsExec = supportsExec.indexOf(method) > -1
      doc[method] = promisify(doc[method].bind(doc), isMethodSupportsExec)
    }
    docs[entityName] = doc
  }
}

const promisify = (fn, supportsExec) => {
  return (...props) => {
    return new Promise((resolve, reject) => {
      if (!supportsExec) {
        fn(...props, (err, result) => {
          err && reject(err)
          !err && resolve(result)
        })
      } else {
        const lastProp = last(props)
        const isLastItemFn = typeof lastProp === 'function'
        let propsForQuery = isLastItemFn ? slice(props, 0, props.length - 1) : props
        let query = fn(...propsForQuery)
        if (isLastItemFn) {
          query = lastProp(query)
        }
        query.exec((err, result) => {
          err && reject(err)
          !err && resolve(result)
        })
      }
    })
  }
}

export class Entity {
  static getEntityName() {
    if (!this.entityName) {
      throw Error('Please set Entity Name')
    }
    return this.entityName
  }

  static getSchema() {
    return {}
  }

  static getDoc() {
    const entityName = this.getEntityName()
    return docs[entityName]
  }

  ///BASE DB METHODS
  static async find(query, ...rest) {
    return await this.getDoc().find(query, ...rest)
  }
  static async findOne(query, ...rest) {
    return await this.getDoc().findOne(query, ...rest)
  }
  static async insert(doc, ...rest) {
    return await this.getDoc().insert(doc, ...rest)
  }
  static async save(doc, ...rest) {
    return await this.getDoc().save(doc, ...rest)
  }
  static async update(query, ...rest) {
    return await this.getDoc().update(query, ...rest)
  }
  static async count(...props) {
    return await this.getDoc().count(...props)
  }

  static toJSON(row, ignoreFalsy = true) {
    if (row) {
      const schema = this.getSchema()
      const keys = Object.keys(schema)
      return keys.reduce(
        (acc, current) => {
          ignoreFalsy && row[current] && (acc[current] = row[current])
          !ignoreFalsy && (acc[current] = row[current])
          return acc
        },
        { _id: row._id }
      )
    }
    return {}
  }

  static async patch(query, props) {
    props._id && delete props._id
    return await this.getDoc().update(query, { $set: props }, { multi: true })
  }

  static async remove(query, multi) {
    return await this.getDoc().remove(query, { multi: multi })
  }

  //Custom methods
  static async truncate() {
    return await this.getDoc().remove({}, { multi: true })
  }
}
