import Entity from './Entity'

class Tags extends Entity {
  static entityName = 'integration_user_tags'

  integrationUserId
  label
  id

  static getSchema() {
    return {
      integrationUserId: 'string',
      label: 'string',
      id: 'string',
    }
  }
}

export default Tags
