import React from 'react'
import styled from 'styled-components'
import { FontIcon } from 'APP/ui-kit/Vendor'

const Component = ({ title, subtext }) => {
  return (
    <Container>
      <Image>
        <FontIcon iconName="Flower" />
      </Image>
      <Title>{title}</Title>
      <SubText>{subtext}</SubText>
    </Container>
  )
}

const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  user-select: none;
  -ms-user-select: none;
  cursor: pointer;
`
const Image = styled.div`
  font-size: 56px;
  color: ${(p) => p.theme.emptyStoryListIconColor};
`
const Title = styled.div`
  font-size: 24px;
  margin-bottom: 16px;
  color: ${(p) => p.theme.emptyStoryListTitleColor};
`
const SubText = styled.div`
  color: ${(p) => p.theme.emptyStoryListSubTitleColor};
`

export default Component
