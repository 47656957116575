import React from 'react'
import styled from 'styled-components'
import Header from './Header'
import SearchBox from './SearchBox'
import Results from './Results'
import ContextMenu from './ContextMenu'
import { useMessage } from 'APP/language'

const Component = ({ integrationUser, subscriptions, results, folders, search, add, term = '', isLoading, clear, isSubscriptionsLoaded, loadSubscriptions }) => {
  const [menuEventData, setMenuEventData] = React.useState(null)
  const [SEARCHING, SEARCH_RESULT_FROM, SEARCH_PLACEHOLDER, FIND_SUBSCRIPTIONS] = useMessage('SEARCHING', 'SEARCH_RESULT_FROM', 'SUBSCRIPTION_SYSTEM_SEARCHBOX_PLACEHOLDER', 'FIND_SUBSCRIPTIONS')
  React.useEffect(() => {
    return () => clear()
  }, [clear])

  React.useEffect(() => {
    !isSubscriptionsLoaded && loadSubscriptions()
  }, [])

  const resultsTitle = isLoading ? SEARCHING(term) : results.length > 0 ? SEARCH_RESULT_FROM(term) : null
  const placeholder = SEARCH_PLACEHOLDER
  return (
    <Container>
      <Header>{FIND_SUBSCRIPTIONS}</Header>
      <SearchBox placeholder={placeholder} onSearch={search} />
      {term && <Results title={resultsTitle} results={results} isLoading={isLoading} handleSubscribe={setMenuEventData} selectedSubscription={menuEventData && menuEventData.subscription} />}

      {menuEventData && (
        <ContextMenu
          folders={folders}
          subscriptions={subscriptions}
          subscription={menuEventData.subscription}
          target={menuEventData.target}
          hidden={!menuEventData}
          onDismiss={() => setMenuEventData(null)}
          add={add}
          integrationUser={integrationUser}
        />
      )}
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
  padding-bottom: 32px;
`

export default Component
