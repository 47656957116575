import React from 'react'
import styled from 'styled-components'
import { FontIcon } from 'APP/ui-kit/Vendor'
import { media } from 'APP/themes/utils'
import { Shimmer, ShimmerElementType } from 'APP/ui-kit/Vendor'
import { Col } from 'APP/ui-kit/Grid'

export const SubscriptionListContainer = styled.div`
  padding: 8px 0px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  ${media.sm`
        justify-content: space-between;
    `}
`

export const Subscription = ({ sub, checked, toggle, index, cat }) => {
  if (!sub || index > 5) {
    return null
  }
  return (
    <SubscriptionContainer image={sub.coverUrl} onClick={() => toggle(sub, cat)}>
      {!checked && <SubscriptionWrapper />}
      <SubscriptionText>{sub.title}</SubscriptionText>
      <SubscriptionLogoContainer>
        <SubscriptionLogo image={sub.visualUrl || sub.iconUrl} />
      </SubscriptionLogoContainer>
      {checked && (
        <SubscriptionChecked>
          <FontIcon iconName={'SkypeCheck'} />
        </SubscriptionChecked>
      )}
    </SubscriptionContainer>
  )
}

export const Header = ({ titleId, text }) => (
  <HeaderContainer>
    <span id={titleId}>{text}</span>
  </HeaderContainer>
)

export const Body = ({ isProgress, children }) => {
  return (
    <BodyContainer isProgress={isProgress}>
      {isProgress && <Shimmers />}
      {!isProgress && children}
    </BodyContainer>
  )
}

export const Footer = styled.div`
  padding: 0px 24px 12px;
  ${(p) =>
    p.isProgress &&
    `
          display:none;
    `}
`

export const Category = styled.div`
  ${(p) => p.theme.fonts.large};
`

export const CategoryContainer = styled.div`
  margin-bottom: 12px;
`

export const Shimmers = () => {
  const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0)
  const isMobile = vw < 768
  const shimmerHeight = isMobile ? 100 : 200
  return (
    <Col cols cacheId={'shimmer'}>
      <Col>
        <Col style={{ height: 16, flex: 1, marginBottom: 8, width: 250 }}>
          <Shimmer shimmerElements={[{ type: ShimmerElementType.line, height: 16 }]} />
        </Col>
      </Col>
      <Col rows>
        <Col style={{ flex: 1, marginRight: 8 }}>
          <Shimmer shimmerElements={[{ type: ShimmerElementType.line, height: shimmerHeight }]} />
        </Col>
        <Col style={{ flex: 1, marginRight: 8 }}>
          <Shimmer shimmerElements={[{ type: ShimmerElementType.line, height: shimmerHeight }]} />
        </Col>
        <Col style={{ flex: 1, marginRight: 8 }}>
          <Shimmer shimmerElements={[{ type: ShimmerElementType.line, height: shimmerHeight }]} />
        </Col>
      </Col>
    </Col>
  )
}

const SubscriptionContainer = styled.div`
  padding: 4px;
  margin-right: 12px;
  margin-bottom: 12px;
  width: 130px;
  height: 100px;
  overflow: hidden;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 25%;
  position: relative;
  background-image: url('${(p) => p.image}');
  ${media.sm`
  margin-right: 0px;
`}
`

const SubscriptionWrapper = styled.div`
  background: #10394c;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  height: 100px;
  opacity: 0.5;
`

const SubscriptionLogo = styled.div`
  width: 28px;
  height: 28px;
  border-radius: 2px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 25%;
  background-image: url('${(p) => p.image}');
`

const SubscriptionLogoContainer = styled.div`
  width: 32px;
  height: 32px;
  position: absolute;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 2px;
`

const SubscriptionText = styled.div`
  color: #fff;
  position: absolute;
  bottom: 0;
  padding-bottom: 4px;
`

const SubscriptionChecked = styled.div`
  background: #10394c;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  height: 100px;
  opacity: 0.8;

  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
`

const HeaderContainer = styled.div`
  ${(p) => p.theme.fonts.xLarge};
  border-top: 4px solid ${(p) => p.theme.themePrimary};
  color: ${(p) => p.theme.themePrimary};
  flex: 1 1 auto;
  display: flex;
  align-items: center;
  padding: 12px 12px 14px 24px;
`

const BodyContainer = styled.div`
  flex: 4 4 auto;
  padding: 0 24px 12px 24px;
  width: 80vw;
  min-width: 340px;
  max-width: 800px;
  height: 65vh;
  min-height: 400px;
  overflow-y: scroll;
  overflow-x: hidden;
  ${(p) =>
    p.isProgress &&
    `
        height: 250px;
        min-height: 250px;
    `}
`
