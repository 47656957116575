import { handleActions } from 'redux-actions'
import { takeEvery, put, delay } from 'redux-saga/effects'
import produce from 'immer'
import { createAction as createActionBase } from 'redux-actions'
import { reject, findAndReplaceOrAdd, sortBy } from 'APP/utils/lodash'
import { MESSAGE_BAR_MESSAGE_TYPE } from 'APP/utils/enums'

/**
 * This file should not import anything from ReduxUTILs file
 */

const MODULE_NAME = 'MESSAGE_BAR'
const createAction = (...props) => createActionBase((props || []).join(':'))

/**
 * 
 * Status object
{
  type:       [enum] MESSAGE_BAR_MESSAGE_TYPE.PRIMARY,
  id:         [string] Unique identifier for  status. Use the same id to clear or update
  message:    [string] plain text or lang key. 
  langData:   [array], extra data to be passed in case of language key
  expires_in: [number] Time in milliseconds. After this Message will be removed
}
*/

export const actions = {
  updateStatusMessage: createAction(MODULE_NAME, 'updateStatusMessage'),
  clearStatusMessage: createAction(MODULE_NAME, 'clearStatusMessage'),
  removeAllMessages: createAction(MODULE_NAME, 'removeAllMessages'),
  updateSplashMessage: createAction(MODULE_NAME, 'updateSplashMessage'),
  showProgressIndicator: createAction(MODULE_NAME, 'showProgressIndicator'),
  hideProgressIndicator: createAction(MODULE_NAME, 'hideProgressIndicator'),
}

const initialState = {
  statusMessages: [],
  splashMessage: 'Initializing...',
  progressIndicatorVisible: false,
}

export const reducer = handleActions(
  {
    [actions.updateStatusMessage]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.statusMessages = sortBy(findAndReplaceOrAdd(draft.statusMessages, { id: payload.id }, { ...payload, updatedAt: Date.now() }), [(s) => -1 * s.updatedAt]) || []
      }),
    [actions.clearStatusMessage]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.statusMessages = reject(draft.statusMessages, { id: payload })
      }),
    [actions.removeAllMessages]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.statusMessages = []
      }),
    [actions.updateSplashMessage]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.splashMessage = payload
      }),

    [actions.showProgressIndicator]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.progressIndicatorVisible = true
      }),

    [actions.hideProgressIndicator]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.progressIndicatorVisible = false
      }),
  },
  initialState
)

export const rootSaga = {
  [actions.updateStatusMessage]: [
    takeEvery,
    function* ({ payload }) {
      if (payload.expires_in) {
        yield delay(payload.expires_in * 1000)
        yield put(actions.clearStatusMessage(payload.id))
      }
    },
  ],
}

function* updateStatus(type, message, id, expiresIn, langData) {
  yield put(
    actions.updateStatusMessage({
      type: type,
      id: id,
      message: message,
      expires_in: expiresIn,
      langData,
    })
  )
}
export function* updateProgressMessage(message, id, expiresIn, langData) {
  yield updateStatus(MESSAGE_BAR_MESSAGE_TYPE.PRIMARY, message, id, expiresIn, langData)
}

export function* updateErrorMessage(message, id, expiresIn, langData) {
  yield updateStatus(MESSAGE_BAR_MESSAGE_TYPE.DANGER, message, id, expiresIn, langData)
}

export function* updateSuccessMessage(message, id, expiresIn, langData) {
  yield updateStatus(MESSAGE_BAR_MESSAGE_TYPE.SUCCESS, message, id, expiresIn, langData)
}
export function* hideStatusMessage(id) {
  yield put(actions.clearStatusMessage(id))
}
