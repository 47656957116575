import React from 'react'
import { FixedSizeList as List } from 'react-window'
import StoryCard from './StoryCard'
import { useMessages } from 'APP/language'
import EmptyStoryList from 'APP/ui-kit/EmptyStoryList'
import Bar from './Bar'
import SkeletonCard from 'APP/ui-kit/StoryCard/SkeletonCard'
import { range } from 'APP/utils/lodash'
import { useDimensions } from 'APP/utils/uiHooks'
import { useHistory } from 'react-router-dom'
import { ProgressIndicator } from 'APP/ui-kit/Vendor'
import styled from 'styled-components'

const CardHeight = 104
const OverScanCount = 2
const BarHeight = 0 // if <Bar is added to UI make it 40px
const LazyLoadingHeight = 8

const langKeys = {
  ALL: { title: 'EMPTY_LIST_FOLDER_TITLE', subtext: 'EMPTY_LIST_FOLDER_SUBTEXT' },
  LATER: { title: 'EMPTY_LIST_LATER_TITLE', subtext: 'EMPTY_LIST_LATER_SUBTEXT' },
  FAVOURITE: { title: 'EMPTY_LIST_FAVOURITE_TITLE', subtext: 'EMPTY_LIST_FAVOURITE_SUBTEXT' },
  FOLDER: { title: 'EMPTY_LIST_FOLDER_TITLE', subtext: 'EMPTY_LIST_FOLDER_SUBTEXT' },
  SUBSCRIPTION: { title: 'EMPTY_LIST_SUBSCRIPTION_TITLE', subtext: 'EMPTY_LIST_SUBSCRIPTION_SUBTEXT' },
  UNKNOWN: { title: 'EMPTY_LIST_UNKNOWN_TITLE', subtext: 'EMPTY_LIST_UNKNOWN_SUBTEXT' },
}

const Row = ({ index, style, data }) => {
  const { stories, selectedStoryId, subscriptionsHash } = data
  const story = stories[index]
  const subscription = subscriptionsHash[story.subscriptionId]
  return <StoryCard style={style} story={story} isSelected={story._id === selectedStoryId} subscription={subscription} />
}

const StoryList = ({ stories, loadStories, selectedSubscriptionHash, selectedStoryId, selectedStoryIndex = 0, subscriptionsHash, emptyListLabelsKey, moveStory, loadStoryMarkers, listStatus }) => {
  const messages = useMessages()
  const listRef = React.useRef(null)
  const [isHandleKeyPress, setIsHandleKeyPress] = React.useState(false)
  const { MIDDLE_WIDTH } = useDimensions()
  const history = useHistory()

  React.useEffect(() => {
    loadStories()
  }, [selectedSubscriptionHash, loadStories])

  React.useEffect(() => {
    // Todo: Should only be fecthed once when an integrationUser is swicthed
    loadStoryMarkers()
  }, [])

  React.useEffect(() => {
    try {
      listRef.current.scrollToItem(selectedStoryIndex)
    } catch (error) {}
  }, [selectedStoryIndex])

  React.useEffect(() => {
    const handler = (e) => {
      if (isHandleKeyPress && (e.keyCode === 38 || e.keyCode === 40)) {
        e.preventDefault()
        let correction = 1
        e.keyCode === 38 && (correction = -1)
        moveStory({ correction, history })
      }
    }
    window.addEventListener('keydown', handler)
    return () => window.removeEventListener('keydown', handler)
  }, [isHandleKeyPress])

  const loadStoriesInner = ({ story }) => {
    if (listStatus.isIdle) {
      console.log('Perform lazy load...Both, local & server')
      loadStories({ afterStory: story })
    }
  }

  if (listStatus.notReady || (stories.length < 1 && listStatus.isLazyLoadingFromServer)) {
    return (
      <div>
        {range(5).map((i) => (
          <SkeletonCard key={i} style={{ height: CardHeight }} />
        ))}
      </div>
    )
  }

  if (stories.length < 1) {
    let keys = langKeys[emptyListLabelsKey] || langKeys.UNKNOWN
    return <EmptyStoryList title={messages[keys.title]} subtext={messages[keys.subtext]} />
  }

  let initialScrollOffset = CardHeight * (selectedStoryIndex - 1)
  initialScrollOffset = initialScrollOffset < 0 ? 0 : initialScrollOffset
  const lastStory = stories.slice(-1)[0]
  return (
    <React.Fragment>
      <div onMouseEnter={() => setIsHandleKeyPress(true)} onMouseLeave={() => setIsHandleKeyPress(false)}>
        <List
          ref={listRef}
          key={selectedSubscriptionHash}
          height={window.innerHeight - BarHeight - (listStatus.isLazyLoadingFromServer ? LazyLoadingHeight : 0)}
          itemCount={stories.length}
          itemSize={CardHeight}
          width={MIDDLE_WIDTH}
          overscanCount={OverScanCount}
          initialScrollOffset={initialScrollOffset}
          itemData={{ stories, selectedStoryId, subscriptionsHash }}
          itemKey={(index) => stories[index].id}
          onItemsRendered={(data) => {
            const { visibleStopIndex } = data
            if (visibleStopIndex + 3 > stories.length) {
              stories && stories.length > 0 && loadStoriesInner({ story: lastStory })
            }
          }}>
          {Row}
        </List>
        {listStatus.isLazyLoadingFromServer && (
          <ProgressBarWrapper>
            <ProgressIndicator barHeight={LazyLoadingHeight} />
          </ProgressBarWrapper>
        )}
        {/** <Bar /> make BarHeight =40px */}
      </div>
    </React.Fragment>
  )
}

const ProgressBarWrapper = styled.div`
  .ms-ProgressIndicator-itemProgress {
    padding: 0px;
  }
  .ms-ProgressIndicator {
    height:0px
    border:none
  }
  height:${LazyLoadingHeight}px;
  background:red;
`

export default StoryList

// const loadStoriesInner = ({ story }) => {
//   if (listStatus.isIdle) {
//     console.log('Perform lazy load...Both, local & server')
//     loadStories({ afterStory: story })
//   }
//   // // console.log('loadStoriesInner', story)
//   // if (story) {
//   //   if (stories.length >= 50) {
//   //     // @TODO: Think for some solution here
//   //     if (lazyLoadMilestonesIds.indexOf(story.id) < 0) {
//   //       lazyLoadMilestonesIds.push(story.id)
//   //       loadStories({ afterStory: story })
//   //     }
//   //   }
//   // } else {
//   //   loadStories()
//   // }
// }
