import { handleActions } from 'redux-actions'
import { takeLatest, call, put, select } from 'redux-saga/effects'
import produce from 'immer'
import db from 'APP/services/dbZervice'
import { handleSaga, create3Actions } from 'APP/utils/reduxUtils'
import { serviceEffext, API_OPERATIONS } from 'APP/services/serviceEffext'
import { THEMES } from 'APP/themes'
import { LANGUAGES } from 'APP/language'
import { getUser } from 'APP/utils/reduxSelectors'

const MODULE_NAME = 'settings'
const createActions = create3Actions(MODULE_NAME)

export const actions = {
  load: createActions('load'),
  update: createActions('update'),
  get: createActions('get'),
}

// Base settings it's empty
const initialState = {
  theme: THEMES.DEFAULT,
  language: LANGUAGES.getDefault().languageCode,
}

export const reducer = handleActions(
  {
    [actions.load.success]: (state, { payload }) =>
      produce(state, (draft) => {
        return { ...draft, ...payload }
      }),
    [actions.update.request]: (state, { payload: { props } }) =>
      produce(state, (draft) => {
        return { ...draft, ...props }
      }),
  },
  initialState
)

export const rootSaga = {
  [actions.load.request]: handleSaga(takeLatest, function* () {
    const settings = yield call(db.getSettings)
    yield put(actions.load.success(settings))
  }),
  [actions.update.request]: handleSaga(takeLatest, function* ({ payload: { props, updateServer } }) {
    const { settings } = yield select((state) => state)
    const user = yield select(getUser)

    yield call(db.updateSettings, { user, props })
    if (updateServer) {
      yield serviceEffext(API_OPERATIONS.UPDATE_SETTINGS, settings)
    }
  }),
  [actions.get.request]: handleSaga(takeLatest, function* () {
    const settings = yield serviceEffext(API_OPERATIONS.GET_SETTINGS)
    if (settings && settings.data) {
      const props = settings.data || {}
      yield put(actions.update.request({ props, updateServer: false }))
    }
  }),
}
