import Entity from './Entity'

/**
 * _id => formatted for UI
 * id => actual from server
 */

class Story extends Entity {
  static entityName = 'stories'

  title
  published
  downloadedTime
  subscriptionId
  url
  popularity
  summary
  content
  readableViewData
  published

  static getSchema() {
    return {
      title: 'string',
      published: 'number',
      downloadedTime: 'string',
      subscriptionId: 'string',
      url: 'string',
      popularity: 'string',
      readableViewData: 'string',
    }
  }
}

export default Story
