import Entity from './Entity'

class Settings extends Entity {
  static entityName = 'settings'

  userId
  deviceId
  syncOnStart = true

  static getSchema() {
    return {
      userId: 'string',
      deviceId: 'string',
      syncOnStart: 'boolean',
    }
  }
}

export default Settings
