import React from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import CardBase from 'APP/ui-kit/Card'
import OutsideAlert from 'APP/ui-kit/OutsideAlert'
import UserRow from 'APP/ui-kit/IntegrationUserCard'
import { useMessage } from 'APP/language/index'
import { useDimensions, useUIState, useNavigators } from 'APP/utils/uiHooks'
import { getIntegrationUsers, getIntegrationUser } from 'APP/utils/reduxSelectors'

const Component = () => {
  const users = useSelector(getIntegrationUsers)
  const currentUser = useSelector(getIntegrationUser)
  const sideBar = useUIState((state) => state.sideBar)
  const toggleAccountPopup = useUIState((state) => state.toggleAccountPopup)
  const [SWITCH_ACCOUNT, INTEGRATION_NAMES] = useMessage('SWITCH_ACCOUNT', 'INTEGRATION_NAMES')
  const { SIDEBAR_WIDTH } = useDimensions()

  const { goToDashboardAtIndex } = useNavigators()
  const showSplash = useUIState((state) => state.showSplash)

  const isVisible = sideBar.isAccounts
  if (!isVisible || !users || (users && users.length < 1)) {
    return null
  }
  const handleRowClick = (intUser, index) => {
    goToDashboardAtIndex(index)
    setTimeout(toggleAccountPopup, 1)
    showSplash()
  }

  return (
    <OutsideAlert onClickOutside={toggleAccountPopup} mask>
      <PopupContainer leftMargin={SIDEBAR_WIDTH}>
        <Card title={SWITCH_ACCOUNT}>
          <div style={{ maxHeight: 300, overflowY: 'auto' }}>
            {users.map((user, index) => (
              <UserRow noborder selected={currentUser._id === user._id} user={user} handleRowClick={(intUser) => handleRowClick(intUser, index)} key={user._id} INTEGRATION_NAMES={INTEGRATION_NAMES} />
            ))}
          </div>
        </Card>
      </PopupContainer>
    </OutsideAlert>
  )
}

//
const PopupContainer = styled.div`
  position: absolute;
  bottom: 50px;
  left: ${(p) => p.leftMargin + 6}px;
  width: 250px;
  max-width: 100%;
`

const Card = styled(CardBase)`
  padding: 12px;
`

export default Component
