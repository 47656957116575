import React from 'react'
import { connect } from 'react-redux'
import { FormattedMessage } from 'APP/language'
import * as S from './styled'

const SplashScreen = ({ message }) => {
  const [barWidth, setBarWidth] = React.useState(parseInt(window.splashScreenProgress, 10) || 0)

  React.useEffect(() => {
    window.splashScreenDisabled = true
    const timer = setTimeout(() => {
      let width = barWidth + 0.2
      if (width > 99) {
        width = 100
      }
      setBarWidth(width)
    }, 15)
    return () => {
      clearTimeout(timer)
    }
  }, [barWidth])

  return (
    <S.Container>
      <S.AppTitle>
        <FormattedMessage id="APP_NAME" />
      </S.AppTitle>
      <S.ProgressOuter>
        <S.ProgressInner width={barWidth} />
      </S.ProgressOuter>
      <S.Message>{message}</S.Message>
    </S.Container>
  )
}

const mapStateToProps = (state) => {
  return {
    message: state.messageBar.splashMessage,
  }
}

export default connect(mapStateToProps)(SplashScreen)
