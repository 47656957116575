import { handleActions } from 'redux-actions'
import { takeLatest, put, call, select, delay } from 'redux-saga/effects'
import produce from 'immer'
import { handleSaga, create3Actions, createAction } from 'APP/utils/reduxUtils'
import { API_OPERATIONS, serviceEffext } from 'APP/services/serviceEffext'
import { INTEGRATION_CODES, AUTH_TYPES, ROUTES } from 'APP/utils/enums'
import { Platform } from 'APP/app/resources'
import dbService from 'APP/services/dbZervice'
import oAuthClient from './oAuth'
import { updateErrorMessage } from 'APP/components/MessageBar/model'
import { actions as UserActions } from 'APP/components/Models/user'
import { getUser, isRefreskTokenInProgress } from 'APP/utils/reduxSelectors'
import { actions as UiBlockerActions } from 'APP/components/UiBlocker/model'
import { generatePath } from 'react-router'

const MODULE_NAME = 'ACCOUNTS_MANAGER'
const createActions = create3Actions(MODULE_NAME)

export const actions = {
  getIntegrations: createActions('getIntegrations'),
  startAuthFlow: createAction(MODULE_NAME, 'startAuthFlow'),
  createIntegrationUser: createActions('createIntegrationUser'),
  reset: createAction(MODULE_NAME, 'reset'),
  cancelAuthFlow: createAction('cancelAuthFlow'),
  changePassword: createActions('changePassword'),
  logout: createAction('logout'),
  refreshToken: createActions('refreshToken'),
}

const initialState = {
  integrations: null,
  activeIntegration: null,
  isRefreskTokenInProgress: false,
}

const CREATING_ACCOUNT_MESSAGE_ID = 'CREATING_ACCOUNT_MESSAGE_ID'

export const reducer = handleActions(
  {
    [actions.getIntegrations.success]: (state, { payload }) =>
      produce(state, (draft) => {
        return { ...draft, integrations: payload }
      }),
    [actions.startAuthFlow]: (state, { payload }) =>
      produce(state, (draft) => {
        return { ...draft, activeIntegration: payload }
      }),
    [actions.reset]: (state, { payload }) =>
      produce(state, (draft) => {
        return { ...draft, ...initialState }
      }),
    [actions.cancelAuthFlow]: (state, { payload }) =>
      produce(state, (draft) => {
        return { ...draft, activeIntegration: null }
      }),
    [actions.refreshToken.request]: (state, { payload }) =>
      produce(state, (draft) => {
        return { ...draft, isRefreskTokenInProgress: true }
      }),
    [actions.refreshToken.success]: (state, { payload }) =>
      produce(state, (draft) => {
        return { ...draft, isRefreskTokenInProgress: false }
      }),
  },
  initialState
)

export const rootSaga = {
  [actions.getIntegrations.request]: handleSaga(
    takeLatest,
    function* () {
      const response = yield serviceEffext(API_OPERATIONS.GET_INTEGRATIONS, null)
      const allIntegrationCodes = Object.values(INTEGRATION_CODES)
      const activeSubscriptions = (response.data || [])
        .filter((i) => i.active)
        .map((i) => {
          return { ...i, disabled: allIntegrationCodes.indexOf(i.code) < 0 }
        })
      yield put(actions.getIntegrations.success(activeSubscriptions))
    },
    true
  ),
  [actions.startAuthFlow]: handleSaga(takeLatest, function* ({ payload }) {
    if (payload.authType === AUTH_TYPES.OAUTH) {
      // try to get appUri
      let appUri = ''
      try {
        appUri = window.location.origin
      } catch (error) {}
      // create oAuth client
      const oac = new oAuthClient({ ...payload.oAuthConfig, state: appUri })
      const authorizationUri = oac.getAuthorizationUri()
      const { redirectUri } = payload.oAuthConfig
      const urlWithAccessCode = yield call(Platform.oAuthFlow, { authUrl: authorizationUri, redirectUri })
      if (urlWithAccessCode) {
        yield put(actions.createIntegrationUser.request({ integration: payload, data: urlWithAccessCode }))
      } else {
        yield updateErrorMessage('AUTH_CANCELLED_BY_USER', CREATING_ACCOUNT_MESSAGE_ID, 2)
      }
    }
  }),
  [actions.createIntegrationUser.request]: handleSaga(
    takeLatest,
    function* ({ payload }) {
      const { data, integration, callback } = payload
      console.log('Creating integration user....')
      const response = yield serviceEffext(API_OPERATIONS.CREATE_INTEGRATION_USER, {
        integrationCode: integration.code,
        payload: data,
      })
      const integrationUser = response.data
      console.log('Saving user integration user in DB', response, integrationUser)
      yield call(dbService.storeIntegrationUser, { integrationUser })
      yield put(actions.createIntegrationUser.success(integrationUser))
      callback && callback(true, response.message, response.data)
      yield put(actions.cancelAuthFlow())
    },
    {
      progressMessageProducer: 'CREATING_USER',
      successMessageProducer: 'USER_CREATED',
    }
  ),
  [actions.changePassword.request]: handleSaga(
    takeLatest,
    function* ({ payload }) {
      const { data, callback } = payload
      const response = yield serviceEffext(API_OPERATIONS.CHANGE_PASSWORD, data)
      yield put(actions.changePassword.success(true))
      callback && callback(true, response.message, response.data)
    },
    {
      generic: true,
      progressMessageProducer: 'UPDATING',
      successMessageProducer: 'CHANGE_PASSWORD_SUCCESS_MESSAGE',
    }
  ),
  [actions.logout]: handleSaga(takeLatest, function* ({ payload }) {
    yield put(UiBlockerActions.blockUI({ zIndex: 9999999 }))
    yield call(dbService.clean)
    yield serviceEffext(API_OPERATIONS.LOGOUT, {}, { isLogoutRequest: true })
    yield put(UiBlockerActions.unBlockUI())
    // Platform.windowMoveToPath('/welcome/login')
    Platform.windowMoveToPath(generatePath(ROUTES.ONBOARDING_LOGIN))
  }),
}

export function* refreshToken() {
  try {
    // when request comes. check whether any token request is in progress or not
    // If found: we assume that prevoius request will fetch the token
    // so, no need to fetch again. Just wait till that request is completed
    const inProgress = yield select(isRefreskTokenInProgress)
    if (inProgress) {
      let inProgress2 = yield select(isRefreskTokenInProgress)
      while (inProgress2) {
        yield delay(200)
        inProgress2 = yield select(isRefreskTokenInProgress)
      }
    } else {
      const user = yield select(getUser)
      const accessToken = (user.accessToken || '').split('.')[1]
      const tokenData = JSON.parse(atob(accessToken))
      const timeRemainingInSec = (new Date(tokenData.exp * 1000) - Date.now()) / 1000
      const fiveDaysInSeconds = 5 * 24 * 60 * 60
      if (timeRemainingInSec < fiveDaysInSeconds) {
        yield put(actions.refreshToken.request())
        const response = yield serviceEffext(API_OPERATIONS.REFRESH_TOKEN, {}, { doNotRefreshToken: true })
        yield put(UserActions.saveRefreshedToken(response.data))
        yield put(actions.refreshToken.success())
      }
    }
  } catch (error) {}
}
