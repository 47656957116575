import Entity from './Entity'

class Markers extends Entity {
  static entityName = 'markers'

  integrationUserId
  type
  storyIds

  static getSchema() {
    return {
      integrationUserId: 'string',
      type: 'string',
      storyIds: [String],
    }
  }
}

export default Markers
