import React from 'react'
import Card from 'APP/ui-kit/Card'
import { Grid, Row } from 'APP/ui-kit/Grid'
import Avatar from 'APP/ui-kit/Avatar'
import { connect } from 'react-redux'
import { withTheme } from 'styled-components'
import InformationRow from 'APP/ui-kit/InformationRow'
import { DefaultButton } from 'APP/ui-kit/Button'
import { useMessage } from 'APP/language'
import ChangePassword from './ChangePassword'
import Logout from './Logout'
import { actions as accountManagerActions } from './model'
import { getUser } from 'APP/utils/reduxSelectors'

const UserInfo = withTheme(({ theme, user }) => {
  return (
    <Grid>
      <Row>
        <Avatar size={60} style={{ marginTop: 10, fontSize: 30 }}>
          {user.fullName[0]}
        </Avatar>
      </Row>
      <Row middle>
        <div
          style={{
            fontSize: 18,
            margin: '8px 0px 6px',
          }}>
          {user.fullName}
        </div>
      </Row>
      <Row
        middle
        style={{
          color: theme.subduedText,
        }}>
        {user.email}
      </Row>
    </Grid>
  )
})

const Forms = {
  ChangePassword: ChangePassword,
  Logout: Logout,
}

const AccountDetails = ({ user, changePassword, logout }) => {
  const [formToRenderKey, setFormToRenderKey] = React.useState(null)
  const [PLAN_DETAILS, EMAIL_LABEL, PASSWORD_PLACEHOLDER, CHANGE, LOGOUT_PLACEHOLDER, USER_LEVEL] = useMessage(
    'PLAN_DETAILS',
    'EMAIL_LABEL',
    'PASSWORD_PLACEHOLDER',
    'CHANGE',
    'LOGOUT_PLACEHOLDER',
    'USER_LEVEL'
  )
  const FormToRender = Forms[formToRenderKey]

  const getDisplayFormHandler = (formKey) => () => setFormToRenderKey(formKey)
  const hideForm = () => setFormToRenderKey(null)

  return (
    <Card
      style={{
        margin: '16px auto',
        padding: 16,
        width: '100%',
        maxWidth: 600,
      }}>
      <UserInfo user={user} />
      <InformationRow title={PLAN_DETAILS} value={'Basic'} selector={<div></div>} />
      <InformationRow title={USER_LEVEL} value={'Beginner'} selector={<div></div>} />
      <InformationRow title={EMAIL_LABEL} value={user.email} selector={<div></div> /**<DefaultButton>{CHANGE}</DefaultButton> */} />
      <InformationRow title={PASSWORD_PLACEHOLDER} value={'********'} selector={<DefaultButton onClick={getDisplayFormHandler('ChangePassword')}>{CHANGE}</DefaultButton>} />
      <InformationRow title={''} value={LOGOUT_PLACEHOLDER} selector={<DefaultButton onClick={getDisplayFormHandler('Logout')}>{LOGOUT_PLACEHOLDER}</DefaultButton>} />
      {FormToRender && <FormToRender logout={logout} changePassword={changePassword} hideForm={hideForm} />}
    </Card>
  )
}

const mapStateToProps = (state) => {
  return {
    user: getUser(state),
  }
}

export default connect(mapStateToProps, {
  changePassword: accountManagerActions.changePassword.request,
  logout: accountManagerActions.logout,
})(AccountDetails)
