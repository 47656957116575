import React from 'react'
import { useHistory, useRouteMatch, useParams, useLocation } from 'react-router-dom'
import { generatePath } from 'react-router'
import { useSelector } from 'react-redux'
import { ROUTES, SCREEN_NAMES, FOLDER_IDS, SYSTEM_TAGS, SUBSCRIPTION_LIST_SELECTED_ITEM_TYPE } from 'APP/utils/enums'
import { get } from 'APP/utils/lodash'
import { useGetActions } from 'APP/utils/reduxHooks'
import { getUser, getSubscriptions, getTags } from 'APP/utils/reduxSelectors'
import { find } from 'APP/utils/lodash'
import { formatStringForUrl } from 'APP/utils/common'

const SIDEBAR_WIDTH = 56
const MIDDLE_WIDTH = 400

export const useDimensions = () => {
  return {
    SIDEBAR_WIDTH,
    MIDDLE_WIDTH,
  }
}

// Root UI context
var UIStateContext = React.createContext()

export const UIStateProvider = ({ children }) => {
  const [isAccountsPopupVisible, setAccountPopUpVisible] = React.useState(false)
  const [isSplash, setIsSplash] = React.useState(false)
  const showSplash = (duration = 300) => {
    setIsSplash(true)
    setTimeout(() => {
      setIsSplash(false)
    }, duration)
  }
  return <UIStateContext.Provider value={{ isAccountsPopupVisible, setAccountPopUpVisible, isSplash, showSplash }}>{children}</UIStateContext.Provider>
}

export const useUIState = (fn = () => {}) => {
  const { pathname = '' } = useLocation()
  const isSystemTag = pathname.indexOf('dashboard/s') > -1

  // const isAccounts = location.hash === ROUTES.DASHBOARD_ACCOUNTS
  const user = useSelector(getUser)
  const isDashboardReady = useSelector((state) => get(state, 'appState.isDashboardReady'))
  const tags = useSelector(getTags)
  const { integration_user_index: integrationUserIndex = '-1', folder_label: folderLabel, subscription_title: subscriptionTitle, story_id: selectedStoryId, tag_name: tagName } = useParams()
  let { isAccountsPopupVisible, setAccountPopUpVisible, isSplash, showSplash } = React.useContext(UIStateContext)
  const toggleAccountPopup = () => setAccountPopUpVisible(!isAccountsPopupVisible)

  const selectedSubscriptionId = useSelector((state) => {
    if (subscriptionTitle) {
      let subscriptions = getSubscriptions(state)
      let subscription = find(
        subscriptions.map((s) => ({ ...s, title: formatStringForUrl(s.title) })),
        { title: (subscriptionTitle || '').toLowerCase() }
      )
      return subscription && subscription.id
    }
  })

  const selectedFolderId = useSelector((state) => {
    let categories = (getSubscriptions(state) || []).reduce((cat, sub) => {
      return cat.concat(sub.categories)
    }, [])
    let category = find(
      categories.map((c) => ({ ...c, label: formatStringForUrl(c.label) })),
      { label: (folderLabel || '').toLowerCase() }
    )
    return (category && category.id) || (folderLabel && FOLDER_IDS.UNCATEGORISED.id)
  })

  const getSelectedTag = () => {
    if (tagName) {
      const keyToCheck = isSystemTag ? 'labelKey' : 'label'
      let tag = find(
        tags.filter((t) => (isSystemTag ? t.system : !t.system)).map((t) => ({ ...t, __check: formatStringForUrl(t[keyToCheck]) })),
        { __check: (tagName || '').toLowerCase() }
      )
      if (tag) {
        delete tag.__check
      }
      return tag
    }
  }
  const selectedTag = getSelectedTag()
  const selectedTagId = selectedTag && selectedTag.id

  const subscriptionsMatch = useRouteMatch(ROUTES.DASHBOARD_SUBSCRIPTION_SYSTEM)
  const settingsMatch = useRouteMatch(ROUTES.DASHBOARD_SETTINGS)
  const sideBar = {
    isSubscriptions: !isAccountsPopupVisible && get(subscriptionsMatch, 'isExact', false),
    isSettings: !isAccountsPopupVisible && get(settingsMatch, 'isExact', false),
    isAccounts: isAccountsPopupVisible,
  }
  //if all false than it's home
  sideBar.isHome = Object.keys(sideBar).reduce((a, c) => (sideBar[c] ? false : a), true)

  const subscriptionListSelectedItem = {}
  if (selectedTag) {
    subscriptionListSelectedItem.type = SUBSCRIPTION_LIST_SELECTED_ITEM_TYPE.TAG
    subscriptionListSelectedItem.value = selectedTagId
  } else if (selectedSubscriptionId) {
    subscriptionListSelectedItem.type = SUBSCRIPTION_LIST_SELECTED_ITEM_TYPE.SUBSCRIPTION
    subscriptionListSelectedItem.value = selectedSubscriptionId
  } else if (selectedFolderId) {
    subscriptionListSelectedItem.type = SUBSCRIPTION_LIST_SELECTED_ITEM_TYPE.FOLDER
    subscriptionListSelectedItem.value = selectedFolderId
  } else {
    subscriptionListSelectedItem.type = SUBSCRIPTION_LIST_SELECTED_ITEM_TYPE.UNKNOWN
    subscriptionListSelectedItem.value = null
  }

  const streamId = selectedSubscriptionId || selectedFolderId || selectedTagId || SYSTEM_TAGS.ALL.id

  const uiState = {
    sideBar: sideBar,
    integrationUserIndex: parseInt(integrationUserIndex, 10) || 0,
    toggleAccountPopup: toggleAccountPopup,
    screen: isSplash ? SCREEN_NAMES.SPLASH : !isDashboardReady ? SCREEN_NAMES.SPLASH : !!user ? SCREEN_NAMES.DASHBOARD : SCREEN_NAMES.WELCOME,
    showSplash: showSplash,
    selectedSubscriptionId,
    selectedFolderId,
    selectedStoryId,
    streamId,
    selectedTagId,
    selectedTag,
    isSystemTag,
    subscriptionListSelectedItem,
  }
  return fn(uiState)
}

export const useNavigators = () => {
  const history = useHistory()
  history.length = history.length - 1
  const goBack = () => history.goBack()
  const goToDashboardAtIndex = (index) => history.push(ROUTES.generateDashboardHomePath(index))
  const goToReplacePath = (path) => history.push(path)
  const goToLogin = (path) => history.push(generatePath(ROUTES.ONBOARDING_LOGIN))
  return { goBack, goToDashboardAtIndex, goToReplacePath, goToLogin }
}

export const LogUIState = () => {
  const { saveUIState } = useGetActions()
  const uiState = useUIState((state) => state)

  React.useEffect(() => {
    saveUIState(uiState)
  })
  return null
}

// A custom hook that builds on useLocation to parse
// the query string for you.
export const useQuery = () => {
  return new URLSearchParams(useLocation().search)
}
