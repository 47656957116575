import styled from 'styled-components'

const Component = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
  background: ${(p) => p.theme.uiBlockerBgColor};
  z-index: ${(p) => p.zIndex};
`

export default Component
