import React from 'react'
import styled from 'styled-components'
import { FontIcon } from 'APP/ui-kit/Vendor'

const Box = ({ IconToRender, text, iconName, containerStyle, selected, rotating, ...rest }) => (
  <Container style={containerStyle} {...rest} selected={selected}>
    {/**{selected && <Selected />} */}
    <Top>{(IconToRender && <IconToRender />) || <FontIcon iconName={iconName} className={rotating && 'rotating'} />}</Top>
    <Bottom>{text}</Bottom>
  </Container>
)

const Container = styled.div`
  position: relative;
  ${(p) =>
    p.selected &&
    `
    background:${p.theme.sidebarSelectedItemBgColor};
  `}
`
const Row = styled.div`
  color: ${(p) => p.theme.sidebarItemTextColor};
  text-align: center;
  min-height: 28px;
  display: flex;
  justify-content: center;
  cursor: pointer;
`

const Top = styled(Row)`
  padding-bottom: 4px;
  align-items: flex-end;
`

const Bottom = styled(Row)`
  padding-top: 4px;
  align-items: flex-start;
  font-size: 10px;
`
export default Box
