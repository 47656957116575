import React from 'react'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { TextField } from 'APP/ui-kit/Vendor'
import { useMessage, FormattedMessage } from 'APP/language'
import FormModal from 'APP/ui-kit/Modals/Form'

const initialValues = {
  oldPassword: '',
  newPassword: '',
}

const schema = yup.object().shape({
  oldPassword: yup.string().required('VALIDATION_OLD_PASSWORD_REQUIRED'),
  newPassword: yup.string().required('VALIDATION_NEW_PASSWORD_REQUIRED'),
})

const config = {
  initialValues,
  validationSchema: schema,
}

const ChangePassword = ({ hideForm = () => {}, changePassword }) => {
  const [CHANGE_PASSWORD_OLD_PLACEHOLDER, CHANGE_PASSWORD_NEW_PLACEHOLDER, CHANGE_PASSWORD_OLD_LABEL, CHANGE_PASSWORD_NEW_LABEL, CANCEL, UPDATE, CHANGE_PASSWORD_FORM_TITLE] = useMessage(
    'CHANGE_PASSWORD_OLD_PLACEHOLDER',
    'CHANGE_PASSWORD_NEW_PLACEHOLDER',
    'CHANGE_PASSWORD_OLD_LABEL',
    'CHANGE_PASSWORD_NEW_LABEL',
    'CANCEL',
    'UPDATE',
    'CHANGE_PASSWORD_FORM_TITLE'
  )
  const [formErrorProp, setFormErrorProp] = React.useState(null)

  const { handleSubmit, handleChange, values, errors, isSubmitting, setSubmitting, setErrors } = useFormik({
    ...config,
    onSubmit: (values) => {
      setSubmitting(true)
      changePassword({
        data: values,
        callback: (success, message, errs) => {
          if (!success) {
            setSubmitting(false)
            errs && setErrors(errs)
            setFormErrorProp(message)
          } else {
            hideForm()
          }
        },
      })
    },
  })

  const customHandleSubmit = (e) => {
    e.preventDefault()
    handleSubmit()
  }

  return (
    <FormModal
      formErrorProp={formErrorProp}
      primaryBtnTxt={UPDATE}
      secondryBtnTxt={CANCEL}
      disabled={isSubmitting}
      modalTitle={CHANGE_PASSWORD_FORM_TITLE}
      hideModal={hideForm}
      onSubmit={customHandleSubmit}>
      <form onSubmit={customHandleSubmit}>
        <TextField
          disabled={isSubmitting}
          errorMessage={errors['oldPassword'] && <FormattedMessage id={errors['oldPassword']} />}
          placeholder={CHANGE_PASSWORD_OLD_PLACEHOLDER}
          label={CHANGE_PASSWORD_OLD_LABEL}
          value={values.password}
          name="oldPassword"
          onChange={handleChange}
          autoComplete="off"
          type="password"
        />
        <div style={{ height: 16 }}></div>
        <TextField
          disabled={isSubmitting}
          errorMessage={errors['newPassword'] && <FormattedMessage id={errors['newPassword']} />}
          placeholder={CHANGE_PASSWORD_NEW_PLACEHOLDER}
          label={CHANGE_PASSWORD_NEW_LABEL}
          value={values.password}
          name="newPassword"
          onChange={handleChange}
          autoComplete="off"
          type="password"
        />
        {/**required for form to submit when user presses enter */}
        <button type="submit" style={{ display: 'none' }} />
      </form>
    </FormModal>
  )
}

export default ChangePassword
