import { get, find } from 'APP/utils/lodash'
import { isStreamASystemTag, isStreamAUserTag, isOlderThanXDays } from 'APP/utils/common'
import { SYSTEM_TAGS } from 'APP/utils/enums'

export const getIntegrationUserIndex = (state) => {
  return get(state, 'appState.uiState.integrationUserIndex')
}

export const getIntegrationUsers = (state) => {
  return get(state, 'integrationUsers.integrationUsers')
}

export const getUIState = (state) => {
  return get(state, 'appState.uiState')
}

export const getIntegrations = (state) => {
  return get(state, 'accountsManager.integrations')
}

export const getUser = (state) => {
  return get(state, 'user.user')
}

// Returns current active integration user
export const getIntegrationUser = (state) => {
  const intUserIndex = getIntegrationUserIndex(state)
  const integrationUsers = getIntegrationUsers(state)
  return integrationUsers[intUserIndex]
}

export const getSubscriptions = (state) => {
  return get(state, 'subscriptions.subscriptions')
}
export const getIsSubscriptionsLoaded = (state) => {
  return get(state, 'subscriptions.isSubscriptionsLoaded')
}

export const getStories = (state) => {
  return get(state, 'stories.stories') || []
}
export const getStoriesModel = (state) => {
  return get(state, 'stories')
}

export const getCounts = (state) => {
  return get(state, 'markers.counts')
}
export const getReadIds = (state) => {
  return get(state, 'markers.readIds')
}
export const getUnreadIds = (state) => {
  return get(state, 'markers.unReadIds')
}
export const getMarkerSubscriptionPostions = (state) => {
  return get(state, 'markers.subscriptionPostions')
}
export const getMarkerSubscriptionPostion = (state, story) => {
  const position = get(state, 'markers.subscriptionPostions')
  return position && position[story.subscriptionId]
}

export const getMany = (...props) => {
  //getMany(state,getStories,getSubscriptions)
  const [state, ...fns] = props
  return (fns || []).map((fn) => fn(state))
}

export const getMarkersInfo = (state) => {
  // const integrationUser = getIntegrationUser(state)
  return {
    autoMarkStoryAsReadOlderThan: 31,
  }
}

export const getTags = (state) => {
  return get(state, 'tags.tags')
}
export const getSystemTags = (state) => {
  const tags = getTags(state)
  return tags.filter((t) => isStreamASystemTag(t.id))
}
export const getUserTags = (state) => {
  const tags = getTags(state)
  return tags.filter((t) => isStreamAUserTag(t.id)) || []
}

export const getTagIds = (state) => {
  return (getTags(state) || []).map((t) => t.id)
}
export const getStoryIdsOfATag = (state, tag) => {
  const tagWithStories = getTagsWithStoryIds(state)
  return tagWithStories[tag] || {}
}
export const getTagsWithStoryIds = (state) => {
  return get(state, `tags.tagsWithStoryIds`)
}

export const getTagsOfAStory = (state) => {
  return get(state, `tags.tagsWithStoryIds`)
}

export const getParsedArticles = (state) => {
  return get(state, `parsedArticles`)
}

export const produceStoryMeta = (state, story = {}) => {
  const parsedArticles = getParsedArticles(state)
  const { autoMarkStoryAsReadOlderThan } = getMarkersInfo(state)
  const position = getMarkerSubscriptionPostion(state, story)
  let isRead = isOlderThanXDays(story, autoMarkStoryAsReadOlderThan)
  const tagsWithStoryIds = getTagsWithStoryIds(state)
  const storyTags = Object.keys(tagsWithStoryIds).filter((k) => tagsWithStoryIds[k][story.id]) || []
  const tags = getTags(state)
  const laterTag = find(tags || [], { labelKey: SYSTEM_TAGS.LATER.id }) || {}
  const favouriteTag = find(tags || [], { labelKey: SYSTEM_TAGS.FAVOURITE.id }) || {}

  if (!isRead) {
    if (position && position >= story.published) {
      isRead = !getUnreadIds(state)[story.id]
    } else {
      isRead = !!getReadIds(state)[story.id]
    }
  }

  let storyMeta = {
    autoMarkStoryAsReadOlderThan,
    isBookmarked: storyTags.indexOf(laterTag.id) > -1,
    isFavourite: storyTags.indexOf(favouriteTag.id) > -1,
    isRead,
    hasParsedArticle: !!parsedArticles[story.url],
    storyTags,
  }
  return storyMeta

  // if(!isRead){
  //   if (position) {
  //     if (position > story.published) {
  //       isRead = !getUnreadIds(state)[story.id]
  //     } else {
  //       isRead = !!getReadIds(state)[story.id]
  //     }
  //   } else {
  //     isRead = !!getReadIds(state)[story.id]
  //   }
  // }
}
export const getMessagesDict = (state, keys = []) => {
  return get(state, `language`) || {}
}
export const getMessages = (state, keys = []) => {
  const messsages = get(state, `language`) || {}
  return keys.map((k) => messsages[k])
}

export const isRefreskTokenInProgress = (state, keys = []) => {
  return get(state, `accountsManager.isRefreskTokenInProgress`)
}

export const getSettings = (state) => {
  return get(state, `settings`)
}

export const getAppBlocker = (state) => {
  return get(state, `appState.appBlocker`)
}

export const getIsAppBlocked = (state) => {
  const appBlocker = getAppBlocker(state) || {}
  return Object.keys(appBlocker).length > 0
}

export const getApiStatus = (state, operationKey) => {
  return get(state, `apiStatus.${operationKey}.status`)
}

export const getOnboardingRecommendations = (state) => {
  return get(state, `onboardingRecommendations.recommendations`)
}

export const getOnboardingRecommendationsActive = (state) => {
  const user = getUser(state)
  return !get(user, `onBoarded`)
}
