import React from 'react'
import styled from 'styled-components'
import { Shimmer, ShimmerElementType } from 'APP/ui-kit/Vendor'

const Card = ({ title, hoverable, disabled, children, className, style, padding, loading, ...rest }) => (
  <Container style={style} className={className} hoverable={hoverable} disabled={disabled} padding={padding} {...rest}>
    {title && (
      <div>
        <Title>{title}</Title>
      </div>
    )}
    {loading && <Shimmer shimmerElements={[{ type: ShimmerElementType.line, height: 160 }]} />}
    {!loading && children}
  </Container>
)

const Container = styled.div`
  background: ${(p) => p.theme.cardBackground};
  border: 1px solid ${(p) => p.theme.cardBorder};
  border-radius: ${(p) => p.theme.borderRadius2};
  padding: ${(p) => p.padding || '16'}px;
  user-select: none;
  ${(p) =>
    p.hoverable &&
    !p.disabled &&
    `
    cursor:pointer;
    &:hover {
      box-shadow: ${p.theme.elevation1};
    };
  `}

  ${(p) =>
    p.disabled &&
    `
    background:${p.theme.cardDisabledBackground};
  `}
`

const Title = styled.div`
  font-size: 18px;
  font-weight: 600;
  padding: 0px 16px 16px 16px;
  padding-left: 0px;
  color: ${(p) => p.theme.cardTitleColor};
`

export default Card
