import { handleActions } from 'redux-actions'
import { takeLatest, call, put } from 'redux-saga/effects'
import produce from 'immer'
import db from 'APP/services/dbZervice'
import { handleSaga, create3Actions } from 'APP/utils/reduxUtils'
import { serviceEffext, API_OPERATIONS } from 'APP/services/serviceEffext'

const MODULE_NAME = 'parsedArticles'
const createActions = create3Actions(MODULE_NAME)

export const actions = {
  parseArticle: createActions('parseArticle'),
  loadOne: createActions('loadOne'),
}

const parsedArticles = {}
export const reducer = handleActions(
  {
    [actions.parseArticle.success]: (state, { payload }) =>
      produce(state, (draft) => {
        const { url, data } = payload
        draft[url] = data
      }),
    [actions.loadOne.success]: (state, { payload }) =>
      produce(state, (draft) => {
        if (payload) {
          const { url, data } = payload
          draft[url] = data
        }
      }),
  },
  parsedArticles
)

export const rootSaga = {
  [actions.parseArticle.request]: handleSaga(
    takeLatest,
    function* ({ payload: story }) {
      const result = yield serviceEffext(API_OPERATIONS.GET_CLEAN_ARTICLE, { url: story.url })
      yield call(db.saveParsedArticle, { url: story.url, data: result.data })
      yield put(actions.parseArticle.success({ url: story.url, data: result.data }))
    },
    {
      showProgressIndicator: true,
    }
  ),
  [actions.loadOne.request]: handleSaga(takeLatest, function* ({ payload: story }) {
    if (story && story.url) {
      const data = yield call(db.getParsedArticle, { url: story.url })
      if (data) {
        yield put(actions.loadOne.success(data))
      }
    }
  }),
}
