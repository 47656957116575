import React from 'react'
import Avatar from 'APP/ui-kit/Avatar'
import { ROUTES } from 'APP/utils/enums'
import { useMessage } from 'APP/language'
import * as S from './styled'
import { useHistory } from 'react-router-dom'
import { useUIState } from 'APP/utils/uiHooks'

const Veen = () => {
  return <S.LogoContainer>Veen</S.LogoContainer>
}

const Component = ({ sync, syncing, name, picture }) => {
  let [months, synclabel, addLabel, settingsLabel] = useMessage('MONTHS', 'SYNC', 'ADD', 'SETTINGS')
  const history = useHistory()
  const sideBar = useUIState((state) => state.sideBar)
  const toggleAccountPopup = useUIState((state) => state.toggleAccountPopup)
  const integrationUserIndex = useUIState((state) => state.integrationUserIndex)

  const now = new Date()
  const month = months[now.getMonth()]
  const date = now.getDate()
  return (
    <S.Container>
      <S.Box
        text={<S.DateComp>{`${date} ${month}`}</S.DateComp>}
        IconToRender={Veen}
        onClick={() => {
          history.push(ROUTES.generateDashboardHomePath(integrationUserIndex))
        }}
        selected={sideBar.isHome}
      />
      <S.Box
        text={addLabel}
        iconName="Add"
        containerStyle={{ marginTop: 'auto' }}
        onClick={() => {
          history.push(ROUTES.generateDashboardSubscriptionSystemPath(integrationUserIndex))
        }}
        selected={sideBar.isSubscriptions}
      />
      <S.Box text={synclabel} iconName="Sync" rotating={syncing} onClick={sync} />
      <S.Box
        text={settingsLabel}
        iconName="Settings"
        onClick={() => {
          history.push(ROUTES.generateDashboardSettingsPath(integrationUserIndex))
        }}
        selected={sideBar.isSettings}
      />
      <S.Box
        IconToRender={() => (
          <Avatar size={28} imageUrl={picture} style={{ marginTop: 10 }}>
            {name[0]}
          </Avatar>
        )}
        text={name}
        onClick={toggleAccountPopup}
        selected={sideBar.isAccounts}
      />
    </S.Container>
  )
}

export default Component
