import { connect } from 'react-redux'
import Pure from './Pure'
import { find } from 'APP/utils/lodash'
import { actions } from 'APP/components/StoriesList/model'
import { actions as TagActions } from 'APP/components/Models/tags'
import { actions as parseArticleActions } from 'APP/components/StoriesList/parsedArticlesModel'
import { getUIState, getStories, getTags, getUserTags, getSystemTags, produceStoryMeta, getTagsWithStoryIds } from 'APP/utils/reduxSelectors'
import { actions as MarkerActions } from 'APP/components/Models/markers'

const mapStateToProps = (state) => {
  const { selectedStoryId } = getUIState(state)
  const stories = getStories(state)
  const tags = getTags(state)
  const systemTags = getSystemTags(state)
  const userTags = getUserTags(state)
  const tagsWithStoryIds = getTagsWithStoryIds(state)
  const story = find(stories, { _id: selectedStoryId })
  let parsedArticle = story ? state.parsedArticles[story.url] : null
  const storyMeta = produceStoryMeta(state, story, tags)
  return {
    story,
    storyMeta,
    parsedArticle,
    tags,
    systemTags,
    userTags,
    tagsWithStoryIds,
  }
}

export default connect(mapStateToProps, {
  parseArticle: parseArticleActions.parseArticle.request,
  loadParsedArticle: parseArticleActions.loadOne.request,
  toggleStoryActivity: actions.toggleStoryActivity.request,
  copyLink: actions.copyLink,
  moveStory: actions.move,
  addTagToStory: TagActions.addTagToStory,
  removeTagfromStory: TagActions.removeTagfromStory,
  markAsRead: MarkerActions.markAsRead.request,
  keepAsUnRead: MarkerActions.keepAsUnRead.request,
})(Pure)
