import React from 'react'
import styled from 'styled-components'
import { Shimmer, ShimmerElementType } from 'APP/ui-kit/Vendor'

const StoryCard = ({ style }) => {
  return (
    <OuterContainer style={{ ...style }}>
      <InnerContainer>
        <Left>
          <Shimmer shimmerElements={[{ type: ShimmerElementType.line, height: 87 }]} />
        </Left>
        <Right>
          <Shimmer />
          <Shimmer style={{ marginTop: 8 }} width="75%" />
          <Shimmer
            style={{ marginTop: 'auto' }}
            shimmerElements={[
              { type: ShimmerElementType.circle, height: 16 },
              { type: ShimmerElementType.gap, width: '5%' },
              { type: ShimmerElementType.line },
              { type: ShimmerElementType.gap, width: '5%' },
              { type: ShimmerElementType.line },
            ]}
          />
        </Right>
      </InnerContainer>
    </OuterContainer>
  )
}

const OuterContainer = styled.div`
  display: flex;
  padding: 0px 8px;
`

const InnerContainer = styled.div`
  display: flex;
  flex: 1;
  border-bottom: 1px solid ${(p) => p.theme.skeletonCardBottomBorderColor};
  padding: 8px 0px;
`

const Left = styled.div`
  width: 104px;
  height: 87px;
`

const Right = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding-left: 12px;
`

export default StoryCard
