import React from 'react'
import ReactDOM from 'react-dom'
import config from 'APP/utils/config'
import * as Sentry from '@sentry/react'
import ReactApp from './app'
import bootStrapApp from './bootstrap'

// Add sentry
if (!config.isLocalHost) {
  Sentry.init({
    dsn: 'https://b48ac965ee5c453ba8b51187a34e9599@o417998.ingest.sentry.io/5319924',
    release: `${config.platform}:${config.appEnv}:${config.appVersion}`,
  })
}

// Hide logs for prodcution
if (config.isProduction) {
  console.log = () => {}
}

function startApp() {
  ReactDOM.render(<ReactApp />, document.getElementById('root'))
}

bootStrapApp(startApp)
