import React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { actions } from './model'
import ConnectionCard from 'APP/ui-kit/ConnectionCard'
import { INTEGRATION_CODES } from 'APP/utils/enums'
import FoundryForm from './FoundryForm'
import { useMessage } from 'APP/language/index'
import Card from 'APP/ui-kit/Card'
import { getIntegrations as getIntegrationsFromState } from 'APP/utils/reduxSelectors'

const IntegrationForms = {
  [INTEGRATION_CODES.FOUNDRY]: FoundryForm,
}

const Component = ({ reset, getIntegrations, integrations, startAuthFlow, activeIntegration, createIntegrationUser, cancelAuthFlow }) => {
  React.useEffect(() => {
    !integrations && getIntegrations()
  }, [getIntegrations, integrations])

  const [PLEASE_UPGRADE_APP, ADD_ACCOUNT] = useMessage('PLEASE_UPGRADE_APP', 'ADD_ACCOUNT')

  let FormToRender = activeIntegration && IntegrationForms[activeIntegration.code]
  return (
    <Card
      loading={!integrations}
      title={ADD_ACCOUNT}
      style={{
        margin: '16px auto',
        width: '100%',
        maxWidth: 600,
      }}>
      <React.Fragment>
        {integrations && (
          <CardsContainer>
            {integrations.map((i, index) => {
              const subText = i.disabled ? PLEASE_UPGRADE_APP : null
              return <ConnectionCard onClick={() => startAuthFlow(i)} title={i.name} key={i.code} disabled={i.disabled} imageUrl={i.visualUrl} subText={subText} />
            })}
          </CardsContainer>
        )}
      </React.Fragment>
      <React.Fragment>{FormToRender && <FormToRender hideModal={cancelAuthFlow} integration={activeIntegration} createIntegrationUser={createIntegrationUser} />}</React.Fragment>
    </Card>
  )
}

const CardsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`

const mapStateToProps = (state) => {
  return {
    integrations: getIntegrationsFromState(state),
    activeIntegration: state.accountsManager.activeIntegration,
  }
}

export default connect(mapStateToProps, {
  getIntegrations: actions.getIntegrations.request,
  startAuthFlow: actions.startAuthFlow,
  createIntegrationUser: actions.createIntegrationUser.request,
  reset: actions.reset,
  cancelAuthFlow: actions.cancelAuthFlow,
})(Component)
