import React from 'react'
import { useFormik } from 'formik'
import { withTheme } from 'styled-components'
import * as yup from 'yup'
import { TextField, Dialog, DialogType, DialogFooter, PrimaryButton, DefaultButton } from 'APP/ui-kit/Vendor'
import { useMessage, FormattedMessage } from 'APP/language'

const initialValues = {
  fullName: '',
}

const schema = yup.object().shape({
  fullName: yup.string().required('VALIDATION_FULL_NAME_REQUIRED'),
})

const config = {
  initialValues,
  validationSchema: schema,
}

const FoundryForm = ({ hideModal = () => {}, createIntegrationUser, integration }) => {
  const [CREATE_ACCOUNT, FULL_NAME_LABEL, CREATE, CANCEL, FULL_NAME_PLACEHOLDER] = useMessage('CREATE_ACCOUNT', 'FULL_NAME_LABEL', 'CREATE', 'CANCEL', 'FULL_NAME_PLACEHOLDER')
  const [formErrorProp, setFormErrorProp] = React.useState(null)
  const { handleSubmit, handleChange, values, errors, isSubmitting, setSubmitting, setErrors } = useFormik({
    ...config,
    onSubmit: (values) => {
      setSubmitting(true)
      createIntegrationUser({
        data: values,
        integration,
        callback: (success, message, errs) => {
          if (!success) {
            setSubmitting(false)
            errs && setErrors(errs)
            setFormErrorProp(message)
          }
        },
      })
    },
  })

  return (
    <Dialog
      hidden={false}
      onDismiss={hideModal}
      minWidth={375}
      dialogContentProps={{
        type: DialogType.largeHeader,
        title: CREATE_ACCOUNT,
        subText: formErrorProp && <ErrorMessage errorProp={formErrorProp} />,
      }}
      modalProps={{
        isBlocking: true,
      }}>
      <form
        onSubmit={(e) => {
          e.preventDefault()
          handleSubmit()
        }}>
        <TextField
          disabled={isSubmitting}
          errorMessage={errors['fullName'] && <FormattedMessage id={errors['fullName']} />}
          placeholder={FULL_NAME_PLACEHOLDER}
          label={FULL_NAME_LABEL}
          value={values.fullName}
          name="fullName"
          onChange={handleChange}
          autoComplete="off"
        />
        <DialogFooter>
          <PrimaryButton type="submit" text={CREATE} disabled={isSubmitting} />
          <DefaultButton onClick={hideModal} text={CANCEL} disabled={isSubmitting} />
        </DialogFooter>
      </form>
    </Dialog>
  )
}

const ErrorMessage = withTheme(({ theme, errorProp }) => {
  const [errorMessage] = useMessage(errorProp)
  return <span style={{ color: theme.formValidationErrorText }}>{errorMessage || errorProp}</span>
})

export default FoundryForm
