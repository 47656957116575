import React from 'react'
import { HashRouter, BrowserRouter, Switch } from 'react-router-dom'
import { ROUTES } from 'APP/utils/enums'
import { BaseRoute, DashboardLayout, PublicRoute } from 'APP/utils/Routers'
import { setDefaultSections } from 'APP/ui-kit/Layouts/Dashboard'
import Config from 'APP/utils/config'
import { PLATFORMS } from 'APP/utils/enums'

// Import sections
import Welcome from 'APP/sections/Welcome'
import Sidebar from 'APP/sections/Sidebar'
import SubscriptionList from 'APP/sections/SubscriptionList'
import StoriesList from 'APP/sections/StoriesList'
import StoryDetails from 'APP/sections/StoryDetails'
import Settings from 'APP/sections/Settings'
import SubscriptionSystem from 'APP/sections/SubscriptionSystem'
import VerifyEmail from 'APP/components/Public/VerifyEmail'

const Router = Config.platform === PLATFORMS.web ? BrowserRouter : HashRouter

// setup default section
setDefaultSections({ leftSection: SubscriptionList, rightSection: StoryDetails, middleSection: StoriesList, sidebar: Sidebar })

const AppRouter = () => {
  return (
    <Router>
      <Switch>
        <PublicRoute exact onboarding path={ROUTES.VERIFY_EMAIL} component={VerifyEmail} />
        <BaseRoute exact onboarding path={ROUTES.ONBOARDING_LOGIN} component={Welcome} />
        <BaseRoute exact onboarding path={ROUTES.ONBOARDING_REGISTER} component={Welcome} />
        <BaseRoute exact onboarding path={ROUTES.ONBOARDING_FORGOT_PASSWORD} component={Welcome} />
        <DashboardLayout exact path={ROUTES.DASHBOARD_SETTINGS} right={Settings} left={null} middle={null} />
        <DashboardLayout exact path={ROUTES.DASHBOARD_SUBSCRIPTION_SYSTEM} right={SubscriptionSystem} left={null} middle={null} />
        <DashboardLayout exact path={ROUTES.DASHBOARD} />
        <DashboardLayout exact path={ROUTES.DASHBOARD_STORY} />
        <DashboardLayout exact path={ROUTES.DASHBOARD_USER} />
        <DashboardLayout exact path={ROUTES.DASHBOARD_USER_FOLDER} />
        <DashboardLayout exact path={ROUTES.DASHBOARD_USER_FOLDER_SUBSCRIPTIONS} />
        <DashboardLayout exact path={ROUTES.DASHBOARD_USER_STORY} />
        <DashboardLayout exact path={ROUTES.DASHBOARD_USER_FOLDER_STORY} />
        <DashboardLayout path={ROUTES.DASHBOARD_USER_SUBSCRIPTIONS_STORY} />
        <DashboardLayout path={ROUTES.DASHBOARD_SYSTEM_TAG_STORY} />
        <DashboardLayout path={ROUTES.DASHBOARD_USER_TAG_STORY} />
        <DashboardLayout path={ROUTES.DASHBOARD_SYSTEM_TAG} />
        <DashboardLayout path={ROUTES.DASHBOARD_USER_TAG} />

        <BaseRoute path={ROUTES.CATCH_ALL} component={Welcome} />
        {/**CATCH ALL */}
      </Switch>
    </Router>
  )
}

export default AppRouter
