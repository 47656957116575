import { handleActions } from 'redux-actions'
import produce from 'immer'
import { createAction } from 'APP/utils/reduxUtils'

const MODULE_NAME = 'API_STATUS'

/* ------------- Actions ------------- */
export const actions = {
  upsert: createAction(MODULE_NAME, 'upsert'),
}

/* ------------- Initial state ------------- */
const initialState = {}

/* ------------- reducer ------------- */
export const reducer = handleActions(
  {
    [actions.upsert]: (state, { payload }) =>
      produce(state, (draft) => {
        return { ...draft, ...payload }
      }),
  },
  initialState
)
