import { handleActions } from 'redux-actions'
import produce from 'immer'
import { createAction } from 'APP/utils/reduxUtils'

const MODULE_NAME = 'LANGUAGE'

/* ------------- Actions ------------- */
export const actions = {
  change: createAction(MODULE_NAME, 'change'),
}

/* ------------- Initial state ------------- */
const initialState = {}

/* ------------- reducer ------------- */
export const reducer = handleActions(
  {
    [actions.change]: (state, { payload }) =>
      produce(state, (draft) => {
        return payload
      }),
  },
  initialState
)
