import React, { useEffect } from 'react'
import { useQuery } from 'APP/utils/uiHooks'
import { useServiceEffect, API_OPERATIONS } from 'APP/services/serviceEffext'
import { API_STATUS } from 'APP/utils/enums'
import { Platform } from 'APP/app/resources'

const messages = {
  [API_STATUS.PROGRESS]: 'Verifying....',
  [API_STATUS.SUCCESS]: 'Verified. Redireting to login page in ',
  [API_STATUS.FAIL]: 'Failed to verify, please refresh the page to retry',
}

const Pure = () => {
  const [counter, setCounter] = React.useState(1)
  const [status, makeRequest] = useServiceEffect(API_OPERATIONS.VERIFY_EMAIL)
  const query = useQuery()

  useEffect(() => {
    const code = query && query.get('code')
    makeRequest({ code })
  }, [])

  useEffect(() => {
    if (status === API_STATUS.SUCCESS) {
      if (counter === 5) {
        Platform.windowMoveToPath('/welcome/login')
      }
      return setTimeout(() => setCounter(counter + 1), 1000)
    }
  }, [status, counter])

  return (
    <div>
      {messages[status]} {status === API_STATUS.SUCCESS && 5 - counter}
    </div>
  )
}

export default Pure
