import { find, sortBy } from 'APP/utils/lodash'
import { FOLDER_IDS, FOLDER_TYPES } from 'APP/utils/enums'
import { formatUnreadCount } from 'APP/utils/common'

export const convertToFolders = ({ subscriptions, uiState, counts = {}, tags = [] }) => {
  let { selectedSubscriptionId = '', selectedFolderId = '', selectedTagId = '' } = uiState
  let folders = []
  tags = sortBy(tags, ['sort'])
  selectedTagId = selectedTagId.toLowerCase()

  // system tags
  const systemTags = tags
    .filter((t) => t.system)
    .map((t) => ({
      ...t,
      subscriptions: [],
      iconName: t.icon,
      isTag: true,
      selected: selectedTagId === (t.id || '').toLowerCase(),
    }))

  // user tags
  const userTags = tags
    .filter((t) => !t.system)
    .map((t) => ({
      ...t,
      title: t.label,
      iconUrl: t.icon,
      hasFontIcon: true,
      isTag: true,
      selected: selectedTagId === (t.id || '').toLowerCase(),
      customizable: false, // make it true when tags menu is readyy
    }))
  const tagsForUI = {
    id: FOLDER_IDS.TAGS.id,
    label: FOLDER_IDS.TAGS.name,
    labelKey: FOLDER_IDS.TAGS.id,
    customizable: false,
    selected: false,
    subscriptions: userTags,
    system: true,
  }

  // Uncat folder
  const unCategorisedCategory = {
    id: FOLDER_IDS.UNCATEGORISED.id,
    label: FOLDER_IDS.UNCATEGORISED.name,
    labelKey: FOLDER_IDS.UNCATEGORISED.id,
    customizable: false,
    selected: !selectedSubscriptionId && FOLDER_IDS.UNCATEGORISED.id === selectedFolderId,
    subscriptions: [],
    system: true,
    type: FOLDER_TYPES.FOLDER,
  }

  // prepares folders from subscriptions
  for (let i = 0; i < subscriptions.length; i++) {
    let sub = subscriptions[i]
    sub = { ...sub, unreadCount: formatUnreadCount(counts[sub.id]), customizable: true }

    let categories = sub.categories || []
    if (categories.length === 0) {
      unCategorisedCategory.subscriptions.push({ ...sub, selected: calculateUniqueSubId(unCategorisedCategory, sub) === `${selectedFolderId}:${selectedSubscriptionId}` })
    }
    for (let j = 0; j < categories.length; j++) {
      let category = categories[j]
      // find this category in folders
      let folder = find(folders, { id: category.id })
      //if folder not found, then create it and add to folders
      if (!folder) {
        folder = {
          id: category.id,
          label: category.label,
          subscriptions: [],
          selected: !selectedSubscriptionId && category.id === selectedFolderId,
          customizable: true,
          type: FOLDER_TYPES.FOLDER,
        }
        folders.push(folder)
      }
      folder.subscriptions.push({
        ...sub,
        selected: calculateUniqueSubId(folder, sub) === `${selectedFolderId}:${selectedSubscriptionId}`,
      })
    }
  }

  // sort folders by label
  folders = sortBy(folders, ['label'])

  // Add uncategorised into folders
  if (unCategorisedCategory.subscriptions.length > 0) {
    folders.push(unCategorisedCategory)
  }

  return userTags.length > 0 ? [...systemTags, ...folders, tagsForUI] : [...systemTags, ...folders]
  // return [...labels, ...folders, tagsForUI]
}

// a subscription may exists in two folders. this generates a unique for each subscription
export const calculateUniqueSubId = (folder, subscription) => `${folder.id}:${subscription.id}`

export const calculateSelectedSubscriptionIds = ({ selectedSubscriptionId, selectedFolderId }, subscriptions = []) => {
  if (selectedSubscriptionId) {
    return [selectedSubscriptionId]
  } else if (selectedFolderId === FOLDER_IDS.UNCATEGORISED.id) {
    return subscriptions.filter((s) => s.categories.length === 0).map((s) => s.id)
  } else if (selectedFolderId) {
    return subscriptions.filter((s) => find(s.categories, { id: selectedFolderId })).map((s) => s.id)
  }
  return []
}

export const convertArrayToDict = (items = [], value = 1) => {
  return items.reduce((a, c) => ({ ...a, [c]: value }), {})
}
