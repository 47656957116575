import React from 'react'
import Confirm from 'APP/ui-kit/Modals/Confirm'
import Form from 'APP/ui-kit/Modals/Form'
import { useSelector } from 'react-redux'
import { get } from 'APP/utils/lodash'
import { useDispatch } from 'react-redux'

const Component = () => {
  const globalDialog = useSelector((s) => get(s, 'globalDialog'))
  const dispatch = useDispatch()
  // if global dialog has any data, then show modal
  const isVisible = Object.keys(globalDialog).length > 0
  if (!isVisible) {
    return null
  }
  const onConfirm = globalDialog.onConfirmDispatch ? (data) => globalDialog.onConfirmDispatch(dispatch, data) : globalDialog.onConfirm
  const onCancel = globalDialog.onCancelDispatch ? (data) => globalDialog.onCancelDispatch(dispatch, data) : globalDialog.onCancel
  const ComponentToRender = globalDialog.isForm ? Form : Confirm
  return <ComponentToRender {...globalDialog} onConfirm={onConfirm} onCancel={onCancel} />
}

export default Component
