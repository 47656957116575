import { handleActions } from 'redux-actions'
import produce from 'immer'
import { create3Actions, createAction } from 'APP/utils/reduxUtils'

const MODULE_NAME = 'GENERIC_DIALOG'
const createActions = create3Actions(MODULE_NAME)

export const actions = {
  showPopup: createAction(MODULE_NAME, 'showPopup'),
  hidePopup: createAction(MODULE_NAME, 'hidePopup'),
}

const initialState = {
  // Sample data for Confirm Popup
  // onCancel: ()=>{},
  // onConfirm: ()=>{},
  // data:'',
  // subText:'',
  // message:'',
  // cancelBtnText:'',
  // confirmBtnText:'',
  // title:'',
  // onConfirmDispatch: (dispatch,data)=>{ dispatch(accountsManagerActions.logout())}
  // onCancelDispatch: (dispatch,data)=>{ dispatch(accountsManagerActions.logout())}
}

export const reducer = handleActions(
  {
    [actions.showPopup]: (state, { payload }) =>
      produce(state, (draft) => {
        return payload
      }),
    [actions.hidePopup]: (state, { payload }) =>
      produce(state, (draft) => {
        return {}
      }),
  },
  initialState
)

export const rootSaga = {}
