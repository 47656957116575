import { connect } from 'react-redux'
import Pure from './Pure'
import { actions } from './model'
import { actions as SubscriptionListActions } from 'APP/components/SubscriptionList/model'
import { convertToFolders } from 'APP/utils/formatter'
import { reject } from 'APP/utils/lodash'
import { getIntegrationUser, getUIState, getSubscriptions, getIsSubscriptionsLoaded, getMany } from 'APP/utils/reduxSelectors'

const mapStateToProps = (state) => {
  const [subscriptions, integrationUser, isSubscriptionsLoaded, uiState] = getMany(state, getSubscriptions, getIntegrationUser, getIsSubscriptionsLoaded, getUIState)
  const allFolders = convertToFolders({ subscriptions, uiState, integrationUser })
  return {
    ...state.subscriptionsManager,
    folders: reject(allFolders || [], { system: true }),
    subscriptions: subscriptions,
    integrationUser: integrationUser,
    isSubscriptionsLoaded: isSubscriptionsLoaded,
  }
}

export default connect(mapStateToProps, {
  search: actions.search.request,
  add: SubscriptionListActions.update.request,
  remove: SubscriptionListActions.remove.request,
  clear: actions.clear,
  loadSubscriptions: SubscriptionListActions.load.request,
})(Pure)
