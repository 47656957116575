import styled from 'styled-components'

export const FolderListContainer = styled.div`
  overflow-y: auto;
  height: 100%;
  background: ${(p) => p.theme.foldersListBGColor};
`

export const FolderContainer = styled.div`
  overflow-y: auto;
  margin-top: ${(p) => (p.bottomMargin ? '24px' : '0px')};
  user-select: none;
  -ms-user-select: none;
`

export const FolderLabel = styled.div`
  color: ${(p) => p.theme.folderTextColor};
  ${(p) => p.theme.fonts.medium};
  font-weight: 900;
  padding: 3px 8px 3px 3px;
  cursor: pointer;
  border-left: 3px solid transparent;
  &:hover {
    ${(p) =>
      !p.selected &&
      `
      color: ${p.theme.folderHoverTextColor};
    `}
  }
  ${(p) =>
    p.selected &&
    `
    background:${p.theme.folderSelectedBgColor};
    border-left: 3px solid ${p.theme.folderMarkerSelectedBgColor};
  `}
  ${(p) =>
    p.isContextMenuActive &&
    `background-color:${p.theme.folderMenuActiveBgColor} !important;
`}
`

export const FolderLabelWithIconWrapper = styled(FolderLabel)`
  color: ${(p) => p.theme.standardFolderTextColor};
  display: flex;
  align-items: center;
  height: 40px;
  font-weight: 600;
  ${(p) =>
    p.selected &&
    `
      color:${p.theme.standardFolderSelectedTextColor};
  `}

  div:nth-child(1) {
    height: 24px;
    width: 24px;
    align-items: center;
    justify-content: center;
    display: flex;
  }

  div:nth-child(2) {
    padding-left: 8px;
  }
`

// @todo: Grayscale is the reason items are taking sometime to render
export const SubscriptionContainer = styled.div`
  color: ${(p) => p.theme.subscriptionTextColor};
  display: flex;
  height: 40px;
  padding-left: 11px;
  align-items: center;
  cursor: pointer;
  filter: grayscale(100%);
  border-left: 5px solid transparent;
  ${(p) =>
    p.selected &&
    `
    filter:grayscale(10%);
    background:${p.theme.subscriptionSelectedBgColor};
    border-left:5px solid ${p.theme.subscriptionMarkerSelectedBgColor};
  `}
  &:hover {
    ${(p) =>
      !p.selected &&
      `
        filter:grayscale(20%);
        background:${p.theme.subscriptionHoverBgColor};
    `}
  }

  ${(p) =>
    p.isContextMenuActive &&
    `background-color:${p.theme.subscriptionMenuActiveBgColor} !important;
`}
`

export const SubscriptionIcon = styled.div`
  height: 24px;
  width: 24px;
  border-radius: 12px;
  align-items: center;
  justify-content: center;
  display: flex;
  background-position: center center;
  background-size: cover;
  background-image: url('${(p) => p.icon}');
`

export const SubscriptionLabel = styled.div`
  flex: 1;
  display: flex;
  padding-left: 8px;
`
export const SubscriptionUnreadNumber = styled.div`
  padding-right: 12px;
  ${(p) => p.theme.fonts.medium};
  font-weight: 300;
  font-size: 10px;
  color: #888;
`
