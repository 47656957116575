import { connect } from 'react-redux'
import Pure from './Pure'
import * as SettingsModel from './model'

const mapStateToProps = (state) => {
  return {
    settings: state.settings,
  }
}

export default connect(mapStateToProps, {
  update: (props) => SettingsModel.actions.update.request({ props, updateServer: true }),
})(Pure)
