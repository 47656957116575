import { handleActions } from 'redux-actions'
import produce from 'immer'
import { put, takeLatest, select, call } from 'redux-saga/effects'
import { handleSaga, create3Actions, createAction } from 'APP/utils/reduxUtils'
import dbService from 'APP/services/dbZervice'
import { actions as IntegrationActions } from 'APP/components/AccountsManager/model'
import { findAndReplaceOrAdd, findAndPatch } from 'APP/utils/lodash'
import { getUser } from 'APP/utils/reduxSelectors'

const MODULE_NAME = 'INTEGRATION_USERS'
const createActions = create3Actions(MODULE_NAME)

/* ------------- Actions ------------- */
export const actions = {
  load: createActions('load'),
  updateLastSuccessSyncTime: createAction(MODULE_NAME, 'LastSuccessSyncTime'),
}

/* ------------- Initial state ------------- */
const initialState = {
  integrationUsers: [],
}

/* ------------- reducer ------------- */
export const reducer = handleActions(
  {
    [actions.load.success]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.integrationUsers = payload
      }),
    [IntegrationActions.createIntegrationUser.success]: (state, { payload: intUser }) =>
      produce(state, (draft) => {
        findAndReplaceOrAdd(draft.integrationUsers, { _id: intUser._id }, intUser)
      }),
    [actions.updateLastSuccessSyncTime]: (state, { payload }) =>
      produce(state, (draft) => {
        const { startTime, integrationUser, isFullSync } = payload
        const propToUpdate = isFullSync ? 'lastSuccessFullAccountSyncTime' : 'lastSuccessAccountSyncTime'
        findAndPatch(draft.integrationUsers, { _id: integrationUser._id }, { [propToUpdate]: startTime })
      }),
  },
  initialState
)

/* ------------- root saga ------------- */
export const rootSaga = {
  [actions.load.request]: handleSaga(takeLatest, function* () {
    const user = yield select(getUser)

    const users = yield call(dbService.getIntegrationUsers, { user })
    yield put(actions.load.success(users))
  }),
  [actions.updateLastSuccessSyncTime]: handleSaga(takeLatest, function* ({ payload }) {
    const user = yield select(getUser)

    const { startTime, integrationUser, isFullSync } = payload
    const propToUpdate = isFullSync ? 'lastSuccessFullAccountSyncTime' : 'lastSuccessAccountSyncTime'
    yield call(dbService.updateIntegrationUser, { user, integrationUser, props: { [propToUpdate]: startTime } })
  }),
}
