import React, { useContext, createContext, useState } from 'react'
import en from './en'
import ru from './ru'
import { find } from 'APP/utils/lodash'

// Language dictionary
const LANGUAGES = {
  en: {
    languageCode: 'en',
    displayName: 'English',
    messages: en,
    default: true,
  },
  ru: {
    languageCode: 'ru',
    displayName: 'русский',
    messages: ru,
  },

  // Utils
  asArray: () => {
    return Object.keys(LANGUAGES)
      .filter((k) => !!LANGUAGES[k].languageCode)
      .reduce((accumulator, currentValue) => {
        accumulator.push({ ...LANGUAGES[currentValue] })
        return accumulator
      }, [])
  },
  getDefault: () => find(LANGUAGES.asArray(), { default: true }),
}

// Root context
var IntlContext = createContext()

/**
 * Hooks
 */

const useIntl = () => {
  let { locale, changeLocale } = useContext(IntlContext)
  return [locale, changeLocale]
}

const useMessage = (...ids) => {
  /**
   * usage
   * const [message1] = useMessage('key1')
   * const [message1, message2] = useMessage('key2')
   */
  let [locale] = useIntl()
  let messages = LANGUAGES[locale]['messages']
  return ids.map((i) => messages[i])
}

const useMessages = () => {
  let [locale] = useIntl()
  let messages = LANGUAGES[locale]['messages']
  return messages
}

const useFormattedMessage = (id, extraProps = [], defaultMessage) => {
  let value = useMessage(id)[0]
  let message = typeof value === 'function' ? value(...extraProps) : value
  return message || defaultMessage
}

/**
 * Components
 */

const IntlProvider = ({ children }) => {
  const [locale, changeLocale] = useState(LANGUAGES.getDefault()['languageCode'])
  return <IntlContext.Provider value={{ locale, changeLocale }}>{children}</IntlContext.Provider>
}

const FormattedMessage = ({ id, tag = 'span', extraProps }) => {
  let message = useFormattedMessage(id, extraProps, id)
  return React.createElement(tag, {}, message)
}

export { LANGUAGES, FormattedMessage, IntlProvider, useIntl, useMessage, useMessages, useFormattedMessage }

/**
 * Usage
 *
 * const [locale, changeLocale] = useIntl()
 * const [message] = useMessage(id)
 * const [message1, message2, message3] = useMessage(key1, key,key3)
 * const [messages] = useMessages()  => All app messages
 * useFormattedMessage(id, [extraProps])
 *
 * <IntlProvider />
 * <FormattedMessage id="" extraProps=["a","b"] />
 */
