import React from 'react'
import styled from 'styled-components'

const Avatar = ({ imageUrl, children = ' ', size, style }) => {
  return (
    <Container imageUrl={imageUrl} size={size} style={style}>
      {imageUrl ? null : children}
    </Container>
  )
}

const Container = styled.div`
  text-transform: uppercase;
  height: ${(p) => p.size}px;
  width: ${(p) => p.size}px;
  border-radius: ${(p) => p.size / 2}px;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  align-items: center;
  display: flex;
  font-weight: 600;
  color: ${(p) => p.theme.avatarText};
  background-color: ${(p) => p.theme.avatarBgColor};
  background-image: url(${(p) => p.imageUrl});
  background-position: center center;
  background-size: cover;
`

export default Avatar
