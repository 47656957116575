import { handleActions } from 'redux-actions'
import produce from 'immer'
import { put, takeLatest, call, select } from 'redux-saga/effects'
import { handleSaga, create3Actions, createAction } from 'APP/utils/reduxUtils'
import dbService from 'APP/services/dbZervice'
import { API_OPERATIONS, serviceEffext } from 'APP/services/serviceEffext'
import { getIntegrationUser, getMany } from 'APP/utils/reduxSelectors'
import { isStreamAUserTag, isStreamASystemTag } from 'APP/utils/common'
import { convertArrayToDict } from 'APP/utils/formatter'
import { pick } from 'APP/utils/lodash'

const MODULE_NAME = 'TAGS'
const createActions = create3Actions(MODULE_NAME)

/* ------------- Actions ------------- */
export const actions = {
  loadTags: createActions('loadTags'),
  fetchTags: createActions('fetchTags'),
  storeTags: createAction(MODULE_NAME, 'storeTags'),
  storeStories: createActions('formatAndSaveStories'),
  loadTaggedStories: createActions(MODULE_NAME, 'loadTaggedStories'),
  cleanTaggedStories: createAction(MODULE_NAME, 'cleanTaggedStories'),
  addTagToStory: createAction(MODULE_NAME, 'addTagToStory'),
  removeTagfromStory: createAction(MODULE_NAME, 'removeTagfromStory'),
  createNewTag: createActions('createNewtag'),
}

/* ------------- Initial state ------------- */
const initialState = {
  tags: [],
  tagsWithStoryIds: {},
}

/* ------------- Reducer ------------- */
export const reducer = handleActions(
  {
    [actions.storeTags]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.tags = payload.tags
      }),
    [actions.storeStories.success]: (state, { payload }) =>
      produce(state, (draft) => {
        const { storyIdAsDict, replace, streamId } = payload
        if (replace) {
          draft.tagsWithStoryIds[streamId] = storyIdAsDict
        } else {
          draft.tagsWithStoryIds[streamId] = { ...draft.tagsWithStoryIds[streamId], ...storyIdAsDict }
        }
      }),
    [actions.loadTaggedStories.success]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.tagsWithStoryIds = payload
      }),
    [actions.cleanTaggedStories]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.tagsWithStoryIds = pick(draft.tagsWithStoryIds, payload.tagIds)
      }),
    [actions.addTagToStory]: (state, { payload }) =>
      produce(state, (draft) => {
        const { story, tag } = payload
        draft.tagsWithStoryIds = draft.tagsWithStoryIds || {}
        draft.tagsWithStoryIds[tag.id] = draft.tagsWithStoryIds[tag.id] || {}
        draft.tagsWithStoryIds[tag.id][story.id] = 1
      }),
    [actions.removeTagfromStory]: (state, { payload }) =>
      produce(state, (draft) => {
        const { story, tag } = payload
        delete draft.tagsWithStoryIds[tag.id][story.id]
      }),
    [actions.createNewTag.success]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.tags = (draft.tags || []).concat(payload.tag)
      }),
  },
  initialState
)

/* ------------- Root Saga ------------- */
export const rootSaga = {
  [actions.loadTags.request]: handleSaga(takeLatest, function* () {
    const [integrationUser] = yield select((state) => getMany(state, getIntegrationUser))
    const tags = yield call(dbService.getTags, { integrationUser })
    console.log('Store tags in redux.....')
    yield put(actions.storeTags({ tags }))
  }),
  [actions.fetchTags.request]: handleSaga(
    takeLatest,
    function* () {
      const [integrationUser] = yield select((state) => getMany(state, getIntegrationUser))

      console.log('Fetch tags from server.....')
      const { data: tags } = yield serviceEffext(API_OPERATIONS.FETCH_TAGS)

      console.log('Store tags in redux.....')
      yield put(actions.storeTags({ tags }))

      console.log('Clear/Store tags in DB.....')
      yield call(dbService.clearIntUserTags, { integrationUser })
      yield call(dbService.upsertTags, { integrationUser, tags })
    },
    { progressMessageProducer: 'SYNC_TAGS' }
  ),
  [actions.loadTaggedStories.request]: handleSaga(takeLatest, function* () {
    const [integrationUser] = yield select((state) => getMany(state, getIntegrationUser))
    const tagsWithStoryIds = yield call(dbService.getTaggedStoryIds, { integrationUser })
    yield put(actions.loadTaggedStories.success(tagsWithStoryIds))
  }),
  [actions.storeStories.request]: handleSaga(takeLatest, function* ({ payload }) {
    const { streamId, stories, replace } = payload
    const [integrationUser] = yield select((state) => getMany(state, getIntegrationUser))
    if (isStreamAUserTag(streamId) || isStreamASystemTag(streamId, true)) {
      const storyIds = stories.map((s) => s.id)
      const storyIdAsDict = convertArrayToDict(storyIds)
      yield put(actions.storeStories.success({ storyIdAsDict: storyIdAsDict, replace, streamId }))
      yield call(dbService.saveTaggedStoryIds, { ids: storyIds, integrationUser, type: streamId, replace })
    }
  }),
  [actions.cleanTaggedStories]: handleSaga(takeLatest, function* ({ payload }) {
    const [integrationUser] = yield select((state) => getMany(state, getIntegrationUser))
    yield call(dbService.clearTaggedStoriesForATag, { integrationUser, streamIdsToKeep: payload.tagIds })
  }),
  [actions.addTagToStory]: handleSaga(takeLatest, function* ({ payload }) {
    const { story, tag } = payload
    const [integrationUser] = yield select((state) => getMany(state, getIntegrationUser))
    yield call(dbService.saveTaggedStoryIds, { ids: [story.id], integrationUser, type: tag.id, replace: false })
    console.log('Push tags to server.....')
    yield serviceEffext(API_OPERATIONS.ADD_TAGS_To_STORIES, {
      storyIds: [story.id],
      tagIds: [tag.id],
    })
  }),
  [actions.removeTagfromStory]: handleSaga(takeLatest, function* ({ payload }) {
    const { story, tag } = payload
    const [integrationUser] = yield select((state) => getMany(state, getIntegrationUser))
    yield call(dbService.removeTagFromStoryId, { storyId: story.id, integrationUser, type: tag.id })
    console.log('remove tags on server.....')
    yield serviceEffext(API_OPERATIONS.REMOVE_TAGS_FROM_STORIES, {
      storyIds: [story.id],
      tagIds: [tag.id],
    })
  }),

  [actions.createNewTag.request]: handleSaga(takeLatest, function* ({ payload }) {
    const { data, callback } = payload
    // push or server
    const result = yield serviceEffext(API_OPERATIONS.CREATE_NEW_TAG, data)
    callback && callback(true, result.message, result.data)
    const tag = result.data
    // update redux
    yield put(actions.createNewTag.success({ tag }))
    const [integrationUser] = yield select((state) => getMany(state, getIntegrationUser))
    // add to local database
    dbService.upsertTags({ integrationUser, tags: [tag] })
  }),
}
