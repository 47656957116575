import { useDispatch } from 'react-redux'
import { actions as appActions } from 'APP/components/Models/app'
import { actions as languageActions } from 'APP/components/Models/language'
import { actions as UiBlockerActions } from 'APP/components/UiBlocker/model'
import { actions as TagActions } from 'APP/components/Models/tags'
import { actions as dialogActions } from 'APP/components/Dialog/model'

export const useGetActions = () => {
  const dispatch = useDispatch()
  const initializeApp = () => dispatch(appActions.initialize.request())
  const changeLanguage = (...props) => dispatch(languageActions.change(...props))
  const saveUIState = (...props) => dispatch(appActions.saveUIState(...props))
  const loadDashboard = (...props) => dispatch(appActions.loadDashboard(...props))
  const blockUI = () => dispatch(UiBlockerActions.blockUI())
  const unBlockUI = () => dispatch(UiBlockerActions.unBlockUI())
  const syncApp = () => dispatch(appActions.sync())
  const integrationUserChanged = () => dispatch(appActions.integrationUserChanged())
  const showPopup = (...props) => dispatch(dialogActions.showPopup(...props))
  const hidePopup = () => dispatch(dialogActions.hidePopup())
  const createNewTag = (...props) => dispatch(TagActions.createNewTag.request(...props))
  return { initializeApp, changeLanguage, saveUIState, loadDashboard, unBlockUI, blockUI, syncApp, integrationUserChanged, showPopup, hidePopup, createNewTag }
}

// export const useIntegrationUser = (fn)=>{
//   const intUserIndex = useSelector(s=>s.appState.uiState.integrationUserIndex)
//   const intUser = useSelector(s=>s.integrationUsers[intUserIndex])
//   console.log('intUserIndex',intUserIndex);
//   console.log('intUserIndex',intUserIndex);
//   return fn(intUser)
// }

// export const useIntegrationUser = (fn)=>{
//   const intUserIndex = useSelector(s=>s.appState.uiState.integrationUserIndex)
//   const intUser = useSelector(s=>s.integrationUsers[intUserIndex])
//   return {} || intUser
// }
