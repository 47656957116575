import styled from 'styled-components'

const Component = styled.div`
  bottom: 0px;
  left: ${(p) => p.left || 0}px;
  right: 0px;
  position: absolute;
  ${(p) => p.theme.fonts.small};
  background-color: ${(p) => p.theme.messageBarBgColors[p.type]};
  color: ${(p) => p.theme.messageBarFontColors[p.type]};
  padding-left: 16px;
  height: 20px;
  display: flex;
  align-items: center;
`
export default Component
