import { handleActions } from 'redux-actions'
import produce from 'immer'
import { create3Actions, handleSaga } from 'APP/utils/reduxUtils'
import { takeLatest, put } from 'redux-saga/effects'
import { serviceEffext, API_OPERATIONS } from 'APP/services/serviceEffext'

const MODULE_NAME = 'ONBOARDING_RECOMMENDATIONS'
const createActions = create3Actions(MODULE_NAME)

export const actions = {
  fetchRecommendations: createActions('fetchRecommendations'),
}

const initialState = {
  recommendations: [],
}

export const reducer = handleActions(
  {
    [actions.fetchRecommendations.success]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.recommendations = payload
      }),
  },
  initialState
)

export const rootSaga = {
  [actions.fetchRecommendations.request]: handleSaga(takeLatest, function* () {
    const response = yield serviceEffext(API_OPERATIONS.GET_ONBOARDING_RECOMMENDATIONS)
    yield put(actions.fetchRecommendations.success(response.data))
  }),
}
