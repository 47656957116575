import React from 'react'
import UIBlocker from 'APP/ui-kit/UIBlocker'

const Component = ({ enabled, zIndex }) => {
  if (enabled) {
    return <UIBlocker zIndex={zIndex} />
  }
  return null
}

export default Component
