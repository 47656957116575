import styled from 'styled-components'

const Container = styled.div`
  padding: 0px 10px;
  display: flex;
  flex: 1;
  height: 100vh;
  width: 100vw;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: #0063b1;
`

const AppTitle = styled.div`
  color: #fff;
  font-size: 60px;
  margin-bottom: 24px;
  ${(p) => p.theme.fonts.superLarge};
  @media (max-width: 500px) {
    ${(p) => p.theme.fonts.xxLargePlus};
  }
`

const ProgressOuter = styled.div`
  max-width: 400px;
  height: 2px;
  margin-top: 2px;
  border-radius: 1px;
  width: 100%;
  background: #004593;
  @media (max-width: 500px) {
    max-width: 250px;
  }
`

const ProgressInner = styled.div`
  background: #9db5d5;
  height: 100%;
  border-radius: 1px;
  width: ${(p) => p.width}%;
`

export const Message = styled.div`
  position: absolute;
  bottom: 32px;
  color: #eee;
  font-size: 12px;
`

export { Container, ProgressOuter, ProgressInner, AppTitle }
