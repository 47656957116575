import Entity from './Entity'

class Cache extends Entity {
  static entityName = 'cache'
  key
  data
  static getSchema() {
    return {
      key: 'string',
      data: 'object',
    }
  }
}

export default Cache
