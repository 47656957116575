import { last, first, dropRight } from 'APP/utils/lodash'
import { SYSTEM_TAGS } from 'APP/utils/enums'

export const wrapSubscriptionTitleName = (name = '') => {
  if (name.length >= 24) {
    let words = name.split(' ')
    let lastWord = last(words).split('-')
    name = `${first(words)}....${last(lastWord)}`
  }
  return name
}

export const isOlderThanXDays = (story, days) => story.published < Date.now() - days * 24 * 60 * 60 * 1000

export const formatTime = (time) => {
  const diff = (Date.now() - new Date(time)) / 1000
  if (diff < 5 * 60) {
    return { key: 'MOMENTS_AGO' }
  } else if (diff < 60 * 60) {
    return { key: 'DIF_MINUTES', time: Math.ceil(diff / 60), isPlural: true }
  } else if (diff < 60 * 60 * 24) {
    const t = Math.floor(diff / (60 * 60))
    return { key: 'DIF_HOURS', time: t, isPlural: t > 1 }
  } else if (diff < 60 * 60 * 24 * 30) {
    const t = Math.floor(diff / (60 * 60 * 24))
    return { key: 'DIF_DAYS', time: t, isPlural: t > 1 }
  } else if (diff < 60 * 60 * 24 * 365) {
    const t = Math.floor(diff / (60 * 60 * 24 * 30))
    return { key: 'DIF_MONTHS', time: t, isPlural: t > 1 }
  } else {
    const t = Math.floor(diff / (60 * 60 * 24 * 365))
    return { key: 'DIF_YEARS', time: t, isPlural: t > 1 }
  }
}

export const wrapStoryTitle = (text = '', length = 110) => {
  if (text.length < length) {
    return text
  } else {
    return `${dropRight(text.slice(0, length).split(' '), 1).join(' ')}...`
  }
}

/**
 * Feedly utility
 */
const isEnclosureAnImage = (item) => {
  //Only href defined assume its images
  if (item.href && !item.type) {
    return true
  }
  if (item.href && item.type && item.type.substr(0, 5) === 'image') {
    return true
  }
  return false
}

export const getStoryThumbnail = (story) => {
  let { thumbnail, visual, enclosure } = story

  //check in visual
  if (visual && visual.url && visual.url !== 'none') {
    return visual.edgeCacheUrl || visual.url
  }

  //First: check in thumbnail
  if (thumbnail && thumbnail.length > 0 && thumbnail[0].url && thumbnail[0].url !== 'none') {
    return thumbnail[0].url
  }

  //loop in enclosure
  if (enclosure && enclosure.length > 0) {
    for (let i = 0; i < enclosure.length; i++) {
      let item = enclosure[i]
      if (isEnclosureAnImage(item)) {
        return item.href
      }
    }
  }
}

export const isMobileBrowser = () => {
  if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
    return true
  }
  return false
}

export const getShortName = (fullName = '', numberOfCharacters = 10) => {
  return (fullName.split(' ')[0] || '').slice(0, numberOfCharacters)
}

export const getShortFullName = (fullName = '', numberOfCharacters = 10) => {
  return fullName.slice(0, numberOfCharacters)
}

export const formatStringForUrl = (str = '') => {
  const charsToIgnore = ['|', ' ', '#', '/', '\\', '?', '*']
  return charsToIgnore.reduce((a, char) => {
    return a.split(char).join('-')
  }, str.toLowerCase())
}

export const removeEndSlashFromStr = (str = '') => {
  if (last(str) === '/') {
    return str.slice(0, -1)
  }
  return str
}

export const formatUnreadCount = (count) => {
  if (!count || isNaN(Number(count)) || count < 0) {
    return ''
  }
  if (count > 999) {
    return '1K+'
  }
  return count
}

export const getTimeXDaysAgo = (n = 0) => Date.now() - n * 24 * 60 * 60 * 1000

export const delay = async function (num) {
  return new Promise((resolve, reject) => {
    setTimeout(resolve, num)
  })
}

export const isStreamAUserTag = (streamId = '') => {
  return streamId.indexOf('user/') > -1 && streamId.indexOf('/tag/') > -1 && streamId.indexOf('/tag/global.saved') < 0 && streamId.indexOf('/tag/global.favourite') < 0
}

export const isStreamASystemTag = (streamId = '', ignoreALLTag = true) => {
  if (ignoreALLTag && streamId === SYSTEM_TAGS.ALL.id) {
    return false
  }
  if (streamId.indexOf('/tag/global.saved') > -1 || streamId.indexOf('/tag/global.favourite') > -1) {
    return true
  }
  return !!SYSTEM_TAGS[streamId]
}

export const produceVersionKey = (config) => {
  const { appVersion, buildNumber, platform } = config
  return `${platform}-${appVersion}-${buildNumber}`
}

export const getUTCMillisecondsNow = () => {
  var now = new Date()
  var utcTime = Date.now() + now.getTimezoneOffset() * 60000
  return utcTime
}

export const stringToHashCodeString = function (s) {
  let code = s.split('').reduce(function (a, b) {
    a = (a << 5) - a + b.charCodeAt(0)
    return a & a
  }, 0)
  if (code < 0) {
    code = code * -1
    code = 'x' + code
  }
  return code + 'a'
}
