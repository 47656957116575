import { put, takeLatest, call } from 'redux-saga/effects'
import { handleSaga, create3Actions } from 'APP/utils/reduxUtils'
import dbService from 'APP/services/dbZervice'
import { actions as AppActions } from 'APP/components/Models/app'
import { Platform } from 'APP/app/resources'
import { API_OPERATIONS, serviceEffext } from 'APP/services/serviceEffext'

const MODULE_NAME = 'WELCOME_SCREEN'
const createActions = create3Actions(MODULE_NAME)

/* ------------- Actions ------------- */
export const actions = {
  register: createActions('register'),
  login: createActions('login'),
  forgotPassword: createActions('forgotPassword'),
}

/* ------------- Root Saga ------------- */
export const rootSaga = {
  [actions.register.request]: handleSaga(takeLatest, function* ({ payload }) {
    const { data: user, callback } = payload
    const result = yield serviceEffext(API_OPERATIONS.CREATE_USER, user, { doNotRefreshToken: true })
    callback && callback(true, result.message, result.data)
  }),
  [actions.login.request]: handleSaga(takeLatest, function* ({ payload }) {
    const { credentials } = payload

    console.log('Verifying user credentials...')
    const result = yield serviceEffext(API_OPERATIONS.AUTHENTICATE, credentials, { doNotRefreshToken: true, isLoginRequest: true })
    console.log('Get /me?complete=yes')
    const accessToken = result.data.accessToken
    const me = yield serviceEffext(API_OPERATIONS.GET_ME, { complete: 'yes' }, { accessToken, doNotRefreshToken: true })

    console.log('Store user and integration user on device')
    const deviceId = Platform.getDeviceId()
    const { user, integrationUsers } = me.data
    const [firstIntUser, ...restIntUsers] = integrationUsers
    yield call(dbService.onBoardUser, { user: { ...user, accessToken }, integrationUser: firstIntUser, deviceId })
    for (let intUser of restIntUsers) {
      yield call(dbService.storeIntegrationUser, { integrationUser: intUser })
    }

    console.log('Load Dashboard')
    yield put(AppActions.loadDashboard())
  }),
  [actions.forgotPassword.request]: handleSaga(takeLatest, function* ({ payload }) {
    const { data, callback, token } = payload
    const result = yield serviceEffext(API_OPERATIONS.FORGOT_PASSWORD, data, { doNotRefreshToken: true, accessToken: token })
    callback && callback(true, result.message, result.data.token)
  }),
}
