import feedlySyncManager from './feedly'
import foundrySyncManager from './foundry'
import { INTEGRATION_CODES } from 'APP/utils/enums'

const apis = {
  [INTEGRATION_CODES.FEEDLY]: feedlySyncManager,
  [INTEGRATION_CODES.FOUNDRY]: foundrySyncManager,
}

export const getSyncManager = (integrationCode) => apis[integrationCode]
