import { APP_ENVS, PLATFORMS, WEB_BROWSERS } from 'APP/utils/enums'
import { isMobileBrowser } from 'APP/utils/common'
import { Platform } from 'APP/app/resources'

// Base config
const config = {
  env: process.env.NODE_ENV,
  appEnv: process.env.APP_ENV,
  isDevelopment: process.env.APP_ENV === APP_ENVS.development,
  isUAT: process.env.APP_ENV === APP_ENVS.uat,
  isProduction: process.env.APP_ENV === APP_ENVS.production,
  isLocalHost: process.env.LOCALHOST === 'yes',
  platform: process.env.VEEN_APP_PLATFORM,
  isWeb: process.env.VEEN_APP_PLATFORM === PLATFORMS.web,
  isMac: process.env.VEEN_APP_PLATFORM === PLATFORMS.mac,
  isWin: process.env.VEEN_APP_PLATFORM === PLATFORMS.win,
  isWinJS: process.env.VEEN_APP_PLATFORM === PLATFORMS.winjs,
  appVersion: process.env.APP_VERSION,
  buildNumber: process.env.BUILD_NUMBER,
  RELAY_SERVER_URL: 'https://dev.api.veenreader.com/api/v1/utils/relay',
  LEGATO_SERVER_URL: 'https://dev.api.veenreader.com',
  reduxLoggerEnabled: false,
  deviceType: process.env.VEEN_APP_PLATFORM === PLATFORMS.web ? (isMobileBrowser() ? WEB_BROWSERS.mobBrowser : WEB_BROWSERS.browser) : process.env.VEEN_APP_PLATFORM,
  clientId: Platform.getDeviceId(),
}

export default config
