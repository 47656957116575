import { handleActions } from 'redux-actions'
import { takeLatest, takeEvery, call, put, select } from 'redux-saga/effects'
import produce from 'immer'
import db from 'APP/services/dbZervice'
import { createAction, handleSaga, create3Actions } from 'APP/utils/reduxUtils'
import { calculateSelectedSubscriptionIds } from 'APP/utils/formatter'
import { differenceBy, orderBy, uniqBy, filter, get, sortBy } from 'APP/utils/lodash'
import { findIndex } from 'APP/utils/lodash'
import { delay, removeEndSlashFromStr, getTimeXDaysAgo, stringToHashCodeString } from 'APP/utils/common'
import { API_OPERATIONS, serviceEffext } from 'APP/services/serviceEffext'
import { getIntegrationUser, getUIState, getStories, getMany, getSubscriptions, getStoryIdsOfATag, produceStoryMeta } from 'APP/utils/reduxSelectors'
import { actions as SubscriptionListActions } from 'APP/components/SubscriptionList/model'
import { actions as IntegrationUsersActions } from 'APP/components/Models/integrationUsers'
import { actions as MarkerActions } from 'APP/components/Models/markers'
import { runSaga } from 'APP/utils/reduxUtils'
import * as TagsModel from 'APP/components/Models/tags'
import { SYSTEM_TAGS, STORY_LIST_STATUS, PLATFORMS, CACHE_KEYS } from 'APP/utils/enums'
import { Platform } from 'APP/app/resources'
import { updateProgressMessage, updateSuccessMessage, updateErrorMessage } from 'APP/components/MessageBar/model'

const MODULE_NAME = 'stories'
const createActions = create3Actions(MODULE_NAME)

const LOAD_STORIES_COUNT = 100
const LOAD_STORIES_SCROLL_COUNT = 150
const FETCH_STORIES_SCROLL_COUNT = 30
const FETCH_STORIES_SYNC_COUNT_BATCH_1 = 20
const FETCH_STORIES_SYNC_COUNT_AFTER_BATCH1 = 500
const STORIES_TO_SYNC_FOR_ACCOUNT_QUICK_FETCH = 20
const STORIES_TO_SYNC_FOR_ACCOUNT = 500 //1500
const STORIES_COUNT_TO_SYNC_FOR_A_SUBSCRIPTION = 500 //1500

export const actions = {
  loadStories: createActions('loadStories'),
  syncAccount: createActions('syncAccount'),
  toggleStoryActivity: createActions('toggleStoryActivity'),
  copyLink: createAction('copyLink'),
  move: createAction(MODULE_NAME, 'MOVE'),
  selectStory: createAction(MODULE_NAME, 'Select story'),
  updateStoriesInUI: createAction(MODULE_NAME, 'updateStoriesInUI'),
  fetchStoriesForSubscriptions: createActions('fetchStoriesForSubscriptions'),
  fetchStoriesOfAStream: createActions('fetchStoriesOfAStream'),
  syncAll: createActions('syncAll'),
  syncOne: createActions('syncOne'),
  addToExisting: createAction(MODULE_NAME, 'addToExisting', 'add stories to existing list'),
  updateListStatus: createAction(MODULE_NAME, 'updateListStatus', 'update list status'),
}

const initialStateStoriesMeta = {}
export const storiesMetaReducer = handleActions(
  {
    [actions.toggleStoryActivity.request]: (state, { payload }) =>
      produce(state, (draft) => {
        const { story, storyMeta = {}, activityName } = payload
        const isRemove = storyMeta[activityName]
        if (isRemove) {
          delete draft[story._id][activityName]
        } else {
          if (!draft[story._id]) {
            draft[story._id] = {}
          }
          draft[story._id][activityName] = Date.now()
        }
      }),
  },
  initialStateStoriesMeta
)

const initialStateStories = {
  stories: [],
  listStatus: STORY_LIST_STATUS.NOT_READY,
}

export const storiesReducer = handleActions(
  {
    [actions.loadStories.request]: (state, { payload }) =>
      produce(state, (draft) => {
        const { afterStory } = payload || {}
        // If after story not passed, we can assume, we need to refresh the list
        if (!afterStory) {
          draft.stories = []
          draft.listStatus = STORY_LIST_STATUS.NOT_READY
        }
      }),
    [actions.updateStoriesInUI]: (state, { payload }) =>
      produce(state, (draft) => {
        const { stories } = payload
        const allStories = draft.stories.concat(stories)
        return { ...draft, stories: sanitizeStories(allStories) }
      }),
    [actions.updateListStatus]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.listStatus = payload
      }),
  },
  initialStateStories
)

export const rootSaga = {
  [actions.loadStories.request]: handleSaga(takeLatest, function* ({ payload }) {
    console.log('Loading stories from DB....')
    const { afterStory } = payload || {} //load stories after this one
    if (afterStory) {
      yield put(actions.updateListStatus(STORY_LIST_STATUS.LAZY_LOAD_FROM_DB))
    }
    const [integrationUser, uiState] = yield select((state) => getMany(state, getIntegrationUser, getUIState))
    const pageSize = afterStory ? LOAD_STORIES_SCROLL_COUNT : LOAD_STORIES_COUNT
    const stories = yield call(db.findStoriesFromUIState, { integrationUser, uiState, afterStory, pageSize })
    const storiesForUI = yield filterStoriesForUI(stories)
    yield put(actions.updateStoriesInUI({ stories: storiesForUI, integrationUser }))
    yield put(actions.updateListStatus(STORY_LIST_STATUS.IDLE))

    // If Stories fetched from DB is less than page size get data from server
    if ((stories.length !== pageSize && afterStory) || stories.length < 15) {
      console.log('Stories in DB ended. Fetch from server...')
      yield put(actions.updateListStatus(STORY_LIST_STATUS.LAZY_LOAD_FROM_SERVER))
      const { streamId } = uiState
      yield put(actions.fetchStoriesOfAStream.request({ streamId, count: FETCH_STORIES_SCROLL_COUNT, onCompleteStatus: STORY_LIST_STATUS.IDLE, type: 'scroll_fetch' }))
    }
  }),
  [actions.fetchStoriesOfAStream.request]: handleSaga(
    takeEvery,
    function* ({ payload: { type, streamId, count, enableQuickFetch = false, onCompleteStatus, statusBar, messageBarStatusId: statusId } }) {
      const [integrationUser, subscriptions] = yield select((state) => getMany(state, getIntegrationUser, getSubscriptions))

      const getAllStreamKey = () => {
        const allKeys = stringToHashCodeString(
          sortBy(subscriptions, ['id'])
            .map((s) => s.id.split('feed/')[1])
            .reduce((a, c) => `${a}${c}`, '')
        )
        return `${integrationUser._id}${allKeys}`
      }
      const streamTimeKey = (streamId === 'ALL' ? getAllStreamKey() : streamId).split('.').join('_')
      const showProgressSt = get(statusBar, 'progress')
      const showSuccessSt = get(statusBar, 'success')
      const showFailureSt = get(statusBar, 'failure')
      const expiresIn = 3 /// for message bar
      const progressMessage = get(statusBar, 'messageKeys.progress', {})
      const successMessage = get(statusBar, 'messageKeys.success', {})
      const errorMessage = get(statusBar, 'messageKeys.fail', {})
      const defaultMessages = {
        progress: 'SYNCING_STORIES_WITH_BATCH_NUMBER',
        sucess: 'SUCCESS',
        fail: 'FAIL',
      }

      // let times = localStorage.getItem('times') ? JSON.parse(localStorage.getItem('times')) : {}
      let timesRow = yield call(db.getCacheValue, { key: CACHE_KEYS.SYNC_TIMES })
      let times = timesRow ? timesRow.data : {}
      times[streamTimeKey] = times[streamTimeKey] || {}

      try {
        if (type === 'scroll_fetch') {
          const continuation = times[streamTimeKey]['lastStoryTime'] || Date.now()
          const response = yield serviceEffext(API_OPERATIONS.GET_STREAM_STORIES, { streamId, count, continuation })
          const stories = get(response, 'data.items', [])
          if (stories.length > 0) {
            yield runSaga(TagsModel, TagsModel.actions.storeStories.request)({ payload: { streamId, stories, replace: false } })
            const storiesForUI = yield filterStoriesForUI(stories)
            yield put(actions.updateStoriesInUI({ stories: storiesForUI, integrationUser }))
            console.log('save stories to DB (note: they are already formatted by dataFormatter)')
            yield call(db.addStories, { stories })
            const lastStory = stories.slice(-1)[0]
            times[streamTimeKey]['lastStoryTime'] = lastStory.published
            // localStorage.setItem('times', JSON.stringify(times))
            yield call(db.setCache, { key: CACHE_KEYS.SYNC_TIMES, value: times })
          }
        } else {
          // assuming full sync
          const startTime = Date.now()
          let continuation = Date.now()
          const days = Platform.getName() === PLATFORMS.web ? 1 : 5
          const newerThan = times[streamTimeKey]['lastSyncTime'] || getTimeXDaysAgo(days)
          let batch = 1
          while (batch === 1 || !!continuation) {
            if (showProgressSt) {
              yield updateProgressMessage(progressMessage.key || defaultMessages.progress, statusId, undefined, [batch, progressMessage.data])
            }

            const batchCount = count ? count : batch === 1 && enableQuickFetch ? FETCH_STORIES_SYNC_COUNT_BATCH_1 : FETCH_STORIES_SYNC_COUNT_AFTER_BATCH1
            const response = yield serviceEffext(API_OPERATIONS.GET_STREAM_STORIES, { streamId, count: batchCount, continuation, newerThan })
            const stories = get(response, 'data.items', [])
            continuation = get(response, 'data.continuation', null)
            if (stories.length > 0) {
              yield runSaga(TagsModel, TagsModel.actions.storeStories.request)({ payload: { streamId, stories, replace: batch === 1 } })
              const storiesForUI = yield filterStoriesForUI(stories)
              yield put(actions.updateStoriesInUI({ stories: storiesForUI, integrationUser }))
              console.log('save stories to DB (note: they are already formatted by dataFormatter)')
              yield call(db.addStories, { stories })
              const lastStory = stories.slice(-1)[0]
              times[streamTimeKey]['lastStoryTime'] = lastStory.published
            }
            batch++
          }
          times[streamTimeKey]['lastSyncTime'] = startTime
          // localStorage.setItem('times', JSON.stringify(times))
          yield call(db.setCache, { key: CACHE_KEYS.SYNC_TIMES, value: times })
        }
        if (onCompleteStatus) {
          yield put(actions.updateListStatus(onCompleteStatus))
        }
        if (showSuccessSt) {
          yield updateSuccessMessage(successMessage.key || defaultMessages.sucess, statusId, expiresIn, successMessage.data)
        }
      } catch (error) {
        if (onCompleteStatus) {
          yield put(actions.updateListStatus(onCompleteStatus))
        }
        if (showFailureSt) {
          const failMessage = error.message || errorMessage.key || defaultMessages.fail
          yield updateErrorMessage(failMessage, statusId, expiresIn, errorMessage.data)
        }
        throw error
      }
    }
  ),
  [actions.fetchStoriesForSubscriptions.request]: handleSaga(
    takeLatest,
    function* ({ payload: { subscriptions, startTime = Date.now(), olderThanStory = null, count } }) {
      if (olderThanStory) {
        yield put(actions.lazyLoadingStories.request())
      }
      const [integrationUser] = yield select((state) => getMany(state, getIntegrationUser))
      const newestStory = yield call(db.findOneStoryOfASubscriptions, { sort: -1, subscriptionIds: subscriptions.map((s) => s.id) })
      const isFirstSync = !newestStory
      const newerThan = olderThanStory ? undefined : isFirstSync ? 111111 : newestStory.published - 1
      console.log('Fetch stories...')
      let batch = 1,
        continuation = olderThanStory ? olderThanStory.published : undefined
      while (batch === 1 || !!continuation) {
        let extraOptions = {}
        if (continuation) {
          extraOptions = {
            continuation,
          }
        }

        // Todo: Pass array not 1 item
        const {
          data: { items: stories, continuation: cont },
        } = yield serviceEffext(API_OPERATIONS.GET_SUBSCRIPTION_STORIES, {
          subscription: subscriptions[0],
          count: count || STORIES_COUNT_TO_SYNC_FOR_A_SUBSCRIPTION,
          newerThan,
          ranked: 'newest',
          ...extraOptions,
        })
        console.log('Save stories in redux')
        const storiesForUI = yield filterStoriesForUI(stories)
        yield put(actions.updateStoriesInUI({ stories: storiesForUI, integrationUser }))
        console.log('save stories to DB (note: they are already formatted by dataFormatter)')
        yield call(db.addStories, { stories })
        !isFirstSync && !olderThanStory && (continuation = cont)
        olderThanStory && (continuation = null)
        batch++
      }

      if (!olderThanStory) {
        for (let sub of subscriptions) {
          yield put(SubscriptionListActions.updateLastSuccessSyncTime({ startTime, subscription: sub }))
        }
      }

      if (olderThanStory) {
        yield put(actions.lazyLoadingStories.success())
      }
    }
    // {
    //   generic: true,
    //   progressMessageProducer: ({ payload: { subscription } }) => ({ message: 'SYNC_SUBSCRIPTION', langData: [subscription.title] }),
    // }
  ),
  [actions.syncAccount.request]: handleSaga(
    takeLatest,
    function* ({ payload: { startTime = Date.now() } }) {
      const integrationUser = yield select(getIntegrationUser)
      const newerThan = integrationUser.lastSuccessAccountSyncTime || 111111
      const isFirstSync = !integrationUser.lastSuccessAccountSyncTime

      // Code block for quick stories
      console.log(`Fetch Quick stories: ${STORIES_TO_SYNC_FOR_ACCOUNT_QUICK_FETCH}`)
      const {
        data: { items: quickStories },
      } = yield serviceEffext(API_OPERATIONS.GET_ACCOUNT_STORIES, { count: STORIES_TO_SYNC_FOR_ACCOUNT_QUICK_FETCH, newerThan })
      console.log(`save quick stories to redux,  stories found: ${quickStories.length}`)
      const qucikStoriesForUI = yield filterStoriesForUI(quickStories)
      yield put(actions.updateStoriesInUI({ stories: qucikStoriesForUI, integrationUser }))
      console.log('Save quick stories in DB')
      yield call(db.addStories, { stories: quickStories })

      // Code block for FULL stories
      // if quick stories received is equal to quick fetch stories, then we will fetch more stories for the account
      if (quickStories.length === STORIES_TO_SYNC_FOR_ACCOUNT_QUICK_FETCH) {
        let batch = 1,
          continuation
        while (batch === 1 || !!continuation) {
          console.log(`Fetch full stories: ${STORIES_TO_SYNC_FOR_ACCOUNT}`)
          let extraOptions = {}
          if (continuation) {
            extraOptions = {
              continuation,
            }
          }
          const {
            data: { items: stories, continuation: cont },
          } = yield serviceEffext(API_OPERATIONS.GET_ACCOUNT_STORIES, { count: STORIES_TO_SYNC_FOR_ACCOUNT, newerThan, ...extraOptions })
          console.log(`save  stories to redux,  stories found: ${stories.length}`)
          const storiesForUI = yield filterStoriesForUI(stories)
          yield put(actions.updateStoriesInUI({ stories: storiesForUI, integrationUser }))
          console.log('Save  stories in DB')
          yield call(db.addStories, { stories: stories })
          !isFirstSync && (continuation = cont)
          // if not first sync then fetch more stories
          batch++
        }
      }
      // yield put(IntegrationUsersActions.updateLastSuccessSyncTime({ startTime, integrationUser, isFullSync: false }))
    },
    'SYNC_ACCOUNT'
  ),
  [actions.selectStory]: handleSaga(takeLatest, function* ({ payload }) {
    let { storyMeta, story, history } = payload
    if (!storyMeta) {
      const state = yield select((state) => state)
      storyMeta = produceStoryMeta(state, story)
    }
    const [url] = history.location.pathname.split('/story/')
    history && history.push(removeEndSlashFromStr(url) + '/story/' + story._id)

    // if story is not mark-as-read
    if (!storyMeta.isRead) {
      yield put(MarkerActions.markAsRead.request({ story, storyMeta }))
    }
  }),
  [actions.move]: handleSaga(takeEvery, function* ({ payload }) {
    //correction => Means how much you you want to move. If +1  means move 2 stories ahead from current story
    const { correction, history } = payload
    const stories = yield select(getStories)
    const { selectedStoryId } = yield select(getUIState)
    let selectedStoryIndex = findIndex(stories, { _id: selectedStoryId }) || 0
    selectedStoryIndex < 0 && (selectedStoryIndex = 0)
    let newStory = stories[selectedStoryIndex + correction]
    if (newStory) {
      yield put(actions.selectStory({ story: newStory, history }))
    }
  }),

  [actions.toggleStoryActivity.request]: handleSaga(takeLatest, function* ({ payload }) {
    const { story, storyMeta = {}, activityName } = payload
    const isRemove = storyMeta[activityName]
    const integrationUser = yield select(getIntegrationUser)
    const dbAction = isRemove ? db.deleteStoryMeta : db.addStoryMeta
    yield call(dbAction, { integrationUser, stories: [story], activityName })
  }),
  [actions.copyLink]: handleSaga(takeLatest, function* ({ payload }) {
    yield console.log('Copy link', payload)
    if (payload && payload.url) {
      Platform.copyToClipBoard(payload.url)
    }
  }),
}

function* filterStoriesForUI(stories = []) {
  const [subscriptions, uiState] = yield select((state) => getMany(state, getSubscriptions, getUIState, getIntegrationUser))
  const { selectedSubscriptionId, selectedFolderId, selectedTagId, streamId } = uiState
  const subIds = calculateSelectedSubscriptionIds({ selectedSubscriptionId, selectedFolderId }, subscriptions)
  // Tag dependent codes
  if ((selectedTagId && selectedTagId === SYSTEM_TAGS.ALL.id) || streamId === SYSTEM_TAGS.ALL.id) {
    return stories
  } else if (selectedTagId) {
    const ids = yield select((state) => getStoryIdsOfATag(state, selectedTagId))
    return stories.filter((s) => !!ids[s.id])
  } else {
    return stories.filter((s) => subIds.indexOf(s.subscriptionId) > -1)
  }
}

// Create a util that will be used by both load-stories, syncOne-Success, syncAccount-success
const sanitizeStories = (stories = [], options = {}, ignoreIds = [], filterStories = () => true) => {
  const { sortDir = 'desc', sortProp = 'published' } = options

  // filter
  stories = filter(stories, filterStories)

  // remove ignore ids
  stories = differenceBy(
    stories,
    ignoreIds.map((id) => ({ _id: id })),
    '_id'
  )

  // pick unique_id
  stories = uniqBy(stories, '_id')

  // sort
  stories = orderBy(stories, [sortProp], [sortDir])
  return stories
}

// [actions.fetchStoriesOfAStream.request]: handleSaga(
//   takeEvery,
//   function* ({ payload: { streamId, startTime = Date.now(), direction = 'forward', count, tillStreamEnd, oldestStory, newestStory, enableQuickFetch = false, onCompleteStatus } }) {
//     /**
//      * enableQuickFetch|false => If enabled first batch will pull only 20 times, next bacth will pull normal items
//      * direction|forward => forward/backward
//      * tillStreamEnd => If enabled it pull till the end of the stream
//      */
//     const [integrationUser, subscriptions] = yield select((state) => getMany(state, getIntegrationUser, getSubscriptions))

//     tillStreamEnd = typeof tillStreamEnd === 'boolean' ? tillStreamEnd : direction === 'forward'
//     if (direction === 'backward' && !oldestStory) {
//       oldestStory = yield call(db.findStoriesFromStream, { integrationUser, streamId, pageSize: 1, allSubscriptions: subscriptions, sort: 1 })
//       oldestStory = (oldestStory && oldestStory[0]) || { published: Date.now() }
//     }
//     if (direction === 'forward' && !newestStory) {
//       ///Todo, published should come from somehwre, May depend in Account. Published here basically means, the oldest time, you want to reach for this stream
//       newestStory = yield call(db.findStoriesFromStream, { integrationUser, streamId, pageSize: 1, allSubscriptions: subscriptions, sort: -1 })
//       newestStory = (newestStory && newestStory[0]) || { published: Date.now() - 3600 * 1000 * 24 * 3 }
//     }
//     const newerThan = direction === 'forward' ? newestStory.published : undefined
//     let continuation = direction === 'backward' ? oldestStory.published : undefined
//     let batch = 1
//     while (batch === 1 || !!continuation) {
//       let batchCount = count ? count : batch === 1 && enableQuickFetch ? FETCH_STORIES_SYNC_COUNT_BATCH_1 : FETCH_STORIES_SYNC_COUNT_AFTER_BATCH1
//       let extraOptions = continuation ? { continuation } : {}
//       const {
//         data: { items: stories, continuation: cont },
//       } = yield serviceEffext(API_OPERATIONS.GET_STREAM_STORIES, { streamId, count: batchCount, newerThan, ...extraOptions })
//       console.log('Save stories in redux, found:', stories.length)
//       // save tagged stories. It will automatically decide it has to save or not
//       yield runSaga(TagsModel, TagsModel.actions.storeStories.request)({ payload: { streamId, stories, batch } })

//       const storiesForUI = yield filterStoriesForUI(stories)
//       yield put(actions.updateStoriesInUI({ stories: storiesForUI, integrationUser }))
//       console.log('save stories to DB (note: they are already formatted by dataFormatter)')
//       yield call(db.addStories, { stories })
//       continuation = cont
//       !tillStreamEnd && (continuation = null)
//       batch++
//     }

//     if(onCompleteStatus){
//       yield put(actions.updateListStatus(onCompleteStatus))
//     }
//   },
//   {
//     progressMessageProducer: ({ payload: { statusBar } }) => (get(statusBar, 'progress') ? { message: 'SYNCING_STORIES' } : null),
//     successMessageProducer: ({ payload: { statusBar } }) => (get(statusBar, 'success') ? { message: 'SUCCESS' } : null),
//     failMessageProducer: ({ payload: { statusBar } }) => (get(statusBar, 'failure') ? { message: 'FAIL' } : null),
//   }
// ),

// [actions.syncOne.request]: handleSaga(
//   takeLatest,
//   function* ({ payload: { subscription, startTime = Date.now() } }) {
//     const [integrationUser] = yield select((state) => getMany(state, getIntegrationUser))
//     const newerThan = subscription.lastSuccessSyncTime

//     console.log('Fetch stories...')
//     const {
//       data: { items: stories },
//     } = yield serviceEffext(API_OPERATIONS.GET_SUBSCRIPTION_STORIES, { subscription, count: STORIES_COUNT_TO_SYNC_FOR_A_SUBSCRIPTION, newerThan, ranked: 'newest' })

//     console.log('Save stories in redux')
//     const storiesForUI = yield filterStoriesForUI(stories)
//     yield put(actions.updateStoriesInUI({ stories: storiesForUI, integrationUser }))

//     console.log('save stories to DB (note: they are already formatted by dataFormatter)')
//     yield call(db.addStories, { stories })
//     yield put(SubscriptionListActions.updateLastSuccessSyncTime({ startTime, subscription }))
//   },
//   {
//     generic: true,
//     progressMessageProducer: ({ payload: { subscription } }) => ({ message: 'SYNC_SUBSCRIPTION', langData: [subscription.title] }),
//   }
// ),

// [actions.syncAll.request]: handleSaga(takeLatest, function* ({ payload: { messageBarStatusId, startTime = Date.now() } }) {
//   // const subscriptions = yield select(getSubscriptions)
//   // // sync one subscription at a time
//   // for (let sub of subscriptions) {
//   //   yield rootSaga[actions.syncOne.request][1]({ payload: { subscription: sub, messageBarStatusId, startTime } })
//   // }
// }),
