import styled from 'styled-components'
import Background from 'APP/static/welcome-gradient.jpg'
import { Link } from 'react-router-dom'
import { media } from 'APP/themes/utils'

const FORMS_CONTAINER_WIDTH = 340

export const Container = styled.div`
  display: flex;
  flex: 1;
  background-color: #093531;
  background-image: url('${Background}');
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  display: flex;
  flex-direction: column;
  color: #fff;
`
export const Top = styled.div`
  height: 166px;
  padding-top: 16px;
  padding-left: 64px;
  ${media.sm`
      padding-top: 0px;
      padding-left: 0px;
  `}
`
export const Middle = styled.div`
  flex: 1;
  padding-left: 64px;
  display: flex;
  justify-content: center;
  ${media.sm`
      padding-left: 0px;
  `}
`
export const Bottom = styled.div`
  padding: 32px;
  color: #fff;
  width: 100%;
  display: flex;
`

export const Flower = styled.img`
  position: absolute;
  right: 0px;
  top: 0px;
`

export const Logo = styled.img`
  width: 150px;
  height: 150px;
`
export const LogoTitle = styled.div`
  width: 150px;
  text-align: center;
  margin-top: -52px;
`

export const TitleContainer = styled.div`
  flex: 1;
  padding-top: 80px;
  ${media.sm`
      display:none;
  `}
`

export const FormsContainer = styled.div`
  width: ${FORMS_CONTAINER_WIDTH + 64 + 64}px;
  max-width: 100vw;
`

export const Title = styled.div`
  ${(p) => p.theme.fonts.superLarge};
  text-transform: uppercase;
`
export const SubTitle = styled.div`
  ${(p) => p.theme.fonts.xxLargePlus};
  font-weight: 300;
  padding-top: 32px;
`

/////FORMS
export const HtmlFormContainer = styled.form`
  width: ${FORMS_CONTAINER_WIDTH}px;
  margin: 0px auto;
`

export const InputContainer = styled.div`
  margin-bottom: 20px;
`

export const Input = styled.input`
  ${(p) => p.theme.fonts.mediumPlus};
  height: 48px;
  width: 100%;
  background: transparent;
  border: 1px solid #c1d1da;
  color: #fff;
  padding-left: 16px;
  border-radius: 2px;
  outline: none;
  ${(p) =>
    p.error &&
    `
    border: 1px solid #ec6d64;
  `}
  &::placeholder {
    color: #aaa;
  }
  &:focus {
    border: 1px solid #fff;FormLabelsContainer
  }
  &:hover {
    ${(p) =>
      !p.error &&
      `
      border: 1px solid #fff;
    `}
  }
  &:disabled {
    border: 1px solid #788e9a;
    color: #999;
  }
`

export const InputError = styled.div`
  color: #ec6d64;
  font-style: italic;
  ${(p) => p.theme.fonts.mediumPlus};
`

export const Button = styled.button`
  border-radius: 2px;
  color: #16446c;
  background: #fff;
  width: 100%;
  height: 48px;
  ${(p) => p.theme.fonts.mediumPlus};
  font-weight: 600;
  border: none;
  outline: none;
  &:disabled {
    background: #bfc3c7;
  }
`

export const FormLabelsContainer = styled.div`
  margin: 0px auto;
  margin-bottom: 32px;
  width: ${FORMS_CONTAINER_WIDTH}px;
`

export const FormMessage = styled.div`
  margin: 0px auto;
  margin-bottom: 16px;
  width: ${FORMS_CONTAINER_WIDTH}px;
  ${(p) => p.theme.fonts.mediumPlus};
`

export const FormLabel = styled(Link)`
  ${(p) => p.theme.fonts.xLarge};
  color: #797e80;
  margin-right: 20px;
  padding-bottom: 12px;
  display: inline-block;
  ${(p) =>
    p.$active &&
    `
        color:#fff;
        border-bottom: 1px solid #fff;
    `}
  cursor:pointer;
`
export const ForgotPasswordContainer = styled.div`
  width: ${FORMS_CONTAINER_WIDTH}px;
  margin: 12px auto;
`

export const ForgotPassword = styled(Link)`
  color: #fff;
`
