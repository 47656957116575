import React from 'react'
import styled, { withTheme } from 'styled-components'
import { FontIcon } from 'APP/ui-kit/Vendor'
import { SYSTEM_TAGS } from 'APP/utils/enums'
import { ContextualMenu, IContextualMenuItem } from 'APP/ui-kit/Vendor'
import { useMessages } from 'APP/language'
import { find } from 'APP/utils/lodash'

const MENU_KEYS = {
  copyLink: 'COPY_LINK',
  toggleRead: 'TOGGLE_READ',
  webView: 'WEB_VIEW',
}

const getMenus = (onMenuItemClick, storyMeta, messages) => {
  const { isRead, isWebview } = storyMeta
  const menuItems: IContextualMenuItem[] = [
    {
      key: MENU_KEYS.copyLink,
      text: messages['COPY_LINK'],
      onClick: onMenuItemClick,
      iconProps: {
        iconName: 'Copy',
      },
    },
    {
      key: MENU_KEYS.toggleRead,
      text: messages[isRead ? 'MARK_AS_UNREAD' : 'MARK_AS_READ'],
      onClick: onMenuItemClick,
      iconProps: {
        iconName: isRead ? 'Mail' : 'Read',
      },
    },
    {
      key: MENU_KEYS.webView,
      text: messages[isWebview ? 'HIDE_WEB_VIEW' : 'WEB_VIEW'],
      onClick: onMenuItemClick,
      iconProps: {
        iconName: 'World',
      },
    },
  ]
  return menuItems
}

const Component = withTheme(({ story, parseArticle, storyMeta, copyLink, toggleWebView, theme, tags, removeTagfromStory, addTagToStory, systemTags, markAsRead, keepAsUnRead }) => {
  const linkRef = React.useRef(null)
  const messages = useMessages()
  const { isBookmarked, hasParsedArticle, isFavourite } = storyMeta
  const [showOverflowMenu, setShowOverflowMenu] = React.useState(false)

  const toggleOverflowMenu = () => setShowOverflowMenu(!showOverflowMenu)

  const handleConextMenuClick = (e, menuItem) => {
    menuItem.key === MENU_KEYS.copyLink && copyLink(story)
    menuItem.key === MENU_KEYS.webView && toggleWebView()
    if (menuItem.key === MENU_KEYS.toggleRead && storyMeta.isRead) {
      keepAsUnRead({ story })
    }
    if (menuItem.key === MENU_KEYS.toggleRead && !storyMeta.isRead) {
      markAsRead({ story })
    }
  }
  // These are system labels
  const laterTag = find(tags || [], { labelKey: SYSTEM_TAGS.LATER.id })
  const favouriteTag = find(tags || [], { labelKey: SYSTEM_TAGS.FAVOURITE.id })
  return (
    <Container style={{ display: 'flex' }}>
      {!!favouriteTag && (
        <Icon
          iconName={isFavourite ? 'FavoriteStarFill' : 'FavoriteStar'}
          onClick={() => {
            if (isFavourite) {
              removeTagfromStory({ story: story, tag: favouriteTag })
            } else {
              addTagToStory({ story: story, tag: favouriteTag })
            }
          }}
        />
      )}
      <Icon
        iconName={isBookmarked ? 'SingleBookmarkSolid' : 'SingleBookmark'}
        style={{
          color: theme.toolbarIconActiveColor,
        }}
        onClick={() => {
          if (isBookmarked) {
            removeTagfromStory({ story: story, tag: laterTag })
          } else {
            addTagToStory({ story: story, tag: laterTag })
          }
        }}
      />
      <Icon style={hasParsedArticle ? { color: theme.toolbarIconActiveColor } : { color: theme.toolbarIconInActiveColor }} iconName={'Lightbulb'} onClick={() => parseArticle(story)} />
      <div style={showOverflowMenu ? { color: theme.toolbarIconActiveColor, background: theme.subduedBackground } : {}} onClick={toggleOverflowMenu} ref={linkRef}>
        <Icon iconName={'MoreVertical'} />
      </div>
      <ContextualMenu
        items={getMenus(handleConextMenuClick, storyMeta, messages)}
        hidden={!showOverflowMenu}
        onItemClick={() => null}
        onDismiss={toggleOverflowMenu}
        target={linkRef}
        alignTargetEdge
      />
    </Container>
  )
})

const Icon = ({ style = {}, iconName, onClick = () => {} }) => (
  <Box onClick={onClick} style={style}>
    <FontIcon iconName={iconName} />
  </Box>
)

const Container = styled.div`
  display: flex;
  justify-content: flex-end;
`

const Box = styled.div`
  margin-left: 8px;
  width: 60px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  poistion: absolute;
  top: 0px;
  right: 0px;
  &:hover {
    color: ${(p) => p.theme.toolbarIconHoverColor};
  }
`
export default Component
