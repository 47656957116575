import React from 'react'
import styled, { withTheme } from 'styled-components'
import ResultCard from './ResultCard'
import SkeletonResult from './SkeletonResult'
import { range } from 'APP/utils/lodash'
import { useMessage } from 'APP/language'
import emptyImage from './empty.png'

const ResultsList = ({ results, handleSubscribe, selectedSubscription }) => (
  <ResultsListContainer>
    {results.map((r) => (
      <ResultsCardWrapper key={r.feedId}>
        <ResultCard selectedSubscription={selectedSubscription} result={r} handleSubscribe={(e) => handleSubscribe({ target: e.target, subscription: r })} />
      </ResultsCardWrapper>
    ))}
  </ResultsListContainer>
)

const SkeletonsList = () => (
  <ResultsListContainer>
    {range(12).map((i) => (
      <ResultsCardWrapper key={i}>
        <SkeletonResult />
      </ResultsCardWrapper>
    ))}
  </ResultsListContainer>
)

const NoResult = withTheme(({ theme }) => {
  const [NO_RESULTS_FOUND, TRY_ANOTHER_TERM] = useMessage('NO_RESULTS_FOUND', 'TRY_ANOTHER_TERM')
  return (
    <NoResultContainer>
      <img src={emptyImage} width="200px" alt="img" />
      <h1 style={{ color: theme.emptyStoryListTitleColor }}>{NO_RESULTS_FOUND}</h1>
      <div style={{ color: theme.emptyStoryListSubTitleColor, fontSize: 16 }}>{TRY_ANOTHER_TERM}</div>
    </NoResultContainer>
  )
})

const Component = ({ title, results = [], isLoading, handleSubscribe, selectedSubscription }) => (
  <Container>
    <Title>{title}</Title>
    {isLoading ? <SkeletonsList /> : results.length > 0 ? <ResultsList handleSubscribe={handleSubscribe} results={results} selectedSubscription={selectedSubscription} /> : <NoResult />}
  </Container>
)

const Container = styled.div`
  width: 95%;
  max-width: 1000px;
  margin-top: 16px;
`

const ResultsListContainer = styled.div`
  margin-top: 16px;
  display: flex;
  flex-wrap: wrap;
`
const ResultsCardWrapper = styled.div`
  flex: 1 0 30%;
  margin: 5px;
`

const NoResultContainer = styled.div`
  margin-top: 64px;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Title = styled.h3``

export default Component
