import { FontIcon } from 'office-ui-fabric-react/lib/Icon'
import { ContextualMenu, ContextualMenuItemType } from 'office-ui-fabric-react/lib/ContextualMenu'
import { Fabric } from 'office-ui-fabric-react/lib/Fabric'
import { PrimaryButton, DefaultButton, Button } from 'office-ui-fabric-react/lib/Button'
import { getTheme, initializeIcons, loadTheme } from 'office-ui-fabric-react'
import { Dialog, DialogType, DialogFooter } from 'office-ui-fabric-react/lib/Dialog'
import { TextField } from 'office-ui-fabric-react/lib/TextField'
import { Card, CardSection } from '@uifabric/react-cards'
import { Pivot as Tabs, PivotItem as TabItem, PivotLinkFormat, PivotLinkSize } from 'office-ui-fabric-react/lib/Pivot'
import { Toggle } from 'office-ui-fabric-react/lib/Toggle'
import { Dropdown } from 'office-ui-fabric-react/lib/Dropdown'
import { SearchBox } from 'office-ui-fabric-react/lib/SearchBox'
import { Shimmer, ShimmerElementType } from 'office-ui-fabric-react/lib/Shimmer'
import { Text } from 'office-ui-fabric-react/lib/Text'
import { ProgressIndicator } from 'office-ui-fabric-react/lib/ProgressIndicator'
import { Modal } from 'office-ui-fabric-react/lib/Modal'

Tabs.Item = TabItem
Tabs.Format = PivotLinkFormat
Tabs.Size = PivotLinkSize

export {
  Dialog,
  DialogType,
  DialogFooter,
  TextField,
  DefaultButton,
  PrimaryButton,
  Button,
  FontIcon,
  ContextualMenu,
  ContextualMenuItemType,
  Fabric,
  getTheme,
  initializeIcons,
  loadTheme,
  Card,
  CardSection,
  Tabs,
  Toggle,
  Dropdown,
  SearchBox,
  Shimmer,
  ShimmerElementType,
  Text,
  ProgressIndicator,
  Modal,
}
