import Entity from './Entity'

class User extends Entity {
  static entityName = 'users'

  email
  fullName
  username
  age

  static getSchema() {
    return {
      email: 'string',
      fullName: 'string',
      username: 'string',
    }
  }
}

export default User
