import React from 'react'
import styled from 'styled-components'
import TopBar from './Bar'
import NoStory from './NoStory'
import WebView from './Webview'
import ArticleView from './ArticleView'
import { useHistory } from 'react-router-dom'

const VIEW_KEYS = {
  articleView: 'articleView',
  webView: 'webView',
}

const VIEWS = {
  [VIEW_KEYS.articleView]: ArticleView,
  [VIEW_KEYS.webView]: WebView,
}

const Component = ({
  story,
  parseArticle,
  copyLink,
  toggleStoryActivity,
  storyMeta,
  loadParsedArticle,
  parsedArticle,
  moveStory,
  removeTagfromStory,
  addTagToStory,
  tags,
  systemTags,
  userTags,
  tagsWithStoryIds,
  markAsRead,
  keepAsUnRead,
}) => {
  const [storyViewerKey, setStoryViewerKey] = React.useState(VIEW_KEYS.articleView)
  const [isHandleKeyPress, setIsHandleKeyPress] = React.useState(false)
  const storyWrapperRef = React.useRef(null)
  const history = useHistory()

  React.useEffect(() => {
    loadParsedArticle(story)
    setStoryViewerKey(VIEW_KEYS.articleView)
    try {
      storyWrapperRef.current.scrollTo(0, 0)
    } catch (error) {
      try {
        storyWrapperRef.current.scrollTop = 0
      } catch (error) {}
    }
  }, [story])

  React.useEffect(() => {
    const handler = (e) => {
      if (isHandleKeyPress && (e.keyCode === 37 || e.keyCode === 39)) {
        e.preventDefault()
        let correction = 1
        e.keyCode === 37 && (correction = -1)
        moveStory({ correction, history })
      }
    }
    window.addEventListener('keydown', handler)
    return () => window.removeEventListener('keydown', handler)
  }, [isHandleKeyPress, moveStory])

  if (!story) {
    return <NoStory />
  }

  const StoryViewer = VIEWS[storyViewerKey]
  const isWebview = storyViewerKey === VIEW_KEYS.webView
  const toggleWebView = () => setStoryViewerKey(isWebview ? VIEW_KEYS.articleView : VIEW_KEYS.webView)
  return (
    <Container
      onMouseEnter={() => setIsHandleKeyPress(true)}
      onMouseLeave={() => setIsHandleKeyPress(false)}
      onClick={(e) => {
        e.preventDefault()
        e.target.href && window.open(e.target.href, '_blank')
      }}>
      <TopBar
        tags={tags}
        removeTagfromStory={removeTagfromStory}
        addTagToStory={addTagToStory}
        toggleWebView={toggleWebView}
        copyLink={copyLink}
        storyMeta={{ ...storyMeta, isWebview }}
        story={story}
        parseArticle={parseArticle}
        toggleStoryActivity={toggleStoryActivity}
        systemTags={systemTags}
        markAsRead={markAsRead}
        keepAsUnRead={keepAsUnRead}
      />
      <StoryWrapper ref={storyWrapperRef}>
        <StoryViewer
          story={story}
          parsedArticle={parsedArticle}
          storyMeta={storyMeta}
          tags={tags}
          systemTags={systemTags}
          userTags={userTags}
          removeTagfromStory={removeTagfromStory}
          addTagToStory={addTagToStory}
          tagsWithStoryIds={tagsWithStoryIds}
        />
        {/**  <AddUnit storyId={story._id} /> */}
        {/**<AddUnit key={story._id} storyId={story._id} /> */}
      </StoryWrapper>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`
const StoryWrapper = styled.div`
  overflow-y: auto;
  flex: 1;
`

export default Component
