import { Platform } from 'APP/app/resources'
import { find } from 'APP/utils/lodash'
import { calculateSelectedSubscriptionIds } from 'APP/utils/formatter'
import { isStreamAUserTag, isStreamASystemTag } from 'APP/utils/common'
import { SYSTEM_TAGS } from 'APP/utils/enums'

import Settings from './Db/models/Settings'
import Story from './Db/models/Story'
import StoryMeta from './Db/models/StoryMeta'
import User from './Db/models/User'
import IntegrationUser from './Db/models/IntegrationUser'
import Subscription from './Db/models/Subscription'
import ParsedArticle from './Db/models/ParsedArticle'
import Markers from './Db/models/Marker'
import Cache from './Db/models/Cache'
import Tags from './Db/models/Tags'

const ENTITIES = [Settings, Story, User, IntegrationUser, Subscription, ParsedArticle, StoryMeta, Markers, Cache, Tags]

const dbMethods = {
  /* ------------- SETTINGS:START ------------- */
  getSettings: async () => await Settings.findOne({}),
  updateSettings: async ({ user, props = {} }) => await Settings.patch({ userId: user._id }, props),
  /* ------------- SETTINGS:END ------------- */

  /* ------------- SUBSCRIPTIONS:START ------------- */
  getSubscriptions: async ({ integrationUser }) => await Subscription.find({ integrationUserId: integrationUser._id }),
  updateSubscription: async ({ subscription, props = {} }) => await Subscription.patch({ _id: subscription._id }, props),
  upsertSubscriptions: async ({ subscriptions = [] }) => await Subscription.save(subscriptions),
  deleteSubscriptions: async ({ integrationUser, subscriptions }) => Subscription.remove({ integrationUserId: integrationUser._id, id: { $in: subscriptions.map((s) => s.id) } }, true),
  clearIntUserSubscriptions: async ({ integrationUser }) => Subscription.remove({ integrationUserId: integrationUser._id }, true),
  /* ------------- SUBSCRIPTIONS:END ------------- */

  /* ------------- USER:START ------------- */
  getUser: async () => await User.findOne({}),
  getIntegrationUsers: async () => await IntegrationUser.findAll({}),
  getIntegrationUser: async ({ integrationUserIndex }) => await IntegrationUser.findByIndex({ integrationUserIndex }),
  /**
   * This method is invoked to store user,integrationUser.....other basic information after user logs in
   * */
  onBoardUser: async ({ user, integrationUser, deviceId }) => {
    try {
      const settings = new Settings()
      settings.userId = user._id
      settings.deviceId = deviceId
      await User.insert(user)
      await IntegrationUser.insert(integrationUser)
      await Settings.insert(settings)
      return true
    } catch (error) {
      // clear all three tables if something fails
      try {
        await User.truncate()
        await IntegrationUser.truncate()
        await Settings.truncate()
      } catch (error) {
        throw Error('UNKNOWN_ERROR')
      }
    }
  },
  /**
   * Upserts an already created integration user , if update happens then previous doc will be replaced with new
   * */
  storeIntegrationUser: async ({ integrationUser }) => await IntegrationUser.save(integrationUser),
  /**
   * Upsert, if update happens then previous doc will be replaced with new
   */
  updateUser: async ({ user, props = {} }) => await User.patch({ _id: user._id }, props),
  updateIntegrationUser: async ({ user, integrationUser, props = {} }) => await IntegrationUser.patch({ userId: user._id, _id: integrationUser._id }, props),

  /* -------------- USER:END -------------- */

  /* ------------- STORIES:START ------------- */
  /**
   * Upsert, if update happens then previous doc will be replaced with new
   */
  addStories: async ({ stories }) => await Story.save(stories),
  getStories: async ({ subscriptionIds, lastStory, pageSize }) => {
    let extra = {}
    if (lastStory) {
      extra = { published: { $lte: lastStory.published } }
    }
    let stories = await Story.find({ subscriptionId: { $in: subscriptionIds }, ...extra }, (q) => {
      q = q.sort({ published: -1 })
      q = q.limit(pageSize)
      return q
    })
    return stories
  },
  findOneStoryOfASubscriptions: async ({ subscriptionIds, sort = -1 }) => {
    // sort=1 for OLDest
    let story = await Story.findOne({ subscriptionId: { $in: subscriptionIds } }, (q) => {
      q = q.sort({ published: sort })
      q = q.limit(1)
      return q
    })
    return story
  },
  // getStoriesOfATag: async ({ integrationUser, tagName, sort }) => {
  //   const taggedStoriesIds = await StoryMexa.find({ integrationUserId: integrationUser._id, activityName: tagName })
  //   const tsids = taggedStoriesIds.map((s) => s.storyId)
  //   const stories = await Story.find({ _id: { $in: tsids } })
  //   return await stories
  // },
  findStoriesFromUIState: async ({ integrationUser, uiState, afterStory, pageSize, sort = -1 }) => {
    const subscriptions = await dbMethods.getSubscriptions({ integrationUser })
    const { selectedSubscriptionId, selectedFolderId, selectedTagId } = uiState
    let query = {}

    if (selectedSubscriptionId || selectedFolderId) {
      // If folder or subscription anything is selected, find applicable subscriptions
      const subscriptionIds = calculateSelectedSubscriptionIds({ selectedSubscriptionId, selectedFolderId }, subscriptions)
      query = { subscriptionId: { $in: subscriptionIds } }
    }
    // Tag dependent codes
    else if (selectedTagId && selectedTagId !== SYSTEM_TAGS.ALL.id) {
      // run this condition for all tags except ALL
      const tsids = await dbMethods.getTaggedStoryIdsForAStreamId({ integrationUser, streamId: selectedTagId })
      query = { id: { $in: tsids } }
    } else {
      // else return all stories
      query = { subscriptionId: { $in: subscriptions.map((s) => s.id) } }
    }

    if (afterStory) {
      query = { ...query, published: { $lte: afterStory.published } }
    }

    let stories = await Story.find(query, (q) => {
      q = q.sort({ published: sort })
      q = q.limit(pageSize)
      return q
    })
    return stories
  },
  findStoriesFromStream: async ({ integrationUser, streamId, afterStory, pageSize, sort = -1 }) => {
    let data = {}
    if (isStreamAUserTag(streamId) || isStreamASystemTag(streamId, false)) {
      data.selectedTagId = streamId
    } else if (streamId.indexOf('feed/') > -1) {
      data.selectedSubscriptionId = streamId
    } else if (streamId.indexOf('user/') > -1) {
      data.selectedFolderId = streamId
    }
    const stories = await dbMethods.findStoriesFromUIState({ integrationUser, uiState: data, afterStory, pageSize, sort })
    return stories
  },
  //@TODO: Strategize to make sure URL should become unique
  saveParsedArticle: async ({ url, data }) => await ParsedArticle.save({ url, data }),
  getParsedArticle: async ({ url }) => await ParsedArticle.findOne({ url }),
  /* -------------- STORIES:END -------------- */

  /* ------------- MARKERS:START ------------- */

  resetIntUserMarkers: async ({ integrationUser }) => {
    await Markers.remove({ integrationUserId: integrationUser._id }, true)
  },
  saveMarker: async ({ integrationUser, ids = [], type }) => {
    const propName = type === 'subscriptionPostions' ? 'subscriptionPostions' : 'storyIds'
    return await Markers.update({ integrationUserId: integrationUser._id, type: type }, { integrationUserId: integrationUser._id, type: type, [propName]: ids }, { upsert: true })
  },
  getMarkers: async ({ integrationUser }) => {
    const markers = await Markers.find({ integrationUserId: integrationUser._id })
    return (markers || []).reduce(
      (a, c) => {
        if (c.type === 'read') {
          a.readIds = a.readIds.concat(c.storyIds)
        } else if (c.type === 'unread') {
          a.unReadIds = a.unReadIds.concat(c.storyIds)
        } else if (c.type === 'subscriptionPostions') {
          a.subscriptionPostions = c.subscriptionPostions
        }
        return a
      },
      {
        readIds: [],
        unReadIds: [],
        subscriptionPostions: [],
      }
    )
  },
  getCounts: async ({ integrationUser, ignoreOlderThan }) => {
    let unreadCounts = {}
    const subscriptions = await dbMethods.getSubscriptions({ integrationUser })
    const { subscriptionPostions, readIds, unReadIds } = await dbMethods.getMarkers({ integrationUser })
    for (let sub of subscriptions) {
      let position = find(subscriptionPostions, { id: sub.id })
      const stories = await Story.find({ subscriptionId: sub.id, published: { $gte: ignoreOlderThan } })
      const unreadStories = (stories || []).filter((story) => {
        if (!position || story.published > position.asOf) {
          return readIds.indexOf(story.id) < 0
        }
        return unReadIds.indexOf(story.id) > -1

        // if (position && position.asOf > story.published) {
        //   unReadIds.indexOf(story.id) > -1
        // } else {
        //   readIds.indexOf(story.id) < 0
        // }
      })
      unreadCounts[sub.id] = unreadStories.length
    }
    return unreadCounts
  },
  deleteMarker: async ({ storyId, integrationUserId, type }) => {
    await Markers.update({ integrationUserId, type }, { $pull: { storyIds: storyId } }, { multi: true })
  },
  addMarker: async ({ storyId, integrationUserId, type }) => {
    await Markers.update({ integrationUserId, type }, { $push: { storyIds: storyId } }, { multi: true, upsert: true })
  },

  /* ------------- MARKERS:END ------------- */

  /* ------------- CACHE:START ------------- */
  setCache: async ({ key, value }) => {
    return await Cache.update({ key }, { key, data: value }, { upsert: true })
  },
  getCacheValue: async ({ key }) => {
    return await Cache.findOne({ key })
  },
  clearCache: async ({ key }) => {
    return await Cache.remove({ key }, { multi: true })
  },
  /* ------------- CACHE:END ------------- */

  /* ------------- TAGS-START ------------- */
  getTags: async ({ integrationUser }) => await Tags.find({ integrationUserId: integrationUser._id }),
  upsertTags: async ({ integrationUser, tags = [] }) => {
    for (let tag of tags) {
      let { _id, ...fTag } = tag
      await Tags.update(
        {
          id: tag.id,
          integrationUserId: tag.integrationUserId,
        },
        fTag,
        { upsert: true }
      )
    }
    return true
  },
  clearIntUserTags: async ({ integrationUser }) => Tags.remove({ integrationUserId: integrationUser._id }, true),
  getTaggedStoryIds: async ({ integrationUser }) => {
    const markers = await Markers.find({ integrationUserId: integrationUser._id })
    return (markers || []).reduce((a, c) => {
      if (isStreamAUserTag(c.type) || isStreamASystemTag(c.type)) {
        a[c.type] = (c.storyIds || []).reduce((a1, c1) => {
          a1[c1] = 1
          return a1
        }, {})
      }
      return a
    }, {})
  },
  saveTaggedStoryIds: async ({ integrationUser, ids = [], type, replace }) => {
    const propName = type === 'subscriptionPostions' ? 'subscriptionPostions' : 'storyIds'
    if (replace) {
      // this will overwrite existing ids for this user+type
      return await Markers.update({ integrationUserId: integrationUser._id, type: type }, { integrationUserId: integrationUser._id, type: type, [propName]: ids }, { upsert: true })
    } else {
      await Markers.update({ integrationUserId: integrationUser._id, type: type }, { $push: { [propName]: { $each: ids } } }, { upsert: true })
    }
  },
  removeTagFromStoryId: async ({ integrationUser, storyId, type }) => {
    const propName = 'storyIds'
    await Markers.update({ integrationUserId: integrationUser._id, type: type }, { $pull: { [propName]: storyId } }, { upsert: true })
  },
  clearTaggedStoriesForATag: async ({ integrationUser, streamIdsToKeep }) => {
    return await Markers.remove({ integrationUserId: integrationUser._id, type: { $nin: streamIdsToKeep } }, true)
  },
  getTaggedStoryIdsForAStreamId: async ({ integrationUser, streamId }) => {
    const row = await Markers.findOne({ integrationUserId: integrationUser._id, type: streamId })
    return (row || { storyIds: [] }).storyIds
  },
  /* ------------- TAGS-END ------------- */

  /* ------------- STORIES-META ------------- */
  addStoryMeta: async ({ integrationUser, stories, activityName }) => {
    const storiesMeta = stories.map((story) => {
      return {
        activityName: activityName,
        integrationUserId: integrationUser._id,
        storyId: story._id,
        _id: `${story._id}-${integrationUser._id}-${activityName}`,
        time: Date.now(),
      }
    })
    return await StoryMeta.save(storiesMeta)
  },
  deleteStoryMeta: async ({ integrationUser, stories, activityName }) =>
    await StoryMeta.remove({ integrationUserId: integrationUser._id, storyId: { $in: stories.map((s) => s._id) }, activityName }, true),

  /* ------------- DATABASE STUFF ------------- */
  initialize: async () => {
    console.log('Initilizing Database....')
    const dbPath = `.db` //for electron only
    Platform.Db.configure({ dbPath, entities: ENTITIES })
  },
  clean: async () => {
    for (let Entity of ENTITIES) {
      await Entity.truncate()
    }
  },
}

const wrapperMethods = Object.keys(dbMethods).reduce((acc, key) => {
  acc[key] = async (...props) => {
    const start = Date.now()
    const handler = dbMethods[key]
    const result = await handler(...props)
    console.log(`Time taken by DB for ${key} operation::: ${Date.now() - start}ms`)
    return result
  }
  return acc
}, {})

export default wrapperMethods
