import React from 'react'
import styled, { css } from 'styled-components'
import ElementWrapper from 'APP/ui-kit/ElementWrapper'
import Bg from 'APP/static/empty-story-bg.jpg'

const Component = () => {
  return (
    <Container>
      <BackgroundWithColor />
      <BackgroundWithImage />
    </Container>
  )
}

const Base = css`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

const Container = styled.div`
  ${Base}
  position:relative;
`
const BackgroundWithColor = styled.div`
  ${Base}
  background-color:${(p) => p.theme.emptyStoryDetailsBgColor};
`

const BackgroundWithImage = styled(ElementWrapper)`
  ${Base}
  background-image:url('${Bg}');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 25%;
  position: absolute;
  top: 0px;
  right: 0px;
`

export default Component
