import { connect } from 'react-redux'
import Pure from './Pure'

const mapStateToProps = (state) => {
  return {
    state: state,
  }
}

export default connect(mapStateToProps, {})(Pure)
