import React from 'react'
import styled from 'styled-components'
import { Card } from 'APP/ui-kit/Vendor'
import CoverImage from 'APP/ui-kit/CoverImage'
import { wrapStoryTitle } from 'APP/utils/common'
import { useMessage } from 'APP/language'
import { LinkButton } from 'APP/ui-kit/Button'

const Stats = ({ title, subtext }) => (
  <div style={{ color: '#777' }}>
    <h4>{title}</h4>
    <div style={{ marginTop: -16 }}>{subtext}</div>
  </div>
)

const Component = ({ result, handleSubscribe, selectedSubscription }) => {
  const [SUBSCRIBERS, ITEMS_PER_WEEK, SUBSCRIBE] = useMessage('SUBSCRIBERS', 'ITEMS_PER_WEEK', 'SUBSCRIBE')
  const isSelected = selectedSubscription && selectedSubscription.id === result.id
  return (
    <Container>
      <Image src={result.coverUrl} />
      <Icon src={result.visualUrl || result.iconUrl} />
      <Section>
        <Title>{result.title}</Title>
        <Subtext>{result.website}</Subtext>
        <Description>{wrapStoryTitle(result.description, 70)}</Description>
        <StatsContainer>
          <Stats title={result.subscribers} subtext={SUBSCRIBERS} />
          <Stats title={result.velocity} subtext={ITEMS_PER_WEEK} />
        </StatsContainer>
        <Separator>&nbsp;</Separator>
        <LinkButton checked={isSelected} onClick={handleSubscribe}>
          {SUBSCRIBE}
        </LinkButton>
      </Section>
    </Container>
  )
}

const BORDER_RADIUS = 2
const Container = styled(Card)`
  height: 380px;
  width: 100%;
  position: relative;
  outline: none;
  min-width: 286px;
  background: ${(p) => p.theme.cardBackground};
  border-radius: ${BORDER_RADIUS}px;
`

const Title = styled.h3`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const Section = styled.div`
  padding: 0px 12px;
`

const Image = styled(CoverImage)`
  width: 100%;
  height: 136px;
  border-top-right-radius: ${BORDER_RADIUS}px;
  border-top-left-radius: ${BORDER_RADIUS}px;
`
const Icon = styled.img`
  width: 50px;
  height: 50px;
  position: absolute;
  left: 8px;
  border-radius: 22px;
  height: 44px;
  width: 44px;
  top: 96px;
  background-color: ${(p) => p.theme.subscriptionManagerIconBorderColor};
  padding: 4px;
`
const Subtext = styled.div`
  margin-top: -15px;
  color: ${(p) => p.theme.subscriptionManagerSubtextColor};
  font-size: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`
const Description = styled.div`
  margin-top: 12px;
  color: ${(p) => p.theme.subscriptionManagerDescriptionColor};
  height: 38px;
  overflow: hidden;
`

const StatsContainer = styled.div`
  display: flex;
  div:nth-child(2) {
    margin-left: auto;
  }
`
const Separator = styled.div`
  height: 1px;
  background: ${(p) => p.theme.subscriptionManagerSeparotorColor};
  width: 100%;
  margin: 12px 0px;
`

export default Component
