import { connect } from 'react-redux'
import { convertToFolders } from 'APP/utils/formatter'
import { actions as StoriesListActions } from 'APP/components/StoriesList/model'
import { actions as SubscriptionListActions } from './model'
import Pure from './Pure'
import { getUIState, getSubscriptions, getCounts, getMany, getTags, getIntegrationUser } from 'APP/utils/reduxSelectors'
import * as Markers from 'APP/components/Models/markers'

const mapStateToProps = (state) => {
  const [subscriptions, counts, tags, integrationUser, uiState] = getMany(state, getSubscriptions, getCounts, getTags, getIntegrationUser, getUIState)
  return {
    folders: convertToFolders({ subscriptions, uiState, counts, tags, integrationUser }),
  }
}

export default connect(mapStateToProps, {
  load: SubscriptionListActions.load.request,
  syncSubscription: StoriesListActions.fetchStoriesOfAStream.request,
  removeSubscription: SubscriptionListActions.remove.request,
  rename: SubscriptionListActions.rename.request,
  loadCounts: Markers.actions.loadCounts.request,
  markSubscriptionAsRead: Markers.actions.markSubscriptionAsRead.request,
})(Pure)
