import { handleActions } from 'redux-actions'
import { takeLatest, put } from 'redux-saga/effects'
import produce from 'immer'
import { handleSaga, create3Actions, createAction } from 'APP/utils/reduxUtils'
import { serviceEffext, API_OPERATIONS } from 'APP/services/serviceEffext'
import { hideStatusMessage } from 'APP/components/MessageBar/model'

const MODULE_NAME = 'SUBSCRIPTION_SYSTEM'
const createActions = create3Actions(MODULE_NAME)

export const actions = {
  search: createActions('search'),
  clear: createAction(MODULE_NAME, 'clear'),
}

const initialState = {
  results: [],
  isLoading: false,
  term: undefined,
}

export const reducer = handleActions(
  {
    [actions.search.request]: (state, { payload }) =>
      produce(state, (draft) => {
        return { ...initialState, results: [], isLoading: true, term: payload }
      }),
    [actions.search.success]: (state, { payload }) =>
      produce(state, (draft) => {
        return { ...draft, results: payload, isLoading: false }
      }),
    [actions.clear]: (state, { payload }) =>
      produce(state, (draft) => {
        return initialState
      }),
  },
  initialState
)

const rootSaga = {
  [actions.search.request]: handleSaga(
    takeLatest,
    function* ({ payload }) {
      const response = yield serviceEffext(API_OPERATIONS.SEARCH_SUBSCRIPTIONS, { q: payload })
      yield put(actions.search.success(response.data.results))
      yield hideStatusMessage('SEARCH_SUBSCRIPTIONS')
    },
    {
      messageBarStatusId: 'SEARCH_SUBSCRIPTIONS',
      progressMessageProducer: ({ payload: q }) => ({ message: 'SEARCHING', langData: [q] }),
    }
  ),
}
export { rootSaga }
