import axios from 'axios'
import { call, select, put } from 'redux-saga/effects'
import { v4 as uuidv4 } from 'uuid'
import config from 'APP/utils/config'
import { get } from 'APP/utils/lodash'
import { getStoryThumbnail, formatStringForUrl } from 'APP/utils/common'
import { API_STATUS, CUSTOM_ERRORS } from 'APP/utils/enums'
import { CustomError } from 'APP/utils/httpUtils'
import { getIntegrationUser, getUser, getMessages, getSettings, getIsAppBlocked } from 'APP/utils/reduxSelectors'
import { actions as apiStatusActions } from 'APP/components/Models/apiStatus'
import { actions as dialogActions } from 'APP/components/Dialog/model'
import * as AccountsManagerModel from 'APP/components/AccountsManager/model'
import React from 'react'
import { runSaga } from 'APP/utils/reduxUtils'
import * as AppModel from 'APP/components/Models/app'

// this will be set by saga
var settings = {}

// legato options
const legatoConfig = {
  LEGATO_API_V1_URL: `${config.LEGATO_SERVER_URL}/api/v1`,
}

// legato headers
const getLegatoHeaders = (user = {}) => {
  return {
    lang: settings.language || 'en',
    authorization: user.accessToken,
    'x-client-id': config.clientId,
    'x-request-id': uuidv4(),
    'x-platform': config.platform,
    'x-build-number': config.buildNumber,
    'x-app-version': config.appVersion,
  }
}

const API_OPERATIONS = {
  SET_API_LANGUAGE: 'SET_API_LANGUAGE',
  AUTHENTICATE: 'AUTHENTICATE',
  CREATE_USER: 'CREATE_USER',
  GET_ME: 'GET_ME',
  SEARCH_SUBSCRIPTIONS: 'SEARCH_SUBSCRIPTIONS',
  CREATE_INTEGRATION_USER: 'CREATE_INTEGRATION_USER',
  GET_INTEGRATIONS: 'GET_INTEGRATIONS',
  UPSERT_SUBSCRIPTION: 'UPSERT_SUBSCRIPTION',
  GET_SUBSCRIPTIONS: 'GET_SUBSCRIPTIONS',
  GET_ACCOUNT_STORIES: 'GET_ACCOUNT_STORIES',
  GET_SUBSCRIPTION_STORIES: 'GET_SUBSCRIPTION_STORIES',
  GET_STREAM_STORIES: 'GET_STREAM_STORIES',
  GET_CLEAN_ARTICLE: 'GET_CLEAN_ARTICLE',
  REMOVE_ONE_SUBSCRIPTION: 'REMOVE_ONE_SUBSCRIPTION',
  GET_SETTINGS: 'GET_SETTINGS',
  UPDATE_SETTINGS: 'UPDATE_SETTINGS',
  CHANGE_PASSWORD: 'CHANGE_PASSWORD',
  LOGOUT: 'LOGOUT',
  RENAME_FOLDER: 'RENAME_FOLDER',
  GET_MARKERS: 'GET_MARKERS',
  ADD_MARKER: 'ADD_MARKER',
  GET_MARKERS_COUNTS: 'GET_MARKERS_COUNTS',
  FETCH_TAGS: 'FETCH_TAGS',
  ADD_TAGS_To_STORIES: 'ADD_TAGS_To_STORIES',
  REMOVE_TAGS_FROM_STORIES: 'REMOVE_TAGS_FROM_STORIES',
  REFRESH_TOKEN: 'REFRESH_TOKEN',
  FORGOT_PASSWORD: 'FORGOT_PASSWORD',
  CREATE_NEW_TAG: 'CREATE_NEW_TAG',
  GET_ONBOARDING_RECOMMENDATIONS: 'GET_ONBOARDING_RECOMMENDATIONS',
  MARK_ONBOARDING_COMPLETE: 'MARK_ONBOARDING_COMPLETE',

  // Public URL NO user available
  VERIFY_EMAIL: 'VERIFY_EMAIL',
}

const methods = {
  [API_OPERATIONS.SET_API_LANGUAGE]: (data) => (legatoConfig.lang = data),
  [API_OPERATIONS.AUTHENTICATE]: (data) => ({
    url: `${legatoConfig.LEGATO_API_V1_URL}/users/authenticate/`,
    method: 'POST',
    headers: getLegatoHeaders(),
    data,
  }),
  [API_OPERATIONS.CREATE_USER]: (data) => ({
    url: `${legatoConfig.LEGATO_API_V1_URL}/users`,
    method: 'POST',
    headers: getLegatoHeaders(),
    data,
  }),
  [API_OPERATIONS.GET_ME]: (data, { integrationUser, user }) => ({
    url: `${legatoConfig.LEGATO_API_V1_URL}/users/me`,
    method: 'GET',
    headers: getLegatoHeaders(user),
    params: data,
  }),
  [API_OPERATIONS.SEARCH_SUBSCRIPTIONS]: (data, { integrationUser, user }) => ({
    url: `${legatoConfig.LEGATO_API_V1_URL}/integration-users/${integrationUser._id}/subscriptions/search`,
    method: 'GET',
    headers: getLegatoHeaders(user),
    params: data,
  }),
  [API_OPERATIONS.CREATE_INTEGRATION_USER]: (data, { integrationUser, user }) => ({
    url: `${legatoConfig.LEGATO_API_V1_URL}/integration-users/`,
    method: 'POST',
    headers: getLegatoHeaders(user),
    data: data,
  }),
  [API_OPERATIONS.GET_INTEGRATIONS]: (data, { integrationUser, user }) => ({
    url: `${legatoConfig.LEGATO_API_V1_URL}/integrations/`,
    method: 'GET',
    headers: getLegatoHeaders(user),
  }),
  [API_OPERATIONS.UPSERT_SUBSCRIPTION]: (data, { integrationUser, user }) => ({
    url: `${legatoConfig.LEGATO_API_V1_URL}/integration-users/${integrationUser._id}/subscriptions`,
    method: 'POST',
    headers: getLegatoHeaders(user),
    data,
  }),
  [API_OPERATIONS.GET_SUBSCRIPTIONS]: (data, { integrationUser, user }) => ({
    url: `${legatoConfig.LEGATO_API_V1_URL}/integration-users/${integrationUser._id}/subscriptions`,
    method: 'GET',
    headers: getLegatoHeaders(user),
  }),
  [API_OPERATIONS.GET_ACCOUNT_STORIES]: ({ count, newerThan, continuation }, { integrationUser, user }) => ({
    url: `${legatoConfig.LEGATO_API_V1_URL}/integration-users/${integrationUser._id}/stories`,
    method: 'GET',
    headers: getLegatoHeaders(user),
    params: { count, newerThan, continuation },
  }),
  [API_OPERATIONS.GET_SUBSCRIPTION_STORIES]: ({ subscription, count, newerThan, ranked, continuation }, { integrationUser, user }) => ({
    url: `${legatoConfig.LEGATO_API_V1_URL}/integration-users/${integrationUser._id}/subscriptions/${encodeURIComponent(subscription.feedId || subscription.id)}/stories`,
    method: 'GET',
    headers: getLegatoHeaders(user),
    params: { count, newerThan, ranked, continuation },
  }),
  [API_OPERATIONS.GET_STREAM_STORIES]: ({ streamId, count, newerThan, ranked, continuation }, { integrationUser, user }) => ({
    url: `${legatoConfig.LEGATO_API_V1_URL}/integration-users/${integrationUser._id}/stream-stories`,
    method: 'POST',
    headers: getLegatoHeaders(user),
    data: { streamId, count, newerThan, ranked, continuation },
  }),
  [API_OPERATIONS.GET_CLEAN_ARTICLE]: (data, { integrationUser, user }) => ({
    url: `${legatoConfig.LEGATO_API_V1_URL}/utils/parseArticle`,
    method: 'POST',
    headers: getLegatoHeaders(user),
    data,
  }),
  [API_OPERATIONS.REMOVE_ONE_SUBSCRIPTION]: ({ subscription }, { integrationUser, user }) => ({
    url: `${legatoConfig.LEGATO_API_V1_URL}/integration-users/${integrationUser._id}/subscriptions/${encodeURIComponent(subscription.feedId || subscription._id)}`,
    method: 'DELETE',
    headers: getLegatoHeaders(user),
  }),
  [API_OPERATIONS.GET_SETTINGS]: (data, { integrationUser, user }) => ({
    url: `${legatoConfig.LEGATO_API_V1_URL}/users/settings/${config.deviceType}`,
    method: 'GET',
    headers: getLegatoHeaders(user),
  }),
  [API_OPERATIONS.UPDATE_SETTINGS]: (settings, { integrationUser, user }) => ({
    url: `${legatoConfig.LEGATO_API_V1_URL}/users/settings/${config.deviceType}`,
    method: 'POST',
    headers: getLegatoHeaders(user),
    data: { settings },
  }),
  [API_OPERATIONS.CHANGE_PASSWORD]: (data, { integrationUser, user }) => ({
    url: `${legatoConfig.LEGATO_API_V1_URL}/users/change-password`,
    method: 'POST',
    headers: getLegatoHeaders(user),
    data: data,
  }),
  [API_OPERATIONS.LOGOUT]: (data, { integrationUser, user }) => ({
    url: `${legatoConfig.LEGATO_API_V1_URL}/users/logout`,
    method: 'POST',
    headers: getLegatoHeaders(user),
  }),
  [API_OPERATIONS.RENAME_FOLDER]: (data, { integrationUser, user }) => ({
    url: `${legatoConfig.LEGATO_API_V1_URL}/integration-users/${integrationUser._id}/collections`,
    method: 'POST',
    headers: getLegatoHeaders(user),
    data,
  }),
  [API_OPERATIONS.GET_MARKERS]: (data, { integrationUser, user }) => ({
    url: `${legatoConfig.LEGATO_API_V1_URL}/integration-users/${integrationUser._id}/markers?newerThan=${data.newerThan}`,
    method: 'GET',
    headers: getLegatoHeaders(user),
    data,
  }),
  [API_OPERATIONS.ADD_MARKER]: (data, { integrationUser, user }) => ({
    url: `${legatoConfig.LEGATO_API_V1_URL}/integration-users/${integrationUser._id}/markers`,
    method: 'POST',
    headers: getLegatoHeaders(user),
    data,
  }),
  [API_OPERATIONS.GET_MARKERS_COUNTS]: (data, { integrationUser, user }) => ({
    url: `${legatoConfig.LEGATO_API_V1_URL}/integration-users/${integrationUser._id}/markers/counts`,
    method: 'GET',
    headers: getLegatoHeaders(user),
    data,
  }),
  [API_OPERATIONS.FETCH_TAGS]: (data, { integrationUser, user }) => ({
    url: `${legatoConfig.LEGATO_API_V1_URL}/integration-users/${integrationUser._id}/tags`,
    method: 'GET',
    headers: getLegatoHeaders(user),
    data,
  }),
  [API_OPERATIONS.ADD_TAGS_To_STORIES]: (data, { integrationUser, user }) => ({
    url: `${legatoConfig.LEGATO_API_V1_URL}/integration-users/${integrationUser._id}/tags`,
    method: 'PUT',
    headers: getLegatoHeaders(user),
    data,
  }),
  [API_OPERATIONS.REMOVE_TAGS_FROM_STORIES]: (data, { integrationUser, user }) => ({
    url: `${legatoConfig.LEGATO_API_V1_URL}/integration-users/${integrationUser._id}/tags`,
    method: 'DELETE',
    headers: getLegatoHeaders(user),
    data,
  }),
  [API_OPERATIONS.REFRESH_TOKEN]: (data, { integrationUser, user }) => ({
    url: `${legatoConfig.LEGATO_API_V1_URL}/users/refresh-token`,
    method: 'POST',
    headers: getLegatoHeaders(user),
  }),
  [API_OPERATIONS.FORGOT_PASSWORD]: (data, { integrationUser, user }) => ({
    url: `${legatoConfig.LEGATO_API_V1_URL}/users/forgot-password`,
    method: 'POST',
    headers: getLegatoHeaders(user),
    data: data,
  }),
  [API_OPERATIONS.VERIFY_EMAIL]: (data) => ({
    url: `${legatoConfig.LEGATO_API_V1_URL}/users/verify-email`,
    method: 'POST',
    headers: getLegatoHeaders({}),
    data: data,
  }),
  [API_OPERATIONS.CREATE_NEW_TAG]: (data, { integrationUser, user }) => ({
    url: `${legatoConfig.LEGATO_API_V1_URL}/integration-users/${integrationUser._id}/tags`,
    method: 'POST',
    headers: getLegatoHeaders(user),
    data,
  }),
  [API_OPERATIONS.GET_ONBOARDING_RECOMMENDATIONS]: (data, { integrationUser, user }) => ({
    url: `${legatoConfig.LEGATO_API_V1_URL}/integration-users/${integrationUser._id}/onboarding-recommendations`,
    method: 'GET',
    headers: getLegatoHeaders(user),
    data,
  }),
  [API_OPERATIONS.MARK_ONBOARDING_COMPLETE]: (data, { integrationUser, user }) => ({
    url: `${legatoConfig.LEGATO_API_V1_URL}/users/onboarding-complete`,
    method: 'POST',
    headers: getLegatoHeaders(user),
    data: data,
  }),
}

const dataFormatters = {
  [API_OPERATIONS.GET_ACCOUNT_STORIES]: {
    response: (result) => {
      return {
        continuation: result.continuation,
        items: (result.items || []).map((s) => {
          s.subscriptionId = s.origin.streamId
          s.subscriptionTitle = s.origin.title
          s.thumbnail = getStoryThumbnail(s) || ''
          s.url = s.alternate[0].href
          s._id = formatStringForUrl(s.id) //THis has to BE FIXED (provider_id_from_storry). Make sure this never has / this will go into URL//THis will come from SERVER
          return s
        }),
      }
    },
  },
  [API_OPERATIONS.GET_SUBSCRIPTION_STORIES]: {
    response: (result) => dataFormatters[API_OPERATIONS.GET_ACCOUNT_STORIES].response(result),
  },
  [API_OPERATIONS.GET_STREAM_STORIES]: {
    response: (result) => dataFormatters[API_OPERATIONS.GET_ACCOUNT_STORIES].response(result),
  },
  [API_OPERATIONS.GET_SUBSCRIPTIONS]: {
    response: (result) => {
      return result.map((s) => {
        // tagCounts is object, sometimes it can have keys like "web 2.0". Dot is not allowed by linvoDb. So, it fails
        s.tagCounts = JSON.stringify(s.tagCounts)
        return s
      })
    },
  },
  [API_OPERATIONS.GET_MARKERS_COUNTS]: {
    response: (result) => {
      let counts = get(result, 'unreadcounts', null)
      if (!counts) {
        return null
      }
      return counts.reduce((a, c) => {
        a[c.id] = c.count
        return a
      }, {})
    },
  },
  [API_OPERATIONS.FETCH_TAGS]: {
    response: (result, reqData, { integrationUser }) => {
      return (result || []).map((t) => ({ ...t, integrationUserId: integrationUser._id, _id: t.id }))
    },
  },
  [API_OPERATIONS.GET_ME]: {
    response: (result, reqData, { integrationUser }) => {
      result.integrationUsers = result.integrationUsers || []
      // result.integrationUsers = result.integrationUsers.map((i) => ({ ...i, id: i.id || i._id }))
      return result
    },
  },
  [API_OPERATIONS.CREATE_NEW_TAG]: {
    response: (result, reqData, { integrationUser }) => {
      return { ...result, integrationUserId: integrationUser._id, _id: result.id }
    },
  },
}

/**
 * @accessToken is optional. When you dont' pass it it will be read from state.user
 * you typically pass accessToken when user is not avaialble in state.user
 *
 * doNotRefreshToken => Will not try to refresh token
 * isLogoutRequest => Is this logout request
 * isLoginRequest => Is this login request
 * This will return the exact data which we receive from the backened.
 * Data property will be formatted based on formttaters
 * If error occurs then, this CUSTOM_ERRORS.API_ERROR error will be thown
 */
function* serviceEffext(operationKey, requestData, { accessToken, doNotRefreshToken, isLogoutRequest, isLoginRequest } = {}) {
  let isAppBlocked = yield select(getIsAppBlocked)
  if (isAppBlocked) {
    return false
  }

  if (!doNotRefreshToken && !isLogoutRequest) {
    yield call(AccountsManagerModel.refreshToken)
  }

  let user = yield select(getUser)
  settings = yield select(getSettings)

  let integrationUser = yield select(getIntegrationUser)
  yield put(apiStatusActions.upsert({ [operationKey]: { status: API_STATUS.PROGRESS, start_time: Date.now() } }))
  user = user || {} // if user is not there we create a dummy user for pre-login scenarios
  accessToken && (user.accessToken = accessToken)
  const options = methods[operationKey](requestData, { integrationUser, user })

  // Make a web request only when options contains an URL
  // It is expected that response returned has below structure
  // {message,errors,success,data}
  if (options && options.url) {
    try {
      const response = yield call(axios, options)
      const result = response.data
      yield put(apiStatusActions.upsert({ [operationKey]: { status: API_STATUS.SUCCESS, end_time: Date.now() } }))
      const formatter = dataFormatters[operationKey] && dataFormatters[operationKey]['response']
      result.data && formatter && (result.data = formatter(result.data, requestData, { user, integrationUser }))
      return result
    } catch (error) {
      const status = get(error, 'response.status')
      if (status + '' === '401' && !isLoginRequest) {
        if (!isLogoutRequest) {
          const [OK, ALERT, SESSION_TIMED_OUT] = yield select((s) => getMessages(s, ['OK', 'ALERT', 'SESSION_TIMED_OUT']))
          yield put(
            dialogActions.showPopup({
              onConfirmDispatch: (dispatch, data) => {
                dispatch(AccountsManagerModel.actions.logout())
              },
              message: SESSION_TIMED_OUT,
              confirmBtnText: OK,
              title: ALERT,
            })
          )
        }
      } else {
        let name = error.name
        let message = error.message || 'UNKNOWN_ERROR'
        let data = null
        if (error.response) {
          name = CUSTOM_ERRORS.API_ERROR
          message = error.response.data.message || message
          data = error.response.data
        }
        yield put(apiStatusActions.upsert({ [operationKey]: { status: API_STATUS.FAIL, name, message } }))
        yield runSaga(AppModel, AppModel.actions.saveAppBlocker)({ payload: { error } })
        yield runSaga(AppModel, AppModel.actions.verifyAppBlocker.request)()
        //
        throw new CustomError(name, message, data)
      }
    }
  } else {
    return options
  }
}

function useServiceEffect(operationKey, callback) {
  const [status, setStatus] = React.useState(undefined)
  const makeRequest = (requestData) => {
    let options = methods[operationKey](requestData)
    setStatus(API_STATUS.PROGRESS)
    axios(options)
      .then((res) => {
        setStatus(API_STATUS.SUCCESS)
        callback && callback(res.data)
      })
      .catch(() => {
        setStatus(API_STATUS.FAIL)
      })
  }
  return [status, makeRequest]
}

//
export { API_OPERATIONS, serviceEffext, useServiceEffect }
