import React from 'react'
import { withTheme } from 'styled-components'
import { useMessage } from 'APP/language'
import { Dialog, DialogType, DialogFooter, PrimaryButton, DefaultButton } from 'APP/ui-kit/Vendor'

/**required for form to submit when user presses enter 
   If form fails to submit when user press enter, Add below button inside form
   check change-password form
  <button type="submit" style={{ display: 'none' }} />
*/
const Form = ({ modalTitle, primaryBtnTxt, secondryBtnTxt, disabled, onSubmit, formErrorProp, hideModal = () => {}, children, dialogContentProps = {}, modalProps = {}, dialogProps = {} }) => {
  return (
    <Dialog
      hidden={false}
      onDismiss={hideModal}
      minWidth={375}
      dialogContentProps={{
        type: DialogType.largeHeader,
        title: modalTitle,
        subText: formErrorProp && <ErrorMessage errorProp={formErrorProp} />,
        ...dialogContentProps,
      }}
      modalProps={{
        isBlocking: true,
        ...modalProps,
      }}
      {...dialogProps}>
      {children}
      <DialogFooter>
        <PrimaryButton type="submit" text={primaryBtnTxt} disabled={disabled} onClick={onSubmit} />
        <DefaultButton onClick={hideModal} text={secondryBtnTxt} disabled={disabled} />
      </DialogFooter>
    </Dialog>
  )
}

const ErrorMessage = withTheme(({ theme, errorProp }) => {
  const [errorMessage] = useMessage(errorProp)
  return <span style={{ color: theme.formValidationErrorText }}>{errorMessage || errorProp}</span>
})

export default Form
