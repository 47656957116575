import React from 'react'
import { Col } from 'APP/ui-kit/Grid'
import styled from 'styled-components'
import { FontIcon } from 'APP/ui-kit/Vendor'
import CardBase from 'APP/ui-kit/Card'

const Component = ({ title, imageUrl, disabled = false, onClick = () => {}, className, containerStyle, subText }) => (
  <Card
    hoverable
    disabled={disabled}
    className={className}
    style={containerStyle}
    onClick={() => {
      !disabled && onClick()
    }}>
    <Col middle>
      <img src={imageUrl} alt={title} style={{ width: 40, height: 40 }} />
    </Col>
    <Col
      length={50}
      centerh
      style={{
        fontSize: 16,
        fontWeight: 600,
      }}>
      {title}
      <div
        style={{
          fontSize: 12,
          fontWeight: 600,
          color: '#999',
        }}>
        {subText}
      </div>
    </Col>
    <AddIcon disabled={disabled}>
      <FontIcon
        style={{
          fontSize: 10,
        }}
        iconName={'Add'}
      />
    </AddIcon>
  </Card>
)

const Card = styled(CardBase)`
  width: 165px;
  height: 120px;
  margin-right: 6px;
  margin-bottom: 6px;
  flex: unset !important;
  cursor: pointer;
  position: relative;
  display: flex;
  flex-direction: column;
`

const AddIcon = styled.div`
  position: absolute;
  right: 0px;
  top: 0px;
  background: ${(p) => p.theme.connectionCardIconBackgroundColor};
  padding: 4px;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  visibility: hidden;
  ${(p) =>
    !p.disabled &&
    `
    ${Card}:hover & {
      visibility: visible;
    }
  `}
`

export default Component
