import React, { useEffect, Fragment, useContext, createContext, useState } from 'react'
import { initializeIcons, loadTheme, Fabric } from 'APP/ui-kit/Vendor'
import { ThemeProvider as StyledThemeProvider } from 'styled-components'

import { colors as defaultColors, theme as defaultTheme } from './defaultTheme'
import { colors as darkColors, theme as darkTheme } from './darkTheme'
import { THEMES } from './contants'
import { GlobalStyle } from './globalStyle'
import { media } from './utils'

// Initialize fabric Icons
initializeIcons()

// Palettes is used by Fabric
const palettes = {
  [THEMES.DEFAULT]: { palette: defaultColors },
  [THEMES.DARK]: { palette: darkColors },
}

// Theme is uses by styled component
const themes = {
  [THEMES.DEFAULT]: defaultTheme,
  [THEMES.DARK]: darkTheme,
}

const ThemeContext = createContext({})
const ThemeProvider = ({ children }) => {
  const [theme, changeTheme] = useState(THEMES.DEFAULT)

  // Load theme everytime theme's value changes
  useEffect(() => {
    // This is for React Fabric
    loadTheme(palettes[theme])
  }, [theme])

  return (
    <Fabric>
      <StyledThemeProvider theme={themes[theme]}>
        <Fragment>
          <GlobalStyle />
          <ThemeContext.Provider value={{ theme, changeTheme }}>{children}</ThemeContext.Provider>
        </Fragment>
      </StyledThemeProvider>
    </Fabric>
  )
}

const useTheme = () => {
  const { theme, changeTheme } = useContext(ThemeContext)
  return [theme, changeTheme]
}

export { ThemeProvider, THEMES, media, useTheme }
