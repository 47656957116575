import React from 'react'
import { Toggle, Dropdown } from 'APP/ui-kit/Vendor'
import { LANGUAGES, useMessage } from 'APP/language'
import Card from 'APP/ui-kit/Card'
import InformationRow from 'APP/ui-kit/InformationRow'
import { THEMES } from 'APP/themes'

const languages = LANGUAGES.asArray()

const languageOptions = languages.map((lang) => ({
  key: lang.languageCode,
  text: lang.displayName,
}))

const Settings = ({ settings, update }) => {
  const [ENABLED, DISABLED, SYNC_ON_START, LANGUAGE, CHOOSE_LANGUAGE, THEME, CHOOSE_THEME, THEME_NAMES] = useMessage(
    'ENABLED',
    'DISABLED',
    'SYNC_ON_START',
    'LANGUAGE',
    'CHOOSE_LANGUAGE',
    'THEME',
    'CHOOSE_THEME',
    'THEME_NAMES'
  )

  const themeOptions = Object.keys(THEMES).map((key) => ({
    key: THEMES[key],
    text: THEME_NAMES[THEMES[key] + ''],
  }))

  return (
    <Card
      style={{
        margin: '16px auto',
        padding: 16,
        width: '100%',
        maxWidth: 600,
      }}>
      <InformationRow
        title={SYNC_ON_START}
        value={settings.syncOnStart ? ENABLED : DISABLED}
        selector={
          <Toggle
            checked={settings.syncOnStart}
            onChange={(event, checked) => {
              update({ syncOnStart: checked })
            }}
          />
        }
      />

      <InformationRow
        title={LANGUAGE}
        value={LANGUAGES[settings.language]['displayName']}
        selector={
          <Dropdown
            placeholder={CHOOSE_LANGUAGE}
            options={languageOptions}
            defaultSelectedKey={settings.language}
            onChange={(event, value) => {
              update({ language: value.key })
            }}
            style={{
              width: 90,
            }}
          />
        }
      />

      <InformationRow
        title={THEME}
        value={THEME_NAMES[settings.theme]}
        selector={
          <Dropdown
            placeholder={CHOOSE_THEME}
            options={themeOptions}
            defaultSelectedKey={settings.theme}
            onChange={(event, value) => {
              update({ theme: value.key })
            }}
            style={{
              width: 90,
            }}
          />
        }
      />
    </Card>
  )
}

export default Settings
