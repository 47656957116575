import { connect } from 'react-redux'
import { default as BaseStoryCard } from 'APP/ui-kit/StoryCard'
import React from 'react'
import { useHistory } from 'react-router-dom'
import { actions } from 'APP/components/StoriesList/model'
import { getUIState, produceStoryMeta } from 'APP/utils/reduxSelectors'
import { isEmpty } from 'APP/utils/lodash'

const StoryCard = ({ style, story, isSelected, storyMeta, subscription, selectStory: selectStoryAction, selectedStoryId }) => {
  const history = useHistory()
  const selectStory = () => {
    // select story if not selected
    selectedStoryId = selectedStoryId && selectedStoryId.toLowerCase()
    const storyId = story && story.id.toLowerCase()
    if (selectedStoryId !== storyId && !isEmpty(storyId)) {
      selectStoryAction({ storyMeta, story, history })
    }
  }
  return <BaseStoryCard subscription={subscription} storyMeta={storyMeta} style={style} story={story} isSelected={isSelected} selectStory={selectStory} />
}

const mapStateToProps = (state, { story }) => {
  const storyMeta = produceStoryMeta(state, story)
  const { selectedStoryId } = getUIState(state)
  return {
    storyMeta,
    selectedStoryId,
  }
}

export default connect(mapStateToProps, {
  selectStory: actions.selectStory,
})(StoryCard)
