import React from 'react'
import Confirm from 'APP/ui-kit/Modals/Confirm'
import { useMessage } from 'APP/language'

const Logout = ({ hideForm, logout }) => {
  const [LOGOUT_CONFIRM_MESSAGE] = useMessage('LOGOUT_CONFIRM_MESSAGE')
  return <Confirm onCancel={hideForm} onConfirm={logout} message={LOGOUT_CONFIRM_MESSAGE} />
}

export default Logout
