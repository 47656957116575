import React from 'react'
import { DialogFooter, PrimaryButton, Modal } from 'APP/ui-kit/Vendor'
import * as S from 'APP/components/OnboardingRecommendations/styled'
import { API_STATUS } from 'APP/utils/enums'
import { find } from 'APP/utils/lodash'
import { useMessage } from 'APP/language'

const titleId = 'titleId'

const Component = ({ results: categories, fetchRecommendations, apiStatus, active, markAsOnBoarded, addSubscription, removeSubscription, integrationUser, subscriptions, startSync }) => {
  const isPrimaryButtonDisabled = false //subscriptions.length < 3
  const [headerText, COMPLETE] = useMessage('ONBOARDING_RECOMMENDATION_TITLE', 'ONBOARDING_RECOMMENDATION_COMPLETE_BTN')

  // progress if not success then progress
  const isProgress = apiStatus !== API_STATUS.SUCCESS
  const isSubscribed = (subscription) => !!find(subscriptions, { id: subscription.id })

  React.useEffect(() => {
    if (active) {
      fetchRecommendations()
    }
  }, [active])

  if (!active) {
    return null
  }

  const toggle = (sub, cat) => {
    const sub2 = { ...sub, categories: [] }
    const catsToAdd = []
    const newCategoryTitle = cat.label
    const folder = {
      id: `user/${integrationUser._id}/category/${newCategoryTitle}`,
      label: newCategoryTitle,
    }
    catsToAdd.push(folder)
    sub2.categories = catsToAdd
    if (isSubscribed(sub)) {
      const subToRemove = find(subscriptions, { id: sub.id })
      if (subToRemove) {
        removeSubscription({ subscription: subToRemove, folder })
      }
    } else {
      addSubscription(sub2)
    }
  }

  const complete = () => {
    markAsOnBoarded()
    startSync()
  }

  return (
    <Modal allowTouchBodyScroll titleAriaId={titleId} isOpen={true} isBlocking={true} topOffsetFixed={false}>
      <S.Header titleId={titleId} text={headerText} />
      <S.Body isProgress={isProgress}>
        {categories.map((cat, i) => (
          <S.CategoryContainer key={i}>
            <S.Category>{cat.label}</S.Category>
            <S.SubscriptionListContainer>
              {cat.subscriptions.map((sub, j) => (
                <S.Subscription sub={sub} cat={cat} key={sub.id} checked={isSubscribed(sub)} toggle={toggle} index={j} />
              ))}
            </S.SubscriptionListContainer>
          </S.CategoryContainer>
        ))}
      </S.Body>
      <S.Footer isProgress={isProgress}>
        <DialogFooter>
          <PrimaryButton onClick={complete} disabled={isPrimaryButtonDisabled} text={COMPLETE} style={{ marginLeft: 12 }} />
        </DialogFooter>
      </S.Footer>
    </Modal>
  )
}

export default Component
