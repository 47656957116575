import React from 'react'
import styled from 'styled-components'

const Component = ({ text, ghost, className, onClick, isButton }) => {
  return (
    <Container ghost={ghost} className={className} onClick={onClick} isButton={isButton}>
      {text}
    </Container>
  )
}

const Container = styled.span`
  height: 24px;
  border-radius: 24px;
  padding: 4px 8px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  line-height: 24px;

  ${(p) =>
    p.ghost &&
    `
        border:1px solid ${p.theme.pillBorderColor};
    `}

  ${(p) =>
    !p.ghost &&
    `
        background:${p.theme.pillBackgroundColor};
        color:${p.theme.pillTextColor};
        border:1px solid ${p.theme.pillBorderColor};
    `}

    ${(p) =>
    p.isButton &&
    `
          border:1px solid ${p.theme.pillButtonBorderColor};
      `}
`

export default Component
