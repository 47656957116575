import React, { useRef, useEffect } from 'react'
import Fullscreen from 'APP/ui-kit/Fullscreen'

/**
 * Hook that call onClickOutside
 */
function useOutsideAlerter(ref, onClickOutside) {
  useEffect(() => {
    /**
     * call onClickOutside if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        onClickOutside && onClickOutside()
      }
    }

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [ref])
}

/**
 * Component that alerts if you click outside of it
 */
export default function OutsideAlerter({ children, onClickOutside, className, mask, ...rest }) {
  const wrapperRef = useRef(null)
  useOutsideAlerter(wrapperRef, onClickOutside)
  if (mask) {
    return (
      <Fullscreen style={{ background: 'transparent' }}>
        <div className={className} ref={wrapperRef}>
          {children}
        </div>
      </Fullscreen>
    )
  }
  return (
    <div className={className} ref={wrapperRef}>
      {children}
    </div>
  )
}
