import React from 'react'
import { connect } from 'react-redux'
import { useMessage } from 'APP/language/index'
import Card from 'APP/ui-kit/Card'
import ConfirmModal from 'APP/ui-kit/Modals/Confirm'
import UserRow from 'APP/ui-kit/IntegrationUserCard'
import { DangerButton } from 'APP/ui-kit/Button'
import { getIntegrationUsers } from 'APP/utils/reduxSelectors'

const Component = ({ integrationUsers }) => {
  const [INTEGRATION_NAMES, DELETE_ACCOUNT, DELETE, MANAGE_ACCOUNTS] = useMessage('INTEGRATION_NAMES', 'DELETE_ACCOUNT', 'DELETE', 'MANAGE_ACCOUNTS')
  const [modalData, setModalData] = React.useState(null)
  return (
    <React.Fragment>
      <React.Fragment>
        <Card
          title={MANAGE_ACCOUNTS}
          style={{
            margin: '16px auto',
            width: '100%',
            maxWidth: 600,
          }}>
          {integrationUsers.map((user, index) => (
            <UserRow
              selected={modalData && modalData._id === user._id}
              disablehover
              user={user}
              key={user._id}
              INTEGRATION_NAMES={INTEGRATION_NAMES}
              command={<DangerButton onClick={() => setModalData(user)}>{DELETE}</DangerButton>}
            />
          ))}
        </Card>
      </React.Fragment>
      <React.Fragment>
        {modalData && (
          <ConfirmModal
            hidden={false}
            onCancel={() => setModalData(null)}
            onConfirm={(integrationUserToDelete) => {
              setModalData(null)
            }}
            data={modalData}
            subText={DELETE_ACCOUNT}
            message={`${modalData.fullName}`}
          />
        )}
      </React.Fragment>
    </React.Fragment>
  )
}

const mapStateToProps = (state) => {
  return {
    integrationUsers: getIntegrationUsers(state),
  }
}

export default connect(mapStateToProps, {})(Component)
