import React from 'react'
import * as S from './styled'
import { useMessage } from 'APP/language'

const InputField = ({ error, disabled, ...props }) => {
  const [errorMessage] = useMessage(error)
  return (
    <S.InputContainer>
      <S.Input disabled={disabled} error={error} {...props} />
      {error && <S.InputError>{errorMessage || error}</S.InputError>}
    </S.InputContainer>
  )
}

export { InputField }
