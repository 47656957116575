import Entity from './Entity'

class StoryMeta extends Entity {
  static entityName = 'integration_user_stories_meta'

  storyId
  activityName
  integrationUserId
  time
  payload

  static getSchema() {
    return {
      storyId: 'string',
      activityName: 'string',
      integrationUserId: 'string',
      time: 'string',
      payload: 'string',
    }
  }
}

export default StoryMeta
