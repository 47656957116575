import styled from 'styled-components'

const Component = styled.div`
  background-repeat: no-repeat;
  position: relative;
  &::after {
    content: '';
    position: absolute;
    background: #10394c;
    height: 100%;
    width: 100%;
    bottom: 0px;
    right: 0px;
    opacity: 0.5;
  }
`

export default Component
