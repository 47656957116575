import { connect } from 'react-redux'
import { actions } from './model'
import Pure from './Pure'
import { findIndex } from 'APP/utils/lodash'
import { wrapSubscriptionTitleName } from 'APP/utils/common'
import { getUIState, getStories, getSubscriptions, getStoriesModel, getMany } from 'APP/utils/reduxSelectors'
import { actions as markerActions } from 'APP/components/Models/markers'
import { STORY_LIST_STATUS } from 'APP/utils/enums'

const emptyListLabelsKey = (uiState) => {
  const { selectedTag, subscriptionListSelectedItem } = uiState
  if (selectedTag && selectedTag.labelKey) return selectedTag.labelKey
  if (subscriptionListSelectedItem) return subscriptionListSelectedItem.type
  return 'UNKNOWN'
}

const mapStateToProps = (state) => {
  const uiState = getUIState(state)
  const { selectedStoryId, selectedSubscriptionId = '', selectedFolderId = '', selectedTagId = '' } = uiState
  const [subscriptions, stories, storiesModel] = getMany(state, getSubscriptions, getStories, getStoriesModel)
  let selectedStoryIndex = findIndex(stories, { _id: selectedStoryId }) || 0
  selectedStoryIndex < 0 && (selectedStoryIndex = 0)

  const subscriptionsHash = (subscriptions || []).reduce((accumulator, currentValue) => {
    let sub = { ...currentValue }
    sub.title = wrapSubscriptionTitleName(currentValue.title)
    accumulator[currentValue.id] = sub
    return accumulator
  }, {})

  return {
    stories: stories,
    selectedStoryId: selectedStoryId,
    selectedStoryIndex: selectedStoryIndex,
    selectedSubscriptionHash: [selectedSubscriptionId, selectedFolderId, selectedTagId].join(''),
    subscriptionsHash: subscriptionsHash,
    emptyListLabelsKey: emptyListLabelsKey(uiState),
    listStatus: {
      notReady: storiesModel.listStatus === STORY_LIST_STATUS.NOT_READY,
      isLazyLoadingFromDb: storiesModel.listStatus === STORY_LIST_STATUS.LAZY_LOAD_FROM_DB,
      isLazyLoadingFromServer: storiesModel.listStatus === STORY_LIST_STATUS.LAZY_LOAD_FROM_SERVER,
      isIdle: storiesModel.listStatus === STORY_LIST_STATUS.IDLE,
    },
  }
}

export default connect(mapStateToProps, {
  loadStories: actions.loadStories.request,
  loadStoryMarkers: markerActions.loadStoryMarkers.request,
  moveStory: actions.move,
})(Pure)
