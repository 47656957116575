import React from 'react'
import styled from 'styled-components'
import Fullscreen from 'APP/ui-kit/Fullscreen'
import { useDimensions } from 'APP/utils/uiHooks'
import AccountSwitcherPopup from 'APP/components/AccountSwitcherPopup'
import MessageBar from 'APP/components/MessageBar'
import ProgressIndicator from 'APP/components/MessageBar/ProgressIndicator'
import UIBlocker from 'APP/components/UiBlocker'
import Dialog from 'APP/components/Dialog'
import OnboardingRecommendations from 'APP/components/OnboardingRecommendations'

let DefaultLeftSection = null
let DefaultMiddleSection = null
let DefaultRightSection = null
let DefaultSidebar = null

export const setDefaultSections = ({ leftSection, middleSection, rightSection, sidebar }) => {
  DefaultLeftSection = leftSection
  DefaultMiddleSection = middleSection
  DefaultRightSection = rightSection
  DefaultSidebar = sidebar
}

const Layout = ({ left, right, middle }) => {
  const { MIDDLE_WIDTH, SIDEBAR_WIDTH } = useDimensions()
  const LeftComp = left === undefined ? DefaultLeftSection : left
  const MiddleComp = middle === undefined ? DefaultMiddleSection : middle
  const RightComp = right === undefined ? DefaultRightSection : right

  return (
    <Fullscreen>
      <SideBarContainer $width={SIDEBAR_WIDTH}>
        <DefaultSidebar />
      </SideBarContainer>
      {LeftComp && (
        <Left>
          <LeftComp />
        </Left>
      )}

      {MiddleComp && (
        <Middle $width={MIDDLE_WIDTH}>
          <MiddleComp width={MIDDLE_WIDTH} />
        </Middle>
      )}

      {RightComp && (
        <Right>
          <RightComp />
        </Right>
      )}
      <UIBlocker />
      <AccountSwitcherPopup />
      <MessageBar />
      <ProgressIndicator />
      <Dialog />
      <OnboardingRecommendations />
    </Fullscreen>
  )
}

const SideBarContainer = styled.div`
  width: ${(p) => p.$width}px;
`

const Left = styled.div`
  width: 230px;
`
const Middle = styled.div`
  width: ${(p) => p.$width}px;
  border-right: 1px solid ${(p) => p.theme.frameBorderColor};
`

const Right = styled.div`
  flex: 1;
  display: flex;
`

export default Layout
