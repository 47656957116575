import React from 'react'
import styled from 'styled-components'
import { useFormattedMessage } from 'APP/language/'
import { formatTime, wrapStoryTitle } from 'APP/utils/common'

const StoryCard = ({ style, story, isSelected, selectStory, storyMeta = {}, subscription }) => {
  const [thumbnail, setThumbnail] = React.useState(null)
  React.useEffect(() => {
    const timer = setTimeout(() => {
      setThumbnail(story.thumbnail)
    }, 300)
    return () => {
      clearTimeout(timer)
    }
  }, [])
  const time = formatTime(story.published)
  let timeLabel = useFormattedMessage(time.key, [time.time, time.isPlural])
  return (
    <OuterContainer read={storyMeta.isRead} isSelected={isSelected} onClick={() => selectStory({ story, storyMeta })} style={{ ...style }}>
      <InnerContainer isSelected={isSelected}>
        <Left thumbnail={thumbnail}></Left>
        <Right>
          <StoryTitle isSelected={isSelected} light={storyMeta.isRead && !isSelected}>
            {wrapStoryTitle(story.title)}
          </StoryTitle>
          {subscription && (
            <StoryMeta>
              <SubscriptionIcon icon={subscription.iconUrl} />
              <SubscriptionTitle>{subscription.title}</SubscriptionTitle>
              <SubscriptionTime>{timeLabel}</SubscriptionTime>
            </StoryMeta>
          )}
        </Right>
      </InnerContainer>
    </OuterContainer>
  )
}

const OuterContainer = styled.div`
  user-select: none;
  -ms-user-select: none;
  cursor: pointer;
  display: flex;
  filter: grayscale(5%);
  padding-left: 8px;
  ${(p) =>
    p.read &&
    `
      filter:grayscale(100%);
  `}
  ${(p) =>
    p.isSelected &&
    `
      filter:unset;
  `}

  border-bottom: 1px solid ${(p) => p.theme.storyCardBottomBorderColor};
  ${(p) =>
    p.isSelected &&
    `
      background: ${p.theme.storyCardSelectedBackgroundColor};
  `}
`

const InnerContainer = styled.div`
  display: flex;
  flex: 1;
  padding: 8px 0px;
`

const Left = styled.div`
  width: 104px;
  background-image: url('${(p) => p.thumbnail}');
  background-color: ${(p) => p.theme.storyCardImageBgColor};
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 25%;
`

const Right = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding-left: 12px;
`

const StoryTitle = styled.div`
  color: ${(p) => p.theme.stortyCardTitleTextColor};
  font-weight: 600;
  ${(p) =>
    p.light &&
    `
      color: ${p.theme.stortyCardTitleReadTextColor};
      font-weight: 400;
  `}

  ${(p) =>
    p.isSelected &&
    `
      color: ${p.theme.stortyCardTitleSelectedTextColor};
  `}
`

const StoryMeta = styled.div`
  margin-top: auto;
  display: flex;
  color: ${(p) => p.theme.subduedText};
  font-size: 12px;
`

const SubscriptionIcon = styled.div`
  height: 16px;
  width: 16px;
  border-radius: 8px;
  align-items: center;
  justify-content: center;
  display: flex;
  background-position: center center;
  background-size: cover;
  background-image: url('${(p) => p.icon}');
  margin-right: 8px;
`

const SubscriptionTitle = styled.div``

const SubscriptionTime = styled.div`
  margin-left: 8px;
`

export default StoryCard
