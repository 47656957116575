import React from 'react'
import { Dialog, DialogType, DialogFooter, PrimaryButton, DefaultButton } from 'APP/ui-kit/Vendor'
import { useMessage } from 'APP/language/index'

const ConfirmModal = ({
  hidden = false,
  onCancel,
  onConfirm,
  data,
  subText,
  message,
  cancelBtnText,
  confirmBtnText,
  title,
  isBlocking = true,
  dialogContentProps = {},
  modalProps = {},
  dialogProps = {},
}) => {
  const [CONFIRM, CANCEL] = useMessage('CONFIRM', 'CANCEL')
  return (
    <Dialog
      hidden={hidden}
      onDismiss={onCancel}
      dialogContentProps={{
        type: DialogType.normal,
        title: title || CONFIRM,
        subText: subText,
        ...dialogContentProps,
      }}
      modalProps={{
        isBlocking: isBlocking,
        styles: { main: { maxWidth: 450 } },
        ...modalProps,
      }}
      {...dialogProps}>
      {message && <div>{message}</div>}
      <DialogFooter>
        {onConfirm && <PrimaryButton onClick={() => onConfirm(data)} text={confirmBtnText || CONFIRM} />}
        {onCancel && <DefaultButton onClick={onCancel} text={cancelBtnText || CANCEL} />}
      </DialogFooter>
    </Dialog>
  )
}

export default ConfirmModal
